import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, PreviewLink, Date, SectionHeader,
} from 'components/common'
import {
  COMPANY, USER, EQUIPMENT, SERVICE_REQUEST,
} from 'constants/resources'

function Information({
  action,
  title,
  code,
  internalCode,
  id,
  companyName,
  companyId,
  assigneeName,
  assigneeId,
  salesRepName,
  salesRepId,
  createdByName,
  createdById,
  equipmentName,
  equipmentId,
  purchaseOrder,
  givenPrice,
  transportGivenPrice,
  deliveryDate,
  duration,
  currentSupplier,
  notes,
  createdAt,
  status,
  loading,
  designation,
}) {
  const { t } = useTranslation(['common', SERVICE_REQUEST])

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={createdByName}
          loading={loading}
          label={t('common:shared.createdBy')}
        >
          <PreviewLink id={createdById} resource={USER}>
            {createdByName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={createdAt}
          loading={loading}
          label={t('common:shared.createdAt')}
        >
          <Date variant="datetime">
            {createdAt}
          </Date>
        </Detail>
      </Grid>

      <Grid item xs={12} md={6}>
        <Detail
          text={internalCode}
          loading={loading}
          label={t('common:shared.id')}
        >
          {internalCode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={code}
          loading={loading}
          label={t('common:shared.publicCode')}
        >
          {code}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={assigneeName}
          loading={loading}
          label={t('serviceRequest:shared.handledBy')}
        >
          <PreviewLink id={assigneeId} resource={USER}>
            {assigneeName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('shared.salesRep')}
          empty={t('company:shared.emptySalesRep')}
        >
          <PreviewLink
            resource={USER}
            id={salesRepId}
          >
            <span>
              {salesRepName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink id={companyId} resource={COMPANY}>
            {companyName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={equipmentName}
          loading={loading}
          label={t('common:resources.equipment.singular')}
        >
          <PreviewLink id={equipmentId} resource={EQUIPMENT}>
            {equipmentName.split(',').map(machine => (<p>{machine}</p>))}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={purchaseOrder}
          loading={loading}
          label={t('serviceRequest:shared.purchaseOrder')}
        >
          {purchaseOrder}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={givenPrice}
          loading={loading}
          label={t('serviceRequest:shared.priceGiven')}
        >
          {givenPrice}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={transportGivenPrice}
          loading={loading}
          label={t('serviceRequest:shared.transportPriceGiven')}
        >
          {transportGivenPrice}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={deliveryDate}
          loading={loading}
          label={t('serviceRequest:shared.deliveryDate')}
        >
          {deliveryDate}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={duration}
          loading={loading}
          label={t('serviceRequest:shared.duration')}
        >
          {duration}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={currentSupplier}
          loading={loading}
          label={t('serviceRequest:shared.currentSupplier')}
        >
          {currentSupplier}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.description')}
        >
          {notes}
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  code: PropTypes.string,
  id: PropTypes.string,
  internalCode: PropTypes.string,
  createdAt: PropTypes.string,
  status: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  createdByName: PropTypes.string,
  createdById: PropTypes.string,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
  designation: PropTypes.string,
  purchaseOrder: PropTypes.string,
  givenPrice: PropTypes.string,
  transportGivenPrice: PropTypes.string,
  deliveryDate: PropTypes.string,
  duration: PropTypes.string,
  currentSupplier: PropTypes.string,
}

Information.defaultProps = {
  action: null,
  title: '',
  id: '',
  internalCode: '',
  code: '',
  createdAt: '',
  status: '',
  companyName: '',
  companyId: '',
  assigneeName: '',
  assigneeId: '',
  salesRepName: '',
  salesRepId: '',
  createdByName: '',
  createdById: '',
  equipmentName: '',
  equipmentId: '',
  notes: '',
  loading: false,
  designation: '',
  purchaseOrder: '',
  givenPrice: '',
  transportGivenPrice: '',
  deliveryDate: '',
  duration: '',
  currentSupplier: '',
}

export default Information
