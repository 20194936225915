import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const DividerStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ margin }) => `${margin}`};
`
export const Line = styled.div`
  width: 100%;
  border-bottom: ${({ stroke, color, theme }) => (stroke ? `${stroke}px solid ${color || theme.color.primary200}` : 'none')};
`

function Divider({
  spacing,
  stroke,
  color,
  ...otherProps
}) {
  return (
    <DividerStyled
      {...otherProps}
      margin={`${spacing / 2}px 0px ${spacing / 2}px 0px`}
    >
      <Line
        className="line"
        stroke={stroke}
        color={color}
      />
    </DividerStyled>
  )
}

Divider.propTypes = {
  spacing: PropTypes.number,
  stroke: PropTypes.number,
  color: PropTypes.string,
}

Divider.defaultProps = {
  spacing: 0,
  stroke: 0,
  color: '',
}

export default Divider
