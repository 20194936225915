import styled, { keyframes } from 'styled-components'

const rotateForever = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: ${rotateForever};
  animation-timing-function: linear;
  height: ${({ size, theme }) => `${(size || theme?.font?.small?.size)}px`};
  width: ${({ size, theme }) => `${(size || theme?.font?.small?.size)}px`};
  border-width: ${({ size, theme }) => `${(size || theme?.font?.small?.size) / 9}px`};
  border-color: ${({ color, theme }) => color || theme?.color?.primary};
  border-style: solid;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
`

export default Loader
