import api from 'config/axios'

export const getCurrent = async () => (
  api.get('/id/me/')
)

export const createSession = async ({ email, password }) => (
  api.post('/id/jwt/', {
    email,
    password,
  })
)

export const verifySession = async ({ token }) => (
  api.post('/id/jwt/verify/', {
    token,
  })
)

export const terminateSession = async () => (
  api.post('/id/jwt/logout/', {})
)

export const resetPassword = async ({ email }) => (
  api.post('/id/reset/', {
    email,
  })
)

export const changePassword = async ({
  email, token, password,
}) => (
  api.post('/id/reset/', {
    email,
    token,
    password,
  })
)
