import React from 'react'
import PropTypes from 'prop-types'
import TextField from 'components/ui/TextField'

function TelField({ onChange, ...otherProps }) {
  const handleChange = (value) => {
    const val = value.replace(/\s/g, '')
    if (onChange) onChange(val)
    return val
  }
  return (
    <TextField
      type="text"
      mask="+9 999 999 9999"
      onChange={handleChange}
      placeholder="+1 437 886 2943"
      {...otherProps}
    />
  )
}

TelField.propTypes = {
  onChange: PropTypes.func,
}

TelField.defaultProps = {
  onChange: null,
}

export default TelField
