import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/reservations/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/reservations/${id}/`)
)

export const create = async ({
  start_date,
  end_date,
  postal_code,
  items,
}) => (
  api.post('/app/reservations/', {
    start_date,
    end_date,
    postal_code,
    items,
  })
)

export const createOrder = async id => (
  api.post(`/app/reservations/${id}/order/`, {})
)


export const cancel = async id => (
  api.post(`/app/reservations/${id}/cancel/`)
)

export const destroy = async id => (
  api.delete(`/app/reservations/${id}/`)
)
