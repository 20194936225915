import React from 'react'
import { useCurrent, useResource, useParentCategory } from 'components/hooks'
import { EQUIPMENT } from 'constants/resources'
import { Divider } from 'components/ui'
import { Destroy, TableDetail } from 'components/common'
import Information from './detail/Information'
import Header from './detail/Header'
import OrderItemList from './detail/OrderItemList'


function EquipmentDetail() {
  const selected = useCurrent(EQUIPMENT)
  const loading = useResource(EQUIPMENT, 'current', 'loading')
  const category = selected.has('category') && !selected.getIn(['category', 'parent']) ? selected.get('category') : new Map()
  const subcategory = selected.has('category') && selected.getIn(['category', 'parent']) ? selected.get('category') : new Map()
  const [parent, loadingParent] = useParentCategory(selected.getIn(['category', 'parent']))
  const canDestroy = !selected.get('number_of_orders')

  return (
    <TableDetail>
      <Header
        title={selected.get('code')}
        isPromoted={selected.get('is_promoted')}
        loading={loading}
      />
      <Divider spacing={28} />
      <Information
        name={selected.get('name')}
        description={selected.get('description')}
        nameTranslation={selected.getIn(['translations', 'fr', 'name'])}
        descriptionTranslation={selected.getIn(['translations', 'fr', 'description'])}
        image={selected.get('image')}
        searchTerms={selected.get('search_terms')}
        categoryName={parent.get('name') || category.get('name')}
        categoryId={parent.get('id') || category.get('id')}
        subcategoryName={subcategory.get('name')}
        subcategoryId={subcategory.get('id')}
        loading={loading}
        loadingParent={loadingParent}
      />
      <Divider spacing={20} />
      <OrderItemList
        equipmentId={selected.get('id')}
      />
      <Destroy resource={EQUIPMENT} id={selected.get('id')} show={canDestroy} />
    </TableDetail>
  )
}


export default EquipmentDetail
