import React from 'react'
import {
  Pagination, TableLoading, SectionHeader,
} from 'components/common'
import PropTypes from 'prop-types'

function InlineTable({
  title,
  action,
  hasNext,
  hasPrevious,
  onNext,
  onPrevious,
  header,
  children,
  loading,
}) {
  return (
    <>
      {
          title ? <SectionHeader fullSize title={title}>{action}</SectionHeader> : null
        }
      {
        header
      }
      {
        !loading ? children : null
      }
      <TableLoading loading={loading} rows={5} fullSize />
      {
          !loading ? (
            <Pagination
              onNext={onNext}
              onPrevious={onPrevious}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
            />
          ) : null
        }
    </>
  )
}

InlineTable.propTypes = {
  title: PropTypes.string,
  action: PropTypes.node,
  hasNext: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  header: PropTypes.node,
  loading: PropTypes.bool,
}

InlineTable.defaultProps = {
  title: '',
  hasNext: false,
  hasPrevious: false,
  onNext: null,
  onPrevious: null,
  loading: false,
}

export default InlineTable
