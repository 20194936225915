import React from 'react'
import { TableHeader, TableCell, Sort } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { USER } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'
import {
  USER_INDEX,
  USER_LASTSESSION_ASC_INDEX,
  USER_LASTSESSION_DESC_INDEX,
} from 'constants/algolia'
import { connectSortBy } from 'react-instantsearch-dom'
import { useDispatch } from 'react-redux'
import { users } from 'store/action-creators'

function UserHeader({
  refine, columns,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const selected = useResource(USER, 'current', 'id')
  const currentIndex = useResource(USER, 'list', 'search', 'index')
  const templateColumns = useDeviceColumns(columns, !!selected)

  const handleSortClick = (value) => {
    dispatch(users.setListIndex(value))
    if (refine) refine(value)
  }

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell sm>
        <p>{t('shared.name')}</p>
      </TableCell>
      <TableCell sm>
        <p>{t('resources.companies.singular')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('shared.email')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('shared.phone')}</p>
      </TableCell>
      <TableCell lg={!selected}>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={USER_INDEX}
          ascendantIndex={USER_LASTSESSION_ASC_INDEX}
          descendantIndex={USER_LASTSESSION_DESC_INDEX}
        >
          {t('shared.lastLogin')}
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <p />
      </TableCell>
    </TableHeader>
  )
}

UserHeader.propTypes = {
  columns: PropTypes.object.isRequired,
  refine: PropTypes.func,
}

UserHeader.defaultProps = {
  refine: null,
}

export default connectSortBy(UserHeader)
