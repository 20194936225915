import React from 'react'
import { Icon } from 'components/ui'
import styled from 'styled-components'

export const BannerStyled = styled.div`
  background-color: ${({ theme }) => theme.color.blue};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: ${({ theme }) => theme.color.primary};
    margin-left: 0.25em;
  }

  .underline {
    text-decoration: underline;
  }
`

function DevelopmentBanner() {
  if (!process.env.REACT_APP_API_URL.includes('staging')) return null

  return (
    <BannerStyled>
      <Icon name="science" size={18} />
      <span>
        You are running this application in
        {' '}
        <span className="underline">test</span>
        {' '}
        mode.
      </span>
    </BannerStyled>
  )
}

export default DevelopmentBanner
