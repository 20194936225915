function formatCurrency(amount = 0) {
  const newAmount = (amount === undefined || amount === null) ? 0 : amount
  const config = {
    minimumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
  }
  const formatter = new Intl.NumberFormat('en', config)
  return formatter.format(newAmount)
}

export default formatCurrency
