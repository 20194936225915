import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect, useCallback } from 'react'
import actions from 'store/action-creators'
import { parseQueryString } from 'utils'

function usePagination(resource) {
  const dispatch = useDispatch()
  const [nextParams, setNextParams] = useState({})
  const [previousParams, setPreviousParams] = useState({})
  const metadata = useSelector(state => state.getIn([resource, 'list', 'metadata']))
  const next = metadata.get('next')
  const previous = metadata.get('previous')
  useEffect(() => {
    if (next) {
      const nextObj = parseQueryString(new URL(next).search.substring(1))
      setNextParams(nextObj)
    }
    if (previous) {
      const previousObj = parseQueryString(new URL(previous).search.substring(1))
      setPreviousParams(previousObj)
    }
  }, [next, previous])

  const hasMore = !!next
  const refineNext = useCallback(
    () => {
      dispatch(actions[resource].getList(nextParams))
    },
    [dispatch, nextParams, resource],
  )
  const hasPrevious = !!previous
  const refinePrevious = useCallback(
    () => {
      dispatch(actions[resource].getList(previousParams))
    },
    [dispatch, previousParams, resource],
  )
  return {
    hasMore, refineNext, hasPrevious, refinePrevious,
  }
}

export default usePagination
