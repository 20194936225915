import React from 'react'
import PropTypes from 'prop-types'
import LoadingRow from './LoadingRow'

function Loading({ loading, rows, fullSize }) {
  if (!loading) return null
  return Array(rows).fill(3).map((item, index) => <LoadingRow key={`${item}-${index}`} fullSize={fullSize} />)
}

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  rows: PropTypes.number,
  fullSize: PropTypes.bool,
}

Loading.defaultProps = {
  rows: 30,
  fullSize: false,
}

export default Loading
