import React from 'react'
import PropTypes from 'prop-types'
import {
  Date, Detail, PreviewLink, SectionHeader,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { COMPANY, ORDER, USER } from 'constants/resources'

function Information({
  code,
  internalCode,
  action,
  title,
  companyName,
  companyId,
  purchaseOrder,
  rentallPurchaseOrder,
  startDate,
  billingDate,
  notes,
  loading,
  salesRepName,
  salesRepId,
  id,
}) {
  const { t } = useTranslation(['common', ORDER])

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={internalCode}
          loading={loading}
          label={t('common:shared.id')}
        >
          <PreviewLink
            resource={ORDER}
            id={id}
          >
            <span>
              {internalCode}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={code}
          loading={loading}
          label={t('common:shared.publicCode')}
        >
          {code}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink
            resource={COMPANY}
            id={companyId}
          >
            <span>
              {companyName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>

      <Grid item xs={12} md={6}>
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('common:shared.salesRep')}
        >
          <PreviewLink
            resource={USER}
            id={salesRepId}
          >
            <span>
              {salesRepName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={startDate}
          loading={loading}
          label={t('common:shared.startDate')}
        >
          <Date>{startDate}</Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={billingDate}
          loading={loading}
          label={t('order:shared.billingDate')}
        >
          <Date>
            {billingDate}
          </Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={purchaseOrder}
          loading={loading}
          label={t('order:shared.customerPO')}
        >
          {purchaseOrder}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={rentallPurchaseOrder}
          loading={loading}
          label={t('order:shared.rentallPO')}
        >
          {rentallPurchaseOrder}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.notes')}
        >
          {notes}
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  companyName: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  companyId: PropTypes.string,
  purchaseOrder: PropTypes.string,
  rentallPurchaseOrder: PropTypes.string,
  startDate: PropTypes.string,
  billingDate: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
  code: PropTypes.string,
  internalCode: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
}

Information.defaultProps = {
  action: null,
  companyName: '',
  id: '',
  title: '',
  companyId: '',
  purchaseOrder: '',
  rentallPurchaseOrder: '',
  startDate: '',
  billingDate: '',
  notes: '',
  loading: false,
  code: '',
  internalCode: '',
  salesRepName: '',
  salesRepId: '',
}

export default Information
