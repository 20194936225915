import React from 'react'
import { QuickFilters } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import { useTranslation } from 'react-i18next'

function Filters() {
  const { t } = useTranslation(SERVICE_REQUEST)
  return (
    <QuickFilters
      resource={SERVICE_REQUEST}
      options={[
        {
          value: {
            attribute: 'designation',
            value: 'off-rent',
            label: t('shared.off-rent'),
          },
          label: t('shared.off-rent'),
        },
        {
          value: {
            attribute: 'designation',
            value: 'order',
            label: t('shared.order'),
          },
          label: t('shared.order'),
        },
        {
          value: {
            attribute: 'designation',
            value: 'purchase-price',
            label: t('shared.purchase-price'),
          },
          label: t('shared.purchase-price'),
        },
        {
          value: {
            attribute: 'designation',
            value: 'rental-price',
            label: t('shared.rental-price'),
          },
          label: t('shared.rental-price'),
        },
      ]}
    />
  )
}

export default Filters
