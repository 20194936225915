import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import {
  Flex, Input, Button, PriceField,
} from 'components/common'
import { orderServices, orders } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { ORDER, ORDER_SERVICE } from 'constants/resources'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', ORDER_SERVICE, 'error'])
  const order = useSelector(state => state.getIn([ORDER, 'current', 'data']))
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register,
  } = useForm({ mode: 'onBlur' })

  const onSubmit = (body) => {
    if (update) {
      dispatch(orderServices.update(
        body,
        (response) => {
          const serviceID = order.get('services').findIndex(value => value.get('id') === response.id)
          const newOrder = order.setIn(['services', serviceID], new Map(response))
          dispatch(orders.setCurrentData(newOrder))
        },
      ))
    } else {
      dispatch(orderServices.create(
        body,
        (response) => {
          const services = order.get('services').push(Map({
            rate: response.rate,
            description: response.description,
            id: response.id,
          }))
          const newOrder = order.set('services', services)
          dispatch(orders.setCurrentData(newOrder))
        },
      ))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input
        type="hidden"
        name="order"
        ref={register}
        defaultValue={current.getIn(['order', 'id'])}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="description"
            required
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:date.required'),
              },
            }}
            error={errors.description?.message}
            type="text"
            label={t('common:shared.description')}
            placeholder={t('orderService:new.description.placeholder')}
            id="new-order-service-description"
            defaultValue={current.get('description')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="rate"
            required
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:date.required'),
              },
            }}
            error={errors.rate?.message}
            label={t('common:shared.cost')}
            placeholder={t('orderService:new.rate.placeholder')}
            id="new-order-service-rate"
            defaultValue={current.get('rate')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('orderService:edit.submit') : t('orderService:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
