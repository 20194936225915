import { createGlobalStyle, keyframes } from 'styled-components'
import { LG } from 'constants/breakpoints'

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export default createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.secondary};
    font-family: '${({ theme }) => theme.font.body.family}', sans-serif;
    transition: color 300ms ease-out;
    transition: background-color 300ms ease-out;
    span, p, h1, h2, h3, h4, h5, h6, a {
      -webkit-tap-highlight-color: transparent;
      font-family: '${({ theme }) => theme.font.body.family}', sans-serif;
      /* font-size: ${({ theme }) => theme.font.body.size}px; */
      font-weight: ${({ theme }) => theme.font.body.weight};
      transition: color 300ms ease-out;
      transition: background-color 300ms ease-out;
      transition: fill 300ms ease-out;
      color: ${({ theme }) => theme.color.secondary};
    }
  }
  ::selection { 
    background: ${({ theme }) => theme.color.accent}80; 
  } 
  p, a, span {
    font-family: '${({ theme }) => theme.font.body.family}', sans-serif;
    font-size: ${({ theme }) => theme.font.body.size}px;
    font-weight: ${({ theme }) => theme.font.body.weight};
    line-height: 1.2;
  }
  h1 {
    font-family: '${({ theme }) => theme.font.title.family}', sans-serif;
    font-size: ${({ theme }) => theme.font.title.size}px;
    font-weight: ${({ theme }) => theme.font.title.weight};
    line-height: 1.2;
    margin-bottom: 0.2em;
  }
  h2 {
    font-family: '${({ theme }) => theme.font.subtitle.family}', sans-serif;
    font-size: ${({ theme }) => theme.font.subtitle.size}px;
    font-weight: ${({ theme }) => theme.font.subtitle.weight};
    line-height: 1.2;
    margin-bottom: 0.2em;
  }
  h3, h4, h5, h6 {
    font-family: '${({ theme }) => theme.font.header.family}', sans-serif;
    font-size: ${({ theme }) => theme.font.header.size}px;
    font-weight: ${({ theme }) => theme.font.header.weight};
    line-height: 1.2;
    margin-bottom: 0.2em;
  }
  strong {
    font-weight: 600;
  }
  .loading {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    min-height: 1.2em;
    background-color: ${({ theme }) => theme.color.secondary}0D;
    margin-bottom: 0.2em;
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(90deg, transparent, ${({ theme }) => theme.color.secondary}0D, transparent);
      animation: ${loading} 1s infinite;
    }
  }
  @media (max-width: ${LG}px) {
    .desktop-only {
      display: none !important;
    }
  }
  @media (min-width: ${LG}px) {
    .mobile-only {
      display: none !important;
    }
  }
`
