import {
  put,
  call,
  select,
} from 'redux-saga/effects'
import services from 'services'
import actions from 'store/action-creators'
import notify from 'utils/notify'
import { NEW, EDIT } from 'constants/crud'
import i18n from 'config/i18n'
import nprogress from './nprogress'
import { closePreview } from './preview'

export function* list({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body: filters, onSuccess, onError } = payload
  const previousResults = yield select(state => state.getIn([resource, 'list', 'data']))
  const response = yield call(services[resource].list, filters)
  const { results, next, previous } = response
  if (results) {
    yield put(actions[resource].setListMetadata({ next, previous }))
    yield put(actions[resource].setListData([...previousResults.toJS(), ...results]))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(actions[resource].setListLoading(false))
}

export function* resetList({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body: filters, onSuccess, onError } = payload
  yield put(actions[resource].setListLoading(true))
  yield put(actions[resource].setListData([]))
  yield put(actions[resource].setListMetadata({ }))
  const response = yield call(services[resource].list, filters)
  const { results, next, previous } = response
  if (results) {
    yield put(actions[resource].setListData(results))
    yield put(actions[resource].setListMetadata({ next, previous }))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(actions[resource].setListLoading(false))
}

export function* get({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  yield put(actions[resource].setCurrentLoading(true))
  yield put(actions[resource].setCurrentId(id))
  const response = yield call(services[resource].get, id)
  if (response) {
    yield put(actions[resource].setCurrentData(response))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(actions[resource].setCurrentLoading(false))
}

export function* create({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  yield put(actions[resource].setCreateLoading(true))
  const response = yield call(services[resource].create, body)
  if (response) {
    yield put(actions[resource].prependListItem(response))
    yield call(notify, {
      text: i18n.t('common:shared.successCreate', { resource: response.name || response.code || response.description }),
    })
    yield call(closePreview, resource, NEW)
    yield put(actions[resource].setCurrentId(response.id))
    yield put(actions[resource].setCurrentData(response))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(actions[resource].setCreateLoading(false))
}

export function* update({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const id = yield select(store => store.getIn([resource, 'current', 'id']))
  yield put(actions[resource].setUpdateLoading(true))
  const response = yield call(services[resource].update, id, body)
  if (response) {
    yield put(actions[resource].updateListItem(response))
    yield call(notify, {
      text: i18n.t('common:shared.successUpdate', { resource: response.name || response.code || response.description }),
    })
    yield call(closePreview, resource, EDIT)
    yield put(actions[resource].setCurrentData(response))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(actions[resource].setUpdateLoading(false))
}

export function* destroy({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  yield put(actions[resource].setDeleteLoading(true))
  const response = yield call(services[resource].destroy, id)
  if (response) {
    yield call(notify, {
      text: i18n.t('common:shared.successDelete'),
    })
    yield put(actions[resource].removeListItem(id))
    yield put(actions[resource].setCurrentId(''))
    yield put(actions[resource].setCurrentData({}))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(actions[resource].setDeleteLoading(false))
}
