import {
  CREATE_RESERVATION_ORDER,
  CANCEL_RESERVATION,
} from 'store/actions/reservations'
import { RESERVATION } from 'constants/resources'
import createActionsWithResource, { createAction } from './factory'

const reservations = createActionsWithResource(RESERVATION)

export default {
  ...reservations,
  createOrder: createAction(CREATE_RESERVATION_ORDER),
  cancel: createAction(CANCEL_RESERVATION),
}
