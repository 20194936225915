import React from 'react'
import styled from 'styled-components'
import Row from './Row'

const HeaderStyled = styled(Row)`
  height: 50px;
  p {
    text-transform: uppercase;
  }
  span, p {
    color: ${({ theme }) => theme.color.secondary}80;
    font-weight: 500;
  }
`

function Header({
  children, ...otherProps
}) {
  return (
    <HeaderStyled {...otherProps}>
      {children}
    </HeaderStyled>
  )
}

export default Header
