import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  TableHeader, TableRow, PreviewLink, TableCell, Price, InlineTable,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EQUIPMENT, ORDER_ITEM } from 'constants/resources'
import { list as listOrderItems } from 'services/order-items'
import { usePaginatedList } from 'components/hooks'

function OrderItemList({ supplierId, onCount }) {
  const { t } = useTranslation(['common', ORDER_ITEM])
  const templateColumns = '2fr 1fr 1fr 1fr'
  const options = useMemo(() => (supplierId ? ({
    supplier: supplierId,
    limit: 5,
  }) : null), [supplierId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listOrderItems, options)
  useEffect(() => {
    if (onCount) onCount(count)
  }, [onCount, count])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={t('orderItem:shared.pastRates')}
          header={(
            <TableHeader
              fullSize
              templateColumns={templateColumns}
            >
              <p>{t('common:resources.equipment.singular')}</p>
              <p>{t('common:frequency.daily')}</p>
              <p>{t('common:frequency.weekly')}</p>
              <p>{t('common:frequency.monthly')}</p>
            </TableHeader>
          )}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(orderItem => (
            <TableRow
              key={orderItem.get('id')}
              fullSize
              templateColumns={templateColumns}
            >
              <TableCell sm>
                <PreviewLink resource={EQUIPMENT} id={orderItem.getIn(['equipment', 'id'])}>
                  {orderItem.getIn(['equipment', 'name'])}
                </PreviewLink>
              </TableCell>
              <TableCell sm>
                <Price amount={orderItem.getIn(['metadata', 'supplier', 'daily_rate'])} />
              </TableCell>
              <TableCell sm>
                <Price amount={orderItem.getIn(['metadata', 'supplier', 'weekly_rate'])} />
              </TableCell>
              <TableCell sm>
                <Price amount={orderItem.getIn(['metadata', 'supplier', 'monthly_rate'])} />
              </TableCell>
            </TableRow>
          ))
      }
        </InlineTable>
      </Grid>
    </Grid>
  )
}


OrderItemList.propTypes = {
  supplierId: PropTypes.string,
  onCount: PropTypes.func,
}

OrderItemList.defaultProps = {
  supplierId: '',
  onCount: null,
}


export default OrderItemList
