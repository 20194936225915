import React from 'react'
import { OrderItemContent, OrderItemHeader, OrderItemControls } from 'components/pages/order-items'
import { withAlgolia } from 'components/hocs'
import { ORDER_ITEM } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import { Link } from 'react-router-dom'
import {
  ORDER_ITEM_INDEX,
  ORDER_ITEM_OFFRENTEDAT_ASC_INDEX,
  ORDER_ITEM_OFFRENTEDAT_DESC_INDEX,
  ORDER_ITEM_STARTDATE_ASC_INDEX,
  ORDER_ITEM_STARTDATE_DESC_INDEX,
  ORDER_ITEM_BILLINGDATE_ASC_INDEX,
  ORDER_ITEM_BILLINGDATE_DESC_INDEX,
} from 'constants/algolia'
import routes from 'constants/routes'

function OrderItemTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1fr 2fr 1fr',
    md: '1fr 1fr 2fr 1fr 2fr 1fr',
    lg: '1fr 1fr 2fr 2fr 0.5fr 1fr 1fr 1.5fr 1fr',
    xl: '1fr 1fr 2fr 2fr 2fr 0.5fr 1fr 1fr 1.5fr 1fr',
  }

  return (
    <Table
      title={t('resources.orderItems.plural')}
      subtitle={<Link to={routes.orders}><h1>{t('resources.orders.plural')}</h1></Link>}
      controls={<OrderItemControls />}
      resource={ORDER_ITEM}
      header={(
        <OrderItemHeader
          columns={columns}
          defaultRefinement={ORDER_ITEM_INDEX}
          items={[
            ORDER_ITEM_OFFRENTEDAT_ASC_INDEX,
            ORDER_ITEM_OFFRENTEDAT_DESC_INDEX,
            ORDER_ITEM_STARTDATE_ASC_INDEX,
            ORDER_ITEM_STARTDATE_DESC_INDEX,
            ORDER_ITEM_BILLINGDATE_ASC_INDEX,
            ORDER_ITEM_BILLINGDATE_DESC_INDEX,
          ]}
        />)}
      content={(
        <OrderItemContent
          columns={columns}
        />
      )}
    />
  )
}

export default withAlgolia(ORDER_ITEM_INDEX)(OrderItemTable)
