import { useState, useEffect } from 'react'

function useRatings(list) {
  const [rating, setRating] = useState({
    deliveries: 0,
    serviceCalls: 0,
    responseTime: 0,
    pricing: 0,
  })

  useEffect(() => {
    let deliveries = 0
    let serviceCalls = 0
    let responseTime = 0
    let pricing = 0
    list.forEach((review) => {
      deliveries += review.get('deliveries')
      serviceCalls += review.get('service_calls')
      responseTime += review.get('response_time')
      pricing += review.get('pricing')
    })
    if (list.size) {
      deliveries /= list.size
      serviceCalls /= list.size
      responseTime /= list.size
      pricing /= list.size
    }
    setRating({
      deliveries,
      serviceCalls,
      responseTime,
      pricing,
    })
  }, [list])


  return rating
}

export default useRatings
