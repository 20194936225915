import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useQuickPreview } from 'components/hooks'
import { SERVICE_REQUEST, ORDER_ITEM } from 'constants/resources'
import { NEW } from 'constants/crud'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'
import { CANCELLED, FINALIZED } from 'constants/status'
import { List } from 'immutable'
import { SERVICE_CALL } from 'constants/designations'

function PlaceService({
  status,
  orderItemId,
  addressLine1,
  addressLine2,
  addressZip,
  addressCity,
  addressState,
  addressCountryCode,
  contacts,
  salesRepId,
  designation,
}) {
  const { t } = useTranslation(['common', ORDER_ITEM])
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()
  const label = designation === SERVICE_CALL ? t('orderItem:shared.service') : t('orderItem:shared.offRent')
  const icon = designation === SERVICE_CALL ? 'phone' : 'flag'
  const handleChipClick = (event) => {
    event.stopPropagation()
    dispatch(serviceRequests.setCreateData({
      designation,
      order_item: { id: orderItemId },
      address_line1: addressLine1,
      address_line2: addressLine2,
      address_zip: addressZip,
      address_city: addressCity,
      address_state: addressState,
      address_country: { code: addressCountryCode },
      sales_rep: { id: salesRepId },
      metadata: {
        contacts,
      },
    }))
    openPreview(SERVICE_REQUEST, NEW)
  }

  if ([CANCELLED, FINALIZED].includes(status)) return null

  return (
    <Chip
      onClick={handleChipClick}
      outline
      icon={icon}
      label={label}
    />
  )
}

PlaceService.propTypes = {
  status: PropTypes.string,
  salesRepId: PropTypes.string,
  orderItemId: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressZip: PropTypes.string,
  addressCity: PropTypes.string,
  addressState: PropTypes.string,
  addressCountryCode: PropTypes.string,
  contacts: PropTypes.instanceOf(List),
  designation: PropTypes.string,
}

PlaceService.defaultProps = {
  status: '',
  salesRepId: '',
  orderItemId: '',
  addressLine1: '',
  addressLine2: '',
  addressZip: '',
  addressCity: '',
  addressState: '',
  addressCountryCode: '',
  designation: 'service-call',
  contacts: new List(),
}


export default PlaceService
