import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, PreviewLink, PhoneNumber, SectionHeader,
} from 'components/common'
import { COMPANY } from 'constants/resources'

function Information({
  action,
  title,
  name,
  companyName,
  companyId,
  email,
  contactNumber,
  loading,
}) {
  const { t } = useTranslation('common')
  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={name}
          loading={loading}
          label={t('common:shared.name')}
        >
          {name}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink resource={COMPANY} id={companyId}>
            {companyName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={email}
          loading={loading}
          label={t('common:shared.email')}
        >
          <a href={`mailto:${email}`}>{email}</a>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.phone')}
        >
          <PhoneNumber number={contactNumber} />
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  name: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.string,
  contactNumber: PropTypes.string,
  loading: PropTypes.bool,
}

Information.defaultProps = {
  action: null,
  name: '',
  companyName: '',
  companyId: '',
  email: '',
  title: '',
  contactNumber: '',
  loading: false,
}

export default Information
