import React from 'react'
import PropTypes from 'prop-types'
import { Detail, PhoneNumber, SectionHeader } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { capitalize } from 'utils'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import { SUPPLIER } from 'constants/resources'

function Information({
  action,
  title,
  name,
  categories,
  code,
  branchNumber,
  contactName,
  contactNumber,
  notes,
  loading,
}) {
  const { t } = useTranslation(['common', SUPPLIER])

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={name}
          loading={loading}
          label={t('common:shared.name')}
        >
          {name}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={categories}
          loading={loading}
          label={t('common:resources.categories.singular')}
        >
          {categories ? categories.map(w => capitalize(w)).join(', ') : null}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={code}
          loading={loading}
          label={t('common:shared.code')}
        >
          {code}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={branchNumber}
          loading={loading}
          label={t('supplier:shared.mainNumber')}
        >
          <PhoneNumber number={branchNumber} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactName}
          loading={loading}
          label={t('common:shared.contactName')}
        >
          {contactName}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.contactNumber')}
        >
          <PhoneNumber number={contactNumber} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.notes')}
        >
          {notes}
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  name: PropTypes.string,
  categories: PropTypes.instanceOf(List),
  code: PropTypes.string,
  title: PropTypes.string,
  branchNumber: PropTypes.string,
  contactName: PropTypes.string,
  contactNumber: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
}

Information.defaultProps = {
  action: null,
  name: '',
  categories: new List(),
  code: '',
  title: '',
  branchNumber: '',
  contactName: '',
  contactNumber: '',
  notes: '',
  loading: false,
}

export default Information
