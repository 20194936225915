import React from 'react'
import styled from 'styled-components'

const ControlsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: ${({ theme }) => theme.spacing.medium}px;
  }
`

function Controls({
  children, ...otherProps
}) {
  return (
    <ControlsStyled {...otherProps}>
      {children}
    </ControlsStyled>
  )
}

export default Controls
