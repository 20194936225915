import React, { useCallback } from 'react'
import { Divider } from 'components/ui'
import { useSessions, useTheme } from 'components/hooks'
import { Input, Logo, Button } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { EMAIL } from 'constants/patterns'
import { Grid } from '@material-ui/core'


function AuthForm(props) {
  const { t } = useTranslation(['session', 'error'])
  const [login] = useSessions()
  const [theme] = useTheme()
  const {
    handleSubmit, errors, register,
  } = useForm({ mode: 'onBlur' })

  const onSubmit = useCallback(
    (data) => {
      login(data.email, data.password)
    },
    [login],
  )

  return (
    <Grid container justify="center" {...props}>
      <Grid item xs={12} sm={4} md={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justify="center">
            <Logo height={30} color={theme.color.secondary} />
          </Grid>
          <Divider spacing={20} />
          <Input
            id="email"
            name="email"
            forwardRef={register({
              required: {
                value: true,
                message: t('error:email.required'),
              },
              pattern: {
                value: EMAIL,
                message: t('error:email.pattern'),
              },
            })}
            error={errors.email?.message}
            label={t('session:login.email')}
            placeholder="you@rentallconstruction.com"
            type="email"
          />
          <Divider spacing={20} />
          <Input
            id="password"
            name="password"
            forwardRef={register({
              required: {
                value: true,
                message: t('error:shared.required'),
              },
            })}
            error={errors.password?.message}
            label={t('session:login.password')}
            placeholder="*********"
            type="password"
          />
          <Divider spacing={20} />
          <Button
            type="submit"
            fullSize
          >
            {t('login.submit')}
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}

export default AuthForm
