import React from 'react'
import { CompanyRow } from 'components/pages/companies'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { COMPANY } from 'constants/resources'
import {
  useResource, useStoreAlgolia, useSelectResource, useDeviceColumns, useFacetFilters,
} from 'components/hooks'
import { companies } from 'store/action-creators'
import PropTypes from 'prop-types'

function CompanyContent({
  hits, hasMore, refineNext, hasPrevious, refinePrevious, columns,
}) {
  const selected = useResource(COMPANY, 'current', 'id')
  const list = useStoreAlgolia(COMPANY, companies, hits)
  const loading = useResource(COMPANY, 'list', 'loading')
  const handleRowClick = useSelectResource(COMPANY)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(COMPANY)

  return (
    <TableContent
      next={refineNext}
      dataLength={hits.length}
      hasMore={hasMore}
    >
      <Configure
        hitsPerPage={40}
        filters="is_redacted:false"
        facetFilters={facetFilters}
      />
      <TableLoading loading={loading} />
      {
        list.map(company => (
          <CompanyRow
            key={company.get('id')}
            shrink={!!selected}
            totalUsers={company.get('total_users')}
            ownerName={company.getIn(['first_owner', 'name'])}
            ownerId={company.getIn(['first_owner', 'id'])}
            isRedacted={company.get('is_redacted')}
            id={company.get('id')}
            templateColumns={templateColumns}
            name={company.get('name')}
            onRent={company.get('on_rent_orders')}
            totalOrders={company.get('total_orders')}
            active={company.get('id') === selected}
            salesRepName={company.getIn(['sales_rep', 'name'])}
            salesRepId={company.getIn(['sales_rep', 'id'])}
            onClick={() => handleRowClick(company.get('id'))}
          />
        ))
      }
    </TableContent>
  )
}

CompanyContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired,
}

export default connectInfiniteHits(CompanyContent)
