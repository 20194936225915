import { SERVICE_REQUEST } from 'constants/resources'
import { SERVICE_REQUEST_PING } from 'store/actions/serviceRequests'
import createActionsWithResource, { createAction } from './factory'

const serviceRequests = createActionsWithResource(SERVICE_REQUEST)

export default {
  ...serviceRequests,
  ping: createAction(SERVICE_REQUEST_PING),
}
