import React from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Input, Button } from 'components/common'
import { categories } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { CategorySelect } from 'components/pages/categories'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { CATEGORY } from 'constants/resources'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', CATEGORY, 'error'])
  const dispatch = useDispatch()
  const { handleSubmit, control, errors } = useForm({ mode: 'onBlur' })

  const onSubmit = (body) => {
    if (update) {
      dispatch(categories.update(body))
    } else {
      dispatch(categories.create(body))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>{t('shared.information')}</h1>
      <Divider spacing={20} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:name.required'),
              },
            }}
            error={errors.name?.message}
            type="text"
            label={`${t('shared.name')} (${t('languages.en')})`}
            id="new-category-name-english"
            placeholder="Fencing"
            defaultValue={current.get('name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="translations.fr.name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:name.required'),
              },
            }}
            error={errors.translations?.fr?.name?.message}
            type="text"
            label={`${t('shared.name')} (${t('languages.fr')})`}
            id="new-category-name-french"
            placeholder="Barriere"
            defaultValue={current.getIn(['translations', 'fr', 'name'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={CategorySelect}
            name="parent"
            control={control}
            error={errors.name?.message}
            type="select"
            label={t('category:new.parent.label')}
            id="new-category-category"
            defaultValue={current.get('parent')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('category:edit.submit') : t('category:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}


export default Form
