import React from 'react'
import { Chip } from 'components/common'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { NOTIFICATION } from 'constants/resources'

function AcknowledgeButton({
  show,
  acknowledge,
}) {
  const { t } = useTranslation(NOTIFICATION)
  const canAcknowledge = show
  if (!canAcknowledge) return null

  return (
    <Chip
      outline
      icon="done_all"
      onClick={acknowledge}
      label={t('shared.acknowledge')}
    />
  )
}

AcknowledgeButton.propTypes = {
  show: PropTypes.bool,
  acknowledge: PropTypes.func.isRequired,
}

AcknowledgeButton.defaultProps = {
  show: false,
}
export default AcknowledgeButton
