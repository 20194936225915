import { useDispatch, useSelector } from 'react-redux'
import { notifications, application } from 'store/action-creators'
import { useCallback } from 'react'

function useNotifications() {
  const dispatch = useDispatch()
  const id = useSelector(state => state.getIn(['session', 'user', 'id']))
  const handleSuccessList = useCallback(
    ({ results }) => {
      if (results.length && results.some(n => !n.acknowledged_at)) {
        dispatch(application.setNotification(true))
      }
    },
    [dispatch],
  )

  const fetchCreated = useCallback(
    () => {
      dispatch(notifications.getListReset(
        {
          status: 'created',
          receiver: id || 'null',
          limit: 40,
        },
        handleSuccessList,
      ))
    },
    [dispatch, handleSuccessList, id],
  )

  const fetchResolved = useCallback(
    () => {
      dispatch(notifications.getListReset({
        status: 'acknowledged',
        receiver: id || 'null',
        limit: 40,
      }))
    },
    [dispatch, id],
  )

  return [fetchCreated, fetchResolved]
}

export default useNotifications
