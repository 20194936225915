import React from 'react'
import PropTypes from 'prop-types'
import {
  Price, Detail, PreviewLink, SectionHeader,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { SUPPLIER, ORDER_ITEM } from 'constants/resources'

function TransportSupplier({
  supplierName,
  supplierId,
  purchaseOrder,
  deliveryCost,
  pickupCost,
  contactName,
  contactNumber,
  loading,
}) {
  const { t } = useTranslation(['common', ORDER_ITEM])

  return (
    <Grid container spacing={2}>
      <SectionHeader title={t('orderItem:shared.transportSupplier')} />
      <Grid item xs={12} md={6}>
        <Detail
          text={supplierName}
          loading={loading}
          label={t('common:resources.suppliers.singular')}
        >
          <PreviewLink
            resource={SUPPLIER}
            id={supplierId}
          >
            <span>
              {supplierName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <Detail
          text={contactName}
          loading={loading}
          label={t('common:shared.contactName')}
        >
          {contactName}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.contactNumber')}
        >
          <PhoneNumber number={contactNumber} />
        </Detail>
      </Grid> */}
      <Grid item xs={12} md={6}>
        <Detail
          text={deliveryCost}
          loading={loading}
          label={t('orderItem:shared.deliveryCost')}
        >
          <Price amount={deliveryCost} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={pickupCost}
          loading={loading}
          label={t('orderItem:shared.pickupCost')}
        >
          <Price amount={pickupCost} />
        </Detail>
      </Grid>
    </Grid>
  )
}

TransportSupplier.propTypes = {
  supplierName: PropTypes.string,
  supplierId: PropTypes.string,
  contactName: PropTypes.string,
  contactNumber: PropTypes.string,
  purchaseOrder: PropTypes.string,
  deliveryCost: PropTypes.number,
  pickupCost: PropTypes.number,
  loading: PropTypes.bool,
}

TransportSupplier.defaultProps = {
  supplierName: '',
  supplierId: '',
  purchaseOrder: '',
  contactName: '',
  contactNumber: '',
  deliveryCost: null,
  pickupCost: null,
  loading: false,
}


export default TransportSupplier
