import React, { useMemo } from 'react'
import {
  InlineTable, TableRow, TableHeader, PreviewLink, Date, Chip,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { list as listOrders } from 'services/orders'
import { COMPANY, ORDER } from 'constants/resources'
import { usePaginatedList, useStatusColors } from 'components/hooks'
import { Grid } from '@material-ui/core'

function OrderList({ companyId }) {
  const { t } = useTranslation([COMPANY, 'common', 'orderItem'])
  const statusColors = useStatusColors(ORDER)
  const templateColumns = '1fr 1fr 1fr'
  const options = useMemo(() => (companyId ? ({
    company: companyId,
    limit: 5,
  }) : null), [companyId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listOrders, options)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('company:orderList.header')} (${count})`}
          header={(
            <TableHeader
              fullSize
              templateColumns={templateColumns}
            >
              <p>{t('common:shared.id')}</p>
              <p>{t('common:shared.status')}</p>
              <p>{t('common:shared.startDate')}</p>
            </TableHeader>
          )}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(item => (
            <TableRow
              key={item.get('id')}
              fullSize
              templateColumns={templateColumns}
            >
              <PreviewLink
                resource={ORDER}
                id={item.get('id')}
              >
                {item.get('internal_code')}
              </PreviewLink>
              <div>
                <Chip
                  label={t(`order:status.${item.get('status')}`)}
                  background={statusColors[item.get('status')]}
                />
              </div>
              <Date>{item.get('start_date')}</Date>
            </TableRow>
          ))
        }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

OrderList.propTypes = {
  companyId: PropTypes.string,
}

OrderList.defaultProps = {
  companyId: '',
}

export default OrderList
