import styled from 'styled-components'

const Link = styled.span`
  color: ${({ theme }) => theme.color.secondary};
  text-decoration: underline;
  position: relative;
  cursor: pointer;
  line-height: 1.5;
`

export default Link
