import React, { useMemo } from 'react'
import { useResource } from 'components/hooks'
import { Chip } from 'components/common'
import { useDispatch } from 'react-redux'
import actions from 'store/action-creators'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import styled from 'styled-components'

const StyledFilters = styled.div`
  overflow-x: scroll;
  flex-grow: 1;
  display: flex; 
  justify-content: flex-end;
  &::-webkit-scrollbar {
    display: none;
  }
`

function ActiveFilters({ resource }) {
  const dispatch = useDispatch()
  const facetFilters = useResource(resource, 'list', 'search', 'facetFilters')
  const filters = useMemo(() => {
    const filterList = []
    facetFilters.forEach((value, key) => {
      if (List.isList(value)) {
        value.forEach((val) => {
          filterList.push([key, val.get('label'), val.get('value')])
        })
      } else {
        filterList.push([key, value.get('label'), value.get('label')])
      }
    })
    return filterList
  }, [facetFilters])

  const handleChipClick = (attribute, value) => {
    dispatch(actions[resource].removeListSearchFacetFilters({ attribute, value }))
  }
  return (
    <StyledFilters>
      {
        filters.map(([key, label, value]) => (
          <Chip
            key={value}
            onClick={() => { handleChipClick(key, value) }}
            outline
            icon="clear"
            label={label}
          />
        ))
      }
    </StyledFilters>
  )
}

ActiveFilters.propTypes = {
  resource: PropTypes.string.isRequired,
}

export default ActiveFilters
