export const backspace = 8
export const tab = 9
export const enter = 13
export const shift = 16
export const ctrl = 17
export const alt = 18
export const pauseBreak = 19
export const capsLock = 20
export const escape = 27
export const pageUp = 33
export const pageDown = 34
export const end = 35
export const home = 36
export const leftArrow = 37
export const upArrow = 38
export const rightArrow = 39
export const downArrow = 40
export const insert = 45
// export const delete = 46
export const zero = 48
export const one = 49
export const two = 50
export const three = 51
export const four = 52
export const five = 53
export const six = 54
export const seven = 55
export const eight = 56
export const nine = 57
export const a = 65
export const b = 66
export const c = 67
export const d = 68
export const e = 69
export const f = 70
export const g = 71
export const h = 72
export const i = 73
export const j = 74
export const k = 75
export const l = 76
export const m = 77
export const n = 78
export const o = 79
export const p = 80
export const q = 81
export const r = 82
export const s = 83
export const t = 84
export const u = 85
export const v = 86
export const w = 87
export const x = 88
export const y = 89
export const z = 90
export const leftWindowKey = 91
export const rightWindowKey = 92
export const selectKey = 93
export const numpadZero = 96
export const numpadOne = 97
export const numpadTwo = 98
export const numpadThree = 99
export const numpadFour = 100
export const numpadFive = 101
export const numpadSix = 102
export const numpadSeven = 103
export const numpadEight = 104
export const numpadNine = 105
export const multiply = 106
export const add = 107
export const subtract = 109
export const decimalPoint = 110
export const divide = 111
export const f1 = 112
export const f2 = 113
export const f3 = 114
export const f4 = 115
export const f5 = 116
export const f6 = 117
export const f7 = 118
export const f8 = 119
export const f9 = 120
export const f10 = 121
export const f11 = 122
export const f12 = 123
export const numLock = 144
export const scrollLock = 145
export const semiColon = 186
export const equalSign = 187
export const comma = 188
export const dash = 189
export const period = 190
export const forwardSlash = 191
export const graveAccent = 192
export const openBracket = 219
export const backSlash = 220
export const closeBraket = 221
export const singleQuote = 222
