import React from 'react'
import { TableHeader, TableCell } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CATEGORY } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'

function CategoryHeader({ columns }) {
  const { t } = useTranslation('common')
  const selected = useResource(CATEGORY, 'current', 'id')
  const templateColumns = useDeviceColumns(columns, !!selected)


  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell sm>
        {t('shared.name')}
      </TableCell>
      <TableCell lg={!selected}>
        {t('shared.translation')}
      </TableCell>
    </TableHeader>
  )
}

CategoryHeader.propTypes = {
  columns: PropTypes.object.isRequired,
}

export default CategoryHeader
