import React from 'react'
import { useCurrent, useResource } from 'components/hooks'
import { SERVICE_REQUEST } from 'constants/resources'
import {
  OFF_RENT, ORDER, PURCHASE_PRICE, RENTAL_PRICE, SERVICE_CALL,
} from 'constants/designations'
import { TableDetail } from 'components/common'
import { Divider } from 'components/ui'
import OffRentDetail from './detail/OffRentDetail'
import OrderDetail from './detail/OrderDetail'
import PurchasePriceDetail from './detail/PurchasePriceDetail'
import RentalPriceDetail from './detail/RentalPriceDetail'
import ServiceCallDetail from './detail/ServiceCallDetail'
import Header from './detail/Header'

function ServiceRequestDetail() {
  const selected = useCurrent(SERVICE_REQUEST)
  const loading = useResource(SERVICE_REQUEST, 'current', 'loading')

  let content
  if (selected.get('designation') === OFF_RENT) content = <OffRentDetail selected={selected} loading={loading} />
  if (selected.get('designation') === ORDER) content = <OrderDetail selected={selected} loading={loading} />
  if (selected.get('designation') === PURCHASE_PRICE) content = <PurchasePriceDetail selected={selected} loading={loading} />
  if (selected.get('designation') === RENTAL_PRICE) content = <RentalPriceDetail selected={selected} loading={loading} />
  if (selected.get('designation') === SERVICE_CALL) content = <ServiceCallDetail selected={selected} loading={loading} />

  return (
    <TableDetail>
      <Header
        id={selected.get('id')}
        designation={selected.get('designation')}
        title={selected.get('code')}
        status={selected.get('status')}
        loading={loading}
        serviceRequest={selected}
        isOrphan={!selected.getIn(['assignee', 'id'])}
      />
      <Divider spacing={28} />
      {content}
    </TableDetail>
  )
}


export default ServiceRequestDetail
