import React from 'react'
import { TableHeader, TableCell, Sort } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ORDER } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'
import {
  ORDER_INDEX,
  ORDER_STARTDATE_ASC_INDEX,
  ORDER_STARTDATE_DESC_INDEX,
  ORDER_BILLINGDATE_ASC_INDEX,
  ORDER_BILLINGDATE_DESC_INDEX,
  ORDER_CREATED_ASC_INDEX,
  ORDER_CREATED_DESC_INDEX,
} from 'constants/algolia'
import { useDispatch } from 'react-redux'
import { orders } from 'store/action-creators'
import { connectSortBy } from 'react-instantsearch-dom'

function OrderHeader({
  refine, columns,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', ORDER])
  const selected = useResource(ORDER, 'current', 'id')
  const currentIndex = useResource(ORDER, 'list', 'search', 'index')
  const templateColumns = useDeviceColumns(columns, !!selected)

  const handleSortClick = (value) => {
    dispatch(orders.setListIndex(value))
    if (refine) refine(value)
  }

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={ORDER_INDEX}
          ascendantIndex={ORDER_CREATED_ASC_INDEX}
          descendantIndex={ORDER_CREATED_DESC_INDEX}
        >
          <p>{t('common:shared.id')}</p>
        </Sort>
      </TableCell>
      <TableCell sm>
        <p>{t('common:shared.status')}</p>
      </TableCell>
      <TableCell sm>
        <p>{t('common:resources.companies.singular')}</p>
      </TableCell>
      <TableCell sm lg={!selected}>
        <p>{t('common:shared.address')}</p>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:resources.equipment.singular')}</p>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={ORDER_INDEX}
          ascendantIndex={ORDER_STARTDATE_ASC_INDEX}
          descendantIndex={ORDER_STARTDATE_DESC_INDEX}
        >
          {t('common:shared.startDate')}
        </Sort>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={ORDER_INDEX}
          ascendantIndex={ORDER_BILLINGDATE_ASC_INDEX}
          descendantIndex={ORDER_BILLINGDATE_DESC_INDEX}
        >
          {t('order:shared.billingDate')}
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:shared.salesRep')}</p>
      </TableCell>
    </TableHeader>
  )
}

OrderHeader.propTypes = {
  columns: PropTypes.object.isRequired,
  refine: PropTypes.func,
}

OrderHeader.defaultProps = {
  refine: null,
}

export default connectSortBy(OrderHeader)
