import React, { useEffect, useState } from 'react'
import { useCurrent, useResource } from 'components/hooks'
import { ORDER } from 'constants/resources'
import { Divider } from 'components/ui'
import {
  ContactSection, AddressSection, Destroy, TableDetail,
} from 'components/common'
import { list as listOrderItems } from 'services/order-items'
import { fromJS } from 'immutable'
import { useDispatch } from 'react-redux'
import { orders } from 'store/action-creators'
import Information from './detail/Information'
import OrderItems from './detail/Items'
import OrderServices from './detail/Services'
import Header from './detail/Header'

function OrderDetail() {
  const selected = useCurrent(ORDER)
  const dispatch = useDispatch()
  const loading = useResource(ORDER, 'current', 'loading')
  const [selectedItem, setSelectedItem] = useState('')
  const id = selected.get('id')
  useEffect(() => {
    if (id && !selected.has('orderItems')) {
      listOrderItems({ order: id })
        .then((response) => {
          dispatch(orders.setCurrentData(selected.set('orderItems', fromJS(response.results))))
        })
    }
  }, [dispatch, id, selected])

  const handleItemClick = (selectedId) => {
    if (selectedItem === selectedId) {
      setSelectedItem('')
    } else {
      setSelectedItem(selectedId)
    }
  }

  return (
    <TableDetail>
      <Header
        id={id}
        order={selected}
        status={selected.get('status')}
        title={selected.get('internal_code')}
        loading={loading}
      />
      <Divider spacing={28} />
      <Information
        code={selected.get('code')}
        internalCode={selected.get('internal_code')}
        companyName={selected.getIn(['company', 'name'])}
        companyId={selected.getIn(['company', 'id'])}
        purchaseOrder={selected.get('external_id')}
        rentallPurchaseOrder={selected.getIn(['metadata', 'rentall_external_id'])}
        startDate={selected.get('start_date')}
        billingDate={selected.get('billing_date')}
        notes={selected.get('notes')}
        loading={loading}
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        id={selected.get('id')}
      />
      <Divider spacing={20} />
      <ContactSection
        list={selected.getIn(['metadata', 'contacts'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <OrderServices
        order={selected}
        list={selected.get('services')}
      />
      <Divider spacing={20} />
      <OrderItems
        onClick={handleItemClick}
        list={selected.get('orderItems')}
        selected={selectedItem}
      />
      <Destroy resource={ORDER} id={id} show />
    </TableDetail>
  )
}


export default OrderDetail
