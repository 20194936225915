import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components/ui'
import styled from 'styled-components'

const StyledRatingField = styled.div`
  .star {
    cursor: pointer;
    transition: 300ms ease-out;
    &:hover {
      transform: scale(1.2)
    }
    &:active {
      transform: scale(1.6)
    }
  }
`

function RatingField({ onChange }) {
  const initialState = [false, false, false, false, false]
  const [score, setScore] = useState(initialState)

  const handleStarClick = (event) => {
    const index = +event.currentTarget.dataset.ratingValue
    const newScore = [...initialState]
    newScore.fill(true, 0, index + 1)
    setScore(newScore)
    if (onChange) onChange(index + 1)
  }

  return (
    <StyledRatingField>
      {
        score.map((value, index) => (
          <Icon
            data-rating-value={index}
            className="star"
            onClick={handleStarClick}
            name={value ? 'star' : 'star_border'}
            size={20}
          />
        ))
      }
    </StyledRatingField>
  )
}

RatingField.propTypes = {
  onChange: PropTypes.func.isRequired,
}


export default RatingField
