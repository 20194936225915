import React from 'react'
import { Input } from 'components/common'

function PurchaseOrderField({ onChange, ...otherProps }) {
  const handleChange = (value) => {
    onChange(value.toUpperCase())
  }
  return (
    <Input
      type="text"
      placeholder="H5N5"
      onChange={handleChange}
      {...otherProps}
    />
  )
}


export default PurchaseOrderField
