import React from 'react'
import {
  Flex, Rating, Button, TableDetail, Input,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { supplierReviews } from 'store/action-creators'
import { useResource, useCurrent } from 'components/hooks'
import { SUPPLIER_REVIEW, SUPPLIER } from 'constants/resources'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

function SupplierReviewNew() {
  const { t } = useTranslation([SUPPLIER_REVIEW, 'common'])
  const dispatch = useDispatch()
  const form = useResource(SUPPLIER_REVIEW, 'create')
  const supplier = useCurrent(SUPPLIER)
  const loading = useResource(SUPPLIER_REVIEW, 'create', 'loading')

  const handleSubmit = (event) => {
    event.preventDefault()
    const body = {
      supplier: form.get('supplier'),
      notes: form.get('notes'),
      deliveries: form.get('deliveries') || 1,
      service_calls: form.get('serviceCalls') || 1,
      response_time: form.get('responseTime') || 1,
      pricing: form.get('pricing') || 1,
    }
    dispatch(supplierReviews.create(body))
  }

  const handleChange = (data) => {
    dispatch(supplierReviews.setCreateData(data))
  }

  return (
    <TableDetail>
      <form onSubmit={handleSubmit}>
        <Grid container xs={12} md={12}>
          <Grid item xs={12} md={12}>
            <h1>{t('new.header')}</h1>
          </Grid>
          <Divider spacing={20} />
          <Grid item xs={12} md={12}>
            <Flex align="center" justify="space-between">
              <p>{t('shared.response')}</p>
              <Rating onChange={(event, responseTime) => handleChange({ responseTime })} size="large" />
            </Flex>
          </Grid>
          <Divider spacing={20} />
          <Grid item xs={12} md={12}>
            <Flex align="center" justify="space-between">
              <p>{t('shared.delivery')}</p>
              <Rating onChange={(event, deliveries) => handleChange({ deliveries })} size="large" />
            </Flex>
          </Grid>
          <Divider spacing={20} />
          <Grid item xs={12} md={12}>
            <Flex align="center" justify="space-between">
              <p>{t('shared.service')}</p>
              <Rating onChange={(event, serviceCalls) => handleChange({ serviceCalls })} size="large" />
            </Flex>
          </Grid>
          <Divider spacing={20} />
          <Grid item xs={12} md={12}>
            <Flex align="center" justify="space-between">
              <p>{t('shared.pricing')}</p>
              <Rating onChange={(event, pricing) => handleChange({ pricing })} size="large" />
            </Flex>
          </Grid>
          <Divider spacing={20} />
          <Grid item xs={12} md={12}>
            <Input
              type="text"
              name="notes"
              multiline
              label={t('common:shared.notes')}
              placeholder={t('new.notes.placeholder', { name: supplier.get('name') })}
              id="new-rating-note"
              value={form.get('notes')}
              disabled={loading}
              onChange={(notes => handleChange({ notes }))}
            />
          </Grid>
        </Grid>
        <Divider spacing={20} />
        <Button
          type="submit"
          fullSize
        >
          {t('new.submit')}
        </Button>
      </form>
    </TableDetail>
  )
}


export default SupplierReviewNew
