import React from 'react'
import { Input } from 'components/common'
import states from 'data/states.json'
import { useTranslation } from 'react-i18next'

function StateSelect(props) {
  const { t } = useTranslation('common')
  return (
    <Input
      type="select"
      name="state"
      label={t('address.state.label')}
      placeholder="Quebec"
      options={states}
      {...props}
    />
  )
}

export default StateSelect
