import React from 'react'
import countries from 'data/countries.json'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'

function CountrySelect({ defaultValue, ...otherProps }) {
  const { t } = useTranslation('common')

  return (
    <Input
      type="select"
      name="country"
      label={t('address.country.label')}
      placeholder="Canada"
      options={countries}
      defaultValue={defaultValue}
      {...otherProps}
    />
  )
}

export default CountrySelect
