import { useTheme } from 'components/hooks'
import { useMemo } from 'react'
import {
  CREATED,
  IN_PROGRESS,
  RESOLVED,
  CANCELLED,
  ACCEPTED,
  ACTIVE,
  FINALIZED,
  SCHEDULED,
  ON_RENT,
  OFF_RENT,
  RE_OPENED,
} from 'constants/status'
import { SERVICE_REQUEST, ORDER_ITEM, ORDER } from 'constants/resources'


export default function useStatusColors(resource) {
  const [theme] = useTheme()
  const status = useMemo(() => {
    const colors = {}
    colors[SERVICE_REQUEST] = {}
    colors[ORDER_ITEM] = {}
    colors[ORDER] = {}

    colors[SERVICE_REQUEST][CREATED] = theme.color.red
    colors[SERVICE_REQUEST][IN_PROGRESS] = theme.color.accent600
    colors[SERVICE_REQUEST][RESOLVED] = theme.color.green
    colors[SERVICE_REQUEST][CANCELLED] = theme.color.accent100
    colors[SERVICE_REQUEST][RE_OPENED] = theme.color.purple

    colors[ORDER_ITEM][CREATED] = theme.color.purple
    colors[ORDER_ITEM][ACCEPTED] = theme.color.blue
    colors[ORDER_ITEM][SCHEDULED] = theme.color.accent600
    colors[ORDER_ITEM][ON_RENT] = theme.color.secondary100
    colors[ORDER_ITEM][OFF_RENT] = theme.color.red
    colors[ORDER_ITEM][FINALIZED] = theme.color.green
    colors[ORDER_ITEM][CANCELLED] = theme.color.accent100

    colors[ORDER][CREATED] = theme.color.purple
    colors[ORDER][ACTIVE] = theme.color.secondary100
    colors[ORDER][FINALIZED] = theme.color.green
    colors[ORDER][CANCELLED] = theme.color.accent100
    return colors
  }, [theme.color])

  return status[resource]
}
