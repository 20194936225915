import React from 'react'
import {
  Chip, Date, TableRow, PreviewLink, TableCell, AddressLink, FacetFilter,
} from 'components/common'
import {
  COMPANY, ORDER, EQUIPMENT, USER,
} from 'constants/resources'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import { ACTIVE } from 'constants/status'

function OrderRow({
  internalCode,
  status,
  companyName,
  companyId,
  street,
  city,
  zipCode,
  state,
  country,
  startDate,
  billingDate,
  active,
  shrink,
  onClick,
  templateColumns,
  statusColor,
  equipment,
  salesRepId,
  salesRepName,
}) {
  const { t } = useTranslation(['common', ORDER])
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell md>
        <p>{internalCode}</p>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'status',
            value: status,
            label: t(`order:status.${status}`),
          }}
          resource={ORDER}
        >
          <Chip
            background={statusColor}
            secondary={active && !status === ACTIVE}
            label={t(`order:status.${status}`)}
          />
        </FacetFilter>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'serialized_company.id',
            value: companyId,
            label: companyName,
          }}
          resource={ORDER}
        >
          <PreviewLink
            hideMobile
            resource={COMPANY}
            id={companyId}
          >
            {companyName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell sm lg={!shrink}>
        <AddressLink
          city={city}
          zipCode={zipCode}
          state={state}
          country={country}
        >
          {street}
        </AddressLink>
      </TableCell>
      <TableCell lg={!shrink}>
        {
          equipment.map(machine => (
            <>
              <PreviewLink
                hideMobile
                resource={EQUIPMENT}
                id={machine.get('id')}
              >
                {machine.get('name')}
              </PreviewLink>
              <span>&nbsp;&nbsp;</span>
            </>
          ))
        }
      </TableCell>
      <TableCell md>
        <Date>{startDate}</Date>
      </TableCell>
      <TableCell md>
        <Date>{billingDate}</Date>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_sales_rep.id',
            value: salesRepId,
            label: salesRepName,
          }}
          resource={ORDER}
        >
          <PreviewLink
            hideMobile
            resource={USER}
            id={salesRepId}
          >
            {salesRepName}
          </PreviewLink>
        </FacetFilter>

      </TableCell>
    </TableRow>
  )
}

OrderRow.propTypes = {
  internalCode: PropTypes.string,
  salesRepId: PropTypes.string,
  salesRepName: PropTypes.string,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  startDate: PropTypes.string,
  billingDate: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  equipment: PropTypes.instanceOf(List),
  templateColumns: PropTypes.string.isRequired,
}

OrderRow.defaultProps = {
  internalCode: '',
  salesRepId: '',
  salesRepName: '',
  status: '',
  statusColor: '',
  companyName: '',
  companyId: '',
  street: '',
  city: '',
  zipCode: '',
  state: '',
  country: '',
  startDate: '',
  billingDate: '',
  active: false,
  shrink: false,
  onClick: null,
  equipment: new List(),
}

export default OrderRow
