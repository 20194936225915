import { ThemeProvider } from 'styled-components'
import themes from 'config/themes'
import { useSelector } from 'react-redux'
import { createMuiTheme, ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles'
import React, { useMemo } from 'react'

function Theme({ children }) {
  const theme = useSelector(state => state.getIn(['application', 'theme']))
  const materialTheme = useMemo(() => createMuiTheme({
    palette: {
      primary: {
        light: themes[theme].color.primary200,
        main: themes[theme].color.primary,
        dark: themes[theme].color.primary600,
      },
      secondary: {
        light: themes[theme].color.secondary200,
        main: themes[theme].color.secondary,
        dark: themes[theme].color.secondary600,
      },
      accent: {
        light: themes[theme].color.accent200,
        main: themes[theme].color.accent,
        dark: themes[theme].color.accent600,
      },
      background: {
        default: themes[theme].color.primary,
        paper: themes[theme].color.primary100,
      },
      text: {
        primary: themes[theme].color.secondary,
        secondary: themes[theme].color.secondary300,
        disabled: themes[theme].color.secondary400,
      },
    },
  }), [theme])
  return (
    <ThemeProvider theme={themes[theme]}>
      <MaterialThemeProvider theme={materialTheme}>
        {children}
      </MaterialThemeProvider>
    </ThemeProvider>
  )
}

export default Theme
