import React from 'react'
import { Detail, SectionHeader } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function Information({
  action,
  title,
  name,
  nameTranslation,
  loading,
}) {
  const { t } = useTranslation('common')

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={name}
          loading={loading}
          label={t('shared.englishTranslation')}
        >
          {name}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={nameTranslation}
          loading={loading}
          label={t('shared.frenchTranslation')}
        >
          {nameTranslation}
        </Detail>
      </Grid>
    </Grid>
  )
}


Information.propTypes = {
  action: PropTypes.node,
  name: PropTypes.string,
  nameTranslation: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
}

Information.defaultProps = {
  action: null,
  name: '',
  nameTranslation: '',
  title: '',
  loading: false,
}


export default Information
