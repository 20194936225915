import { useDispatch } from 'react-redux'
import actions, { application } from 'store/action-creators'
import { NEW, SHOW } from 'constants/crud'
import { useCallback } from 'react'

function useQuickPreview() {
  const dispatch = useDispatch()

  const openPreview = useCallback(
    (resource, action) => {
      dispatch(application.setOverlayDisplay(true))
      dispatch(application.setOverlayAction(action))
      dispatch(application.setOverlayResource(resource))
    },
    [dispatch],
  )

  const closePreview = useCallback(
    (resource, action) => {
      if (resource) {
        switch (action) {
          case SHOW:
            dispatch(actions[resource].setCurrentId(''))
            dispatch(actions[resource].clearCurrentData())
            break
          case NEW:
            dispatch(actions[resource].clearCreateData())
            break
          default:
            break
        }
      }
      dispatch(application.setOverlayDisplay(false))
      dispatch(application.setOverlayResource(''))
      dispatch(application.setOverlayAction(''))
    },
    [dispatch],
  )

  return [openPreview, closePreview]
}

export default useQuickPreview
