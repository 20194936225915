import styled from 'styled-components'

const ButtonStyled = styled.button`
  background-color: ${({ secondary, theme }) => (secondary ? 'transparent' : theme.color.secondary)};
  border-color:  ${({
    theme, secondary,
  }) => (secondary ? theme.color.secondary : theme.color.primary)};
  border-width: 1px;
  border-style: solid;
  outline: none;
  color: ${({
    theme, secondary,
  }) => (secondary ? theme.color.secondary : theme.color.primary)};
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  font-size: ${({ theme }) => theme.font.body.size}px;
  font-weight:  500;
  padding: 14px 37px;
  text-align: center;
  width: ${({ fullSize }) => fullSize && '100%'};
  box-sizing: border-box;
  box-shadow: 0 0 0 2px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
   box-shadow: 0 0 0 2px ${({ theme }) => `${theme.color.secondary}1A`};
  } 
  &:active {
    transform: scale(0.95);
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.secondary}CC;
  }
`

export default ButtonStyled
