export const COMPANY = 'company'
export const CATEGORY = 'category'
export const EQUIPMENT = 'equipment'
export const NOTIFICATION = 'notification'
export const ORDER_ITEM = 'orderItem'
export const ORDER_SERVICE = 'orderService'
export const ORDER = 'order'
export const RESERVATION = 'reservation'
export const RESERVATION_ITEM = 'reservationItem'
export const SERVICE_REQUEST = 'serviceRequest'
export const SUPPLIER = 'supplier'
export const SUPPLIER_REVIEW = 'supplierReview'
export const USER = 'user'
