import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/categories/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/categories/${id}/`)
)

export const create = async ({
  name,
  translations,
  parent,
}) => (
  api.post('/app/categories/', {
    name,
    translations,
    parent,
  })
)

export const update = async (id, {
  name,
  translations,
  parent,
}) => (
  api.patch(`/app/categories/${id}/`, {
    name,
    translations,
    parent,
  })
)

export const destroy = async id => (
  api.delete(`/app/categories/${id}/`)
)
