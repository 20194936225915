import React from 'react'
import { Icon } from 'components/ui'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const IconButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.color.primary300};
  border-radius: ${({ theme }) => theme.radius}px;
  outline: none;
  border: none;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > span {
    color: ${({ theme }) => theme.color.secondary};
    font-size: 16px;
  }
`

export const Dot = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.accent};
  height: ${({ size }) => size / 2}px;
  width: ${({ size }) => size / 2}px;
  border-radius: ${({ size }) => size / 4}px;
  top: -2px;
  right: -2px;
`

function IconButton({
  icon, dot, size, ...otherProps
}) {
  return (
    <IconButtonStyled size={size} {...otherProps}>
      <Icon name={icon} size={size * 0.6} />
      {
        dot ? <Dot size={size} /> : null
      }
    </IconButtonStyled>
  )
}

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  dot: PropTypes.bool,
  size: PropTypes.number,
}

IconButton.defaultProps = {
  dot: false,
  size: 24,
}

export default IconButton
