import React from 'react'
import initializeStore from 'store'
import { Provider } from 'react-redux'

const store = initializeStore()

function Store({ children }) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

export default Store
