import styled, { keyframes } from 'styled-components'
import Typed from 'typed.js'
import React, { useEffect, useState } from 'react'
import AuthForm from 'components/pages/login/AuthForm'
import { Overlay } from 'components/common'
import { useTranslation } from 'react-i18next'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledOverlay = styled(Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
  #welcome-text {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .popup {
    transition: 1s ease;
    animation: ${fadeIn} 1000ms linear;
  }
`

function Login() {
  const { t } = useTranslation('session')
  const [show, setShow] = useState(false)

  useEffect(() => {
    const typed = new Typed('#welcome-text', {
      strings: [t('login.message')],
      typeSpeed: 30,
      onComplete: () => {
        setTimeout(() => {
          setShow(true)
          typed.destroy()
        }, 500)
      },
    })
    return () => {
      typed.destroy()
    }
  }, [t])

  return (
    <StyledOverlay opacity={1} show center>
      <span id="welcome-text" />
      {
        show ? <AuthForm className="popup" /> : null
      }
    </StyledOverlay>
  )
}

export default Login
