import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SelectField from 'components/common/SelectField'
import TelField from 'components/common/TelField'
import DateField from 'components/common/DateField'
import TextField from 'components/ui/TextField'
import DateTimeField from 'components/common/DateTimeField'
import FileField from 'components/common/FileField'

const InputStyled = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }
`

function Input({
  type, error, helperText, ...otherProps
}) {
  let SelectedInput

  switch (type) {
    case 'file': SelectedInput = FileField; break
    case 'date': SelectedInput = DateField; break
    case 'datetime': SelectedInput = DateTimeField; break
    case 'text': SelectedInput = TextField; break
    case 'tel': SelectedInput = TelField; break
    case 'password': SelectedInput = TextField; break
    case 'select': SelectedInput = SelectField; break
    default: SelectedInput = TextField; break
  }

  return (
    <InputStyled>
      <SelectedInput
        type={type}
        error={!!error}
        helperText={error || helperText}
        {...otherProps}
      />
    </InputStyled>
  )
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  helperText: PropTypes.string,
}

Input.defaultProps = {
  error: '',
  helperText: '',
}

export default Input
