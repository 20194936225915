import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'components/ui'
import {
  Chip, Flex, Rating, SectionHeader,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { supplierReviews } from 'store/action-creators'
import { useQuickPreview } from 'components/hooks'
import { SUPPLIER_REVIEW } from 'constants/resources'
import { NEW } from 'constants/crud'
import { useTranslation } from 'react-i18next'

function Reviews({
  supplierId,
  deliveries,
  serviceCalls,
  responseTime,
  pricing,
  loading,
}) {
  const { t } = useTranslation(SUPPLIER_REVIEW)
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleChipClick = () => {
    dispatch(supplierReviews.setCreateData({
      supplier: supplierId,
    }))
    openPreview(SUPPLIER_REVIEW, NEW)
  }

  return (
    <div>
      <SectionHeader title={t('shared.rating')} fullSize>
        <Chip
          icon="edit"
          outline
          onClick={handleChipClick}
          label={t('new.header')}
        />
      </SectionHeader>
      <Divider spacing={20} />
      <Flex align="center" justify="space-between">
        <p>{t('shared.response')}</p>
        <Rating loading={loading} score={responseTime} readOnly precision={0.5} />
      </Flex>
      <Divider spacing={20} />
      <Flex align="center" justify="space-between">
        <p>{t('shared.delivery')}</p>
        <Rating loading={loading} score={deliveries} readOnly precision={0.5} />
      </Flex>
      <Divider spacing={20} />
      <Flex align="center" justify="space-between">
        <p>{t('shared.service')}</p>
        <Rating loading={loading} score={serviceCalls} readOnly precision={0.5} />
      </Flex>
      <Divider spacing={20} />
      <Flex align="center" justify="space-between">
        <p>{t('shared.pricing')}</p>
        <Rating loading={loading} score={pricing} readOnly precision={0.5} />
      </Flex>
    </div>
  )
}

Reviews.propTypes = {
  supplierId: PropTypes.string.isRequired,
  serviceCalls: PropTypes.number.isRequired,
  responseTime: PropTypes.number.isRequired,
  deliveries: PropTypes.number.isRequired,
  pricing: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

Reviews.defaultProps = {
  loading: false,
}


export default Reviews
