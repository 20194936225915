import React from 'react'
import { TableControls, AlgoliaSearch, ActiveFilters } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'
import Hidden from '@material-ui/core/Hidden'
import Filters from './Filters'

function RequestsControls(props) {
  const dispatch = useDispatch()
  const handleSearchChange = (value) => {
    dispatch(serviceRequests.setListSearchQuery(value))
  }
  return (
    <TableControls
      {...props}
    >
      <Hidden xsDown>
        <ActiveFilters resource={SERVICE_REQUEST} />
        <Filters />
      </Hidden>
      <AlgoliaSearch onChange={handleSearchChange} />
    </TableControls>
  )
}

export default RequestsControls
