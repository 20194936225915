import notify from 'utils/notify'

const handleSuccess = (response) => {
  if ([200, 201].includes(response.status) && response.data) {
    return response.data || {}
  }
  return response
}

const handleError = (error) => {
  if (error?.response?.data?.detail) {
    notify({
      text: error.response.data.detail,
      type: 'error',
    })
  }
  Promise.reject(error)
}

const setInterceptors = (instance) => {
  instance.interceptors.response.use(handleSuccess, handleError)
}

export default setInterceptors
