import React from 'react'
import { DetailHeader, Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useQuickPreview } from 'components/hooks'
import { NEW } from 'constants/crud'
import { useDispatch } from 'react-redux'
import { users } from 'store/action-creators'
import { USER, COMPANY } from 'constants/resources'
import { useTranslation } from 'react-i18next'

function Header({ title, loading, companyId }) {
  const { t } = useTranslation(COMPANY)
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleChipClick = () => {
    dispatch(users.setCreateData({
      company: { id: companyId },
    }))
    openPreview(USER, NEW)
  }
  return (
    <DetailHeader title={title} loading={loading}>
      <Chip
        icon="add"
        outline
        onClick={handleChipClick}
        label={t('company:employeeList.newUser')}
      />
    </DetailHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  companyId: PropTypes.string,
  loading: PropTypes.bool,
}

Header.defaultProps = {
  title: '',
  companyId: '',
  loading: false,
}

export default Header
