import React from 'react'
import { Link } from 'components/ui'
import { useDispatch } from 'react-redux'
import actions from 'store/action-creators'
import { useQuickPreview } from 'components/hooks'
import { SHOW } from 'constants/crud'
import PropTypes from 'prop-types'

function PreviewLink({
  resource, id, children, hideMobile,
}) {
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()
  const isMobile = window.innerWidth < 720
  if (!children) return null
  if (!id) return children

  const handleClick = (event) => {
    event.stopPropagation()
    dispatch(actions[resource].get(id))
    openPreview(resource, SHOW)
  }
  return (hideMobile && isMobile) ? <span>{children}</span> : <Link onClick={handleClick}>{children}</Link>
}

PreviewLink.propTypes = {
  resource: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hideMobile: PropTypes.bool,
}

PreviewLink.defaultProps = {
  hideMobile: false,
}

export default PreviewLink
