import React from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Icon } from 'components/ui'
import IconButton from '@material-ui/core/IconButton'

function SearchField({ value, onClear, ...otherProps }) {
  const { t } = useTranslation('common')
  const handleClearClick = () => {
    if (onClear) onClear()
  }
  return (
    <Input
      {...otherProps}
      id="search-box"
      value={value}
      type="search"
      shrink={false}
      label={(
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="search" size={18} />
          &nbsp;
          &nbsp;
          <span>{t('actions.search')}</span>
        </p>
      )}
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClearClick}
              edge="end"
            >
              <Icon name="clear" size={16} />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  )
}

export default SearchField
