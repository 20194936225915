import React from 'react'
import PropTypes from 'prop-types'
import {
  Label, TableRow, Detail, VoiceNote, PreviewLink, Date, TableCell,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import {
  EQUIPMENT, COMPANY, NOTIFICATION, USER,
} from 'constants/resources'
import { List } from 'immutable'


function Reservation({
  id,
  loading,
  companyName,
  companyId,
  contactName,
  contactId,
  contactEmail,
  contactNumber,
  postalCode,
  startDate,
  voiceNoteUrl,
  voiceNoteExpiration,
  items,
}) {
  const { t } = useTranslation(['common', NOTIFICATION])
  const templateColumns = '1fr 1fr'

  if (!id) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink
            resource={COMPANY}
            id={companyId}
          >
            {companyName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactName}
          loading={loading}
          label={t('common:shared.contactName')}
        >
          <PreviewLink
            resource={USER}
            id={contactId}
          >
            {contactName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactEmail}
          loading={loading}
          label={t('common:shared.email')}
        >
          {contactEmail}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.contactNumber')}
        >
          {contactNumber}
        </Detail>
      </Grid>

      <Grid item xs={12} md={6}>
        <Detail
          text={startDate}
          loading={loading}
          label={t('common:shared.startDate')}
        >
          <Date>{startDate}</Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={postalCode}
          loading={loading}
          label={t('common:address.zipcode.label')}
        >
          {postalCode}
        </Detail>
      </Grid>
      {
        voiceNoteUrl ? (
          <Grid item xs={12} md={6}>
            <Detail
              loading={loading}
              label={t('common:shared.voiceNote')}
            >
              <VoiceNote
                src={voiceNoteUrl}
                expiration={voiceNoteExpiration}
              >
                {t('common:shared.voiceNote')}
              </VoiceNote>
            </Detail>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <TableRow
              fullSize
              templateColumns={templateColumns}
            >
              <Label>{t('common:resources.equipment.singular')}</Label>
              <Label>{t('common:shared.quantity')}</Label>
            </TableRow>
            {
              (items && items.size) ? items.map(item => (
                <TableRow
                  key={item.get('id')}
                  fullSize
                  templateColumns={templateColumns}
                >
                  <TableCell sm>
                    <PreviewLink resource={EQUIPMENT} id={item.getIn(['equipment', 'id'])}>
                      {item.getIn(['equipment', 'name'])}
                    </PreviewLink>
                  </TableCell>
                  <TableCell sm>
                    <span>
                      {item.get('quantity')}
                    </span>
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow
                  fullSize
                >
                  {t(`${NOTIFICATION}:detail.allCancelled`)}
                </TableRow>)
          }
          </Grid>
        )
      }
    </Grid>
  )
}


Reservation.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  contactName: PropTypes.string,
  contactId: PropTypes.string,
  postalCode: PropTypes.string,
  startDate: PropTypes.string,
  contactEmail: PropTypes.string,
  contactNumber: PropTypes.string,
  voiceNoteUrl: PropTypes.string,
  voiceNoteExpiration: PropTypes.string,
  items: PropTypes.instanceOf(List),
}

Reservation.defaultProps = {
  id: '',
  loading: false,
  companyName: '',
  companyId: '',
  contactName: '',
  contactId: '',
  postalCode: '',
  startDate: '',
  contactEmail: '',
  contactNumber: '',
  voiceNoteUrl: '',
  voiceNoteExpiration: '',
  items: new List(),
}


export default Reservation
