import React from 'react'
import { Card } from 'components/ui'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import actions from 'store/action-creators'
import { useTableHotkeys } from 'components/hooks'

const TableGrid = styled(Card)`
  padding: 0;
  display: flex;
  flex-direction: column;
`

const Headline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  height: 90px;
  > div {
    flex-grow: 1;
    display: flex;
    > h1 {
      margin-right: 0.75em;
    } 
  }
  .subtitle a {
    text-decoration: none;
    h1 {
      opacity: 0.4 !important
    }
  }
`

function Table({
  title,
  resource,
  controls,
  header,
  content,
  subtitle,
}) {
  useTableHotkeys(resource)
  const dispatch = useDispatch()
  const closeSidebar = () => {
    dispatch(actions[resource].setCurrentId(''))
    dispatch(actions[resource].clearCurrentData())
    dispatch(actions.navigation.removeQuery('id'))
  }
  return (
    <TableGrid>
      <Headline onClick={closeSidebar}>
        <div>
          <h1>{title}</h1>
          <div className="desktop-only subtitle">
            {subtitle}
          </div>
        </div>
        {controls}
      </Headline>
      {header}
      {content}
    </TableGrid>
  )
}

Table.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  resource: PropTypes.string.isRequired,
  controls: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
}

export default Table
