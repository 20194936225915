import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from 'components/common'
import { useTranslation } from 'react-i18next'

function AddressLink({
  state, country, street, city, zipCode,
}) {
  const { t } = useTranslation('common')
  if (!state || !street || !city || !zipCode) return null

  const handleChipClick = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${street} ${city} ${zipCode} ${state} ${country}`
    window.open(url, '_blank')
  }

  return (
    <Chip
      className="address-button"
      outline
      icon="directions"
      onClick={handleChipClick}
      label={t('shared.openMap')}
    />
  )
}

AddressLink.propTypes = {
  state: PropTypes.string,
  country: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
}

AddressLink.defaultProps = {
  state: '',
  country: '',
  street: '',
  zipCode: '',
  city: '',
}

export default AddressLink
