import React from 'react'
import { Chip } from 'components/common'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useQuickPreview } from 'components/hooks'
import { serviceRequests } from 'store/action-creators'
import { SERVICE_REQUEST, NOTIFICATION } from 'constants/resources'
import { EDIT } from 'constants/crud'
import { Map } from 'immutable'

function EditServiceButton({
  show,
  serviceRequest,
}) {
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()
  const { t } = useTranslation(NOTIFICATION)
  const canEdit = show
  if (!canEdit) return null

  const handleChipClick = () => {
    dispatch(serviceRequests.setCurrentId(serviceRequest.get('id')))
    dispatch(serviceRequests.setCurrentData(serviceRequest))
    openPreview(SERVICE_REQUEST, EDIT)
  }

  return (
    <Chip
      icon="edit"
      outline
      onClick={handleChipClick}
      label={t('shared.edit')}
    />
  )
}

EditServiceButton.propTypes = {
  serviceRequest: PropTypes.instanceOf(Map),
  show: PropTypes.bool,
}

EditServiceButton.defaultProps = {
  serviceRequest: new Map(),
  show: false,
}
export default EditServiceButton
