import React from 'react'
import { Chip } from 'components/common'
import { useDispatch } from 'react-redux'
import { reservations } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { NOTIFICATION, ORDER } from 'constants/resources'
import { useQuickPreview } from 'components/hooks'
import { NEW } from 'constants/crud'

function NewOrderButton({
  show,
  isCompany,
  isDate,
  reservationId,
  acknowledge,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(NOTIFICATION)
  const [openPreview] = useQuickPreview()
  if (!show) return null

  const handleNewOrderClick = () => {
    if (isCompany && isDate && reservationId) {
      dispatch(reservations.createOrder(
        reservationId,
        acknowledge,
      ))
    } else {
      openPreview(ORDER, NEW)
    }
  }

  return (
    <Chip
      icon="add"
      outline
      onClick={handleNewOrderClick}
      label={t('detail.newOrder')}
    />
  )
}

NewOrderButton.propTypes = {
  reservationId: PropTypes.string,
  isCompany: PropTypes.bool,
  acknowledge: PropTypes.func.isRequired,
  isDate: PropTypes.bool,
  show: PropTypes.bool,
}

NewOrderButton.defaultProps = {
  reservationId: '',
  isCompany: false,
  isDate: false,
  show: false,
}
export default NewOrderButton
