import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { SERVICE_REQUEST } from 'constants/resources'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'
import { CANCELLED, RESOLVED } from 'constants/status'

function RemindButton({
  id,
  status,
}) {
  const { t } = useTranslation(SERVICE_REQUEST)
  const dispatch = useDispatch()

  const handleChipClick = () => {
    dispatch(serviceRequests.ping(id))
  }

  return ![CANCELLED, RESOLVED].includes(status) ? (
    <Chip
      outline
      icon="touch_app"
      onClick={handleChipClick}
      label={t('shared.askUpdate')}
    />
  ) : null
}

RemindButton.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
}

RemindButton.defaultProps = {
  status: '',
  id: '',
}


export default RemindButton
