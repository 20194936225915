import {
  takeLatest,
} from 'redux-saga/effects'
import { SUPPLIER_REVIEW } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  list, get, create, update, destroy,
} from './factory'


export default function* supplierReviewSaga() {
  yield takeLatest(LIST(SUPPLIER_REVIEW), list)
  yield takeLatest(GET(SUPPLIER_REVIEW), get)
  yield takeLatest(CREATE(SUPPLIER_REVIEW), create)
  yield takeLatest(UPDATE(SUPPLIER_REVIEW), update)
  yield takeLatest(DELETE(SUPPLIER_REVIEW), destroy)
}
