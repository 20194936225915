import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Detail, PreviewLink, SectionHeader } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { COMPANY, USER } from 'constants/resources'

function Information({
  action,
  title,
  name,
  loading,
  salesRepName,
  salesRepId,
}) {
  const { t } = useTranslation(['common', COMPANY])
  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={12}>
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('shared.salesRep')}
          empty={t('company:shared.emptySalesRep')}
        >
          <PreviewLink
            resource={USER}
            id={salesRepId}
          >
            <span>
              {salesRepName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  name: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
}

Information.defaultProps = {
  action: null,
  name: '',
  salesRepName: '',
  salesRepId: '',
  title: '',
  loading: false,
}

export default Information
