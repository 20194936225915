import React from 'react'
import {
  TableRow, TableCell, Flex, Chip,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function CategoryRow({
  id,
  name,
  translation,
  subcategory,
  active,
  onClick,
  templateColumns,
  shrink,
}) {
  const { t } = useTranslation('common')

  return (
    <TableRow
      templateColumns={templateColumns}
      active={active}
      onClick={onClick}
      data-test-id={id}
      className="row-category"
    >
      <TableCell sm>
        <p>{name}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <p>{translation}</p>
      </TableCell>
      <TableCell sm>
        <Flex justify="flex-end">
          {
          !subcategory ? (
            <Chip
              secondary={active}
              label={t('common:resources.categories.singular')}
            />
          ) : (
            <Chip
              secondary={active}
              outline
              label={t('common:resources.subcategories.singular')}
            />
          )
          }
        </Flex>
      </TableCell>
    </TableRow>
  )
}

CategoryRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  translation: PropTypes.string,
  subcategory: PropTypes.bool,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
}

CategoryRow.defaultProps = {
  id: '',
  name: '',
  translation: '',
  subcategory: false,
  active: false,
  shrink: false,
  onClick: null,
}
export default CategoryRow
