import React from 'react'
import {
  TableRow, TableCell, Rating, FacetFilter,
} from 'components/common'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { capitalize } from 'utils'
import { SUPPLIER } from 'constants/resources'

function SupplierRow({
  name,
  categories,
  state,
  city,
  rating,
  totalOrders,
  active,
  onClick,
  templateColumns,
  shrink,
}) {
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell sm>
        <p>{name}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <p>{categories.map(w => capitalize(w)).join(', ')}</p>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'address_state',
            value: state,
            label: state,
          }}
          resource={SUPPLIER}
        >
          <p>{state}</p>
        </FacetFilter>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'address_city',
            value: city,
            label: city,
          }}
          resource={SUPPLIER}
        >
          <p>{city}</p>
        </FacetFilter>
      </TableCell>
      <TableCell sm>
        <p>{totalOrders}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <Rating score={rating} readOnly precision={0.5} />
      </TableCell>
    </TableRow>
  )
}

SupplierRow.propTypes = {
  name: PropTypes.string,
  categories: PropTypes.instanceOf(List),
  state: PropTypes.string,
  city: PropTypes.string,
  rating: PropTypes.number,
  totalOrders: PropTypes.number,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
}

SupplierRow.defaultProps = {
  name: '',
  categories: new List(),
  state: '',
  city: '',
  rating: 0,
  totalOrders: 0,
  active: false,
  shrink: false,
  onClick: null,
}

export default SupplierRow
