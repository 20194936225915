import React, { useMemo } from 'react'
import {
  InlineTable, TableRow, PreviewLink,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { list as listReviews } from 'services/supplier-reviews'
import { NOTIFICATION, USER } from 'constants/resources'
import { usePaginatedList } from 'components/hooks'
import { Grid } from '@material-ui/core'

function ReviewNotes({ supplierId }) {
  const { t } = useTranslation([NOTIFICATION, 'common', 'orderItem'])
  const templateColumns = '1fr 2fr'
  const options = useMemo(() => (supplierId ? ({
    supplier: supplierId,
    has_notes: true,
    limit: 5,
  }) : null), [supplierId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listReviews, options)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('common:resources.reviews.plural')} (${count})`}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(item => (
            <TableRow
              key={item.get('id')}
              fullSize
              templateColumns={templateColumns}
            >
              <PreviewLink
                resource={USER}
                id={item.getIn(['created_by', 'id'])}
              >
                {item.getIn(['created_by', 'name'])}
              </PreviewLink>

              <p>{item.get('notes')}</p>
            </TableRow>
          ))
        }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

ReviewNotes.propTypes = {
  supplierId: PropTypes.string,
}

ReviewNotes.defaultProps = {
  supplierId: '',
}

export default ReviewNotes
