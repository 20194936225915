import React from 'react'
import styled from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'

const NavLinkStyled = styled(RouterNavLink)`
  font-size: ${({ theme }) => theme.font.header.size}px;
  color: ${({ theme }) => theme.color.secondary};
  text-decoration: none;
  position: relative;
  cursor: pointer;
  line-height: 1.5;
  outline: none;
  font-weight: 500;
  &::after{
    position: absolute;
    opacity: 0;
    content: '';
    background-color: ${({ theme }) => theme.color.secondary};
    height: 0.1em;
    bottom: 0; 
    margin: 0 auto;
    left: 0;
    right: 0;
    transition: 200ms;
  } 

  &.active-link::after{
    opacity: 1;
  } 
`

function NavLink(props) {
  return <NavLinkStyled activeClassName="active-link" {...props} />
}

export default NavLink
