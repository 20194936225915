import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { Divider } from 'components/ui'
import { AddressSection, Destroy } from 'components/common'
import PurchasePriceInformation from './PurchasePriceInformation'
import UpdatesHistory from './UpdatesHistory'

function PurchasePriceDetail({ selected, loading }) {
  return (
    <>
      <PurchasePriceInformation
        code={selected.get('code')}
        internalCode={selected.get('internal_code')}
        assigneeName={selected.getIn(['assignee', 'name'])}
        assigneeId={selected.getIn(['assignee', 'id'])}
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        createdByName={selected.getIn(['created_by', 'name'])}
        createdById={selected.getIn(['created_by', 'id'])}
        notes={selected.get('notes')}
        createdAt={selected.get('created')}
        status={selected.get('status')}
        designation={selected.get('designation')}
        budget={selected.getIn(['metadata', 'budget'])}
        equipmentYear={selected.getIn(['metadata', 'equipment_year'])}
        equipmentState={selected.getIn(['metadata', 'equipment_state'])}
        equipmentModel={selected.getIn(['metadata', 'equipment_model'])}
        loading={loading}
        // FIXME:
        equipmentName={selected.getIn(['metadata', 'equipment'])}
        equipmentId={selected.getIn(['metadata', 'equipment', 'id'])}
        companyName={selected.getIn(['metadata', 'company'])}
        companyId={selected.getIn(['metadata', 'company', 'id'])}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <UpdatesHistory
        stateHistory={selected.get('state_history')}
        statusHistory={selected.get('status_history')}
        loading={loading}
      />
      <Destroy resource={SERVICE_REQUEST} id={selected.get('id')} />
    </>
  )
}


export default PurchasePriceDetail
