import React, { useState, useEffect } from 'react'
import MaterialMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import { Chip } from 'components/common'

function Menu({
  value, options, onChange, disabled, ...otherProps
}) {
  const [anchor, setAnchor] = useState(null)
  const [selected, setSelected] = useState()
  useEffect(() => {
    setSelected(options.find(opt => opt.value === value))
  }, [options, value])

  const handleOpen = (event) => {
    event.stopPropagation()
    if (!disabled) setAnchor(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchor(null)
  }

  const handleChange = val => (event) => {
    if (onChange) onChange(val)
    handleClose(event)
  }


  if (!value) return null

  return (
    <div>
      {
        selected ? (
          <Chip
            {...otherProps}
            background={selected.color}
            onClick={handleOpen}
            label={selected.label}
          />
        )
          : null
      }
      <MaterialMenu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={handleClose}
      >
        {
          options.map(option => (
            <MenuItem
              key={option.value}
              onClick={handleChange(option.value)}
            >
              <Chip
                background={option.color}
                label={option.label}
              />
            </MenuItem>
          ))
        }
      </MaterialMenu>
    </div>
  )
}

Menu.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
}

Menu.defaultProps = {
  disabled: false,
  onChange: null,
}

export default Menu
