import React, { useState, useEffect } from 'react'
import { Chip } from 'components/common'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { equipment } from 'store/action-creators'
import { EQUIPMENT } from 'constants/resources'

function PromoteButton({ value }) {
  const dispatch = useDispatch()
  const { t } = useTranslation(EQUIPMENT)
  const [isPromoted, setIsPromoted] = useState(value)
  const chipLabel = isPromoted ? t('shared.promoted') : t('shared.unpromoted')

  useEffect(() => {
    setIsPromoted(value)
  }, [value])

  const handleSuccessPromotion = (response) => {
    setIsPromoted(response.is_promoted)
  }

  const handleChipClick = () => {
    setIsPromoted(!isPromoted)
    dispatch(equipment.promote(
      !isPromoted,
      handleSuccessPromotion,
    ))
  }

  return (
    <Chip
      outline={!isPromoted}
      onClick={handleChipClick}
      label={chipLabel}
    />
  )
}

PromoteButton.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool,
}

PromoteButton.defaultProps = {
  id: '',
  value: false,
}
export default PromoteButton
