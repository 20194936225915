import {
  SET_SESSION_TOKEN,
  SET_CURRENT_SESSION,
  SET_ALGOLIA_TOKEN,
} from 'store/actions/sessions'
import { fromJS } from 'immutable'

export const initialState = fromJS({
  token: null,
  algoliaToken: null,
  user: {},
})

const sessions = (state = initialState, { type, payload = {} }) => {
  const { body } = payload
  switch (type) {
    case SET_SESSION_TOKEN:
      return state.set('token', body)
    case SET_ALGOLIA_TOKEN:
      return state.set('algoliaToken', body)
    case SET_CURRENT_SESSION:
      return state.set('user', body)
    default: return state
  }
}

export default sessions
