import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { CANCELLED, FINALIZED } from 'constants/status'
import BatchOffRentForm from './BatchOffRentForm'
import SingleOffRentForm from './SingleOffRentForm'

function Form({ current, loading, update }) {
  if (!update && current.get('multiple') && current.getIn(['order', 'items']) && current.getIn(['order', 'items']).size) {
    return (
      <BatchOffRentForm
        items={current.getIn(['order', 'items']).filter(item => ![CANCELLED, FINALIZED].includes(item.get('status')))}
        current={current}
        loading={loading}
      />
    )
  }

  return (
    <SingleOffRentForm
      current={current}
      loading={loading}
      update={update}
    />
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
