import React, { useState } from 'react'
import { Switch } from 'components/common'
import { useNotifications } from 'components/hooks'


function NotificationSwitch() {
  const [checked, setChecked] = useState(false)
  const [getUnread, getResolved] = useNotifications()

  const handleSwitch = () => {
    if (checked) {
      getUnread()
    } else {
      getResolved()
    }
    setChecked(!checked)
  }

  return (
    <Switch
      checked={checked}
      onChange={handleSwitch}
      size="medium"
    />
  )
}

export default NotificationSwitch
