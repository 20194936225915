import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { sessions } from 'store/action-creators'
import api from 'config/axios'

function PrivateRoute({ children, ...otherProps }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.getIn(['session', 'token']))
  const algoliaToken = useSelector(state => state.getIn(['session', 'algoliaToken']))
  const storedToken = localStorage.getItem('jwt')
  const storedTokenExp = localStorage.getItem('exp')
  const storedAlgoliaToken = localStorage.getItem('algolia')

  if ((token || storedToken) && new Date().getTime() < storedTokenExp) {
    api.defaults.headers.common.Authorization = `Bearer ${token || storedToken}`
  }
  if (!token && storedToken) {
    dispatch(sessions.setToken(storedToken))
    dispatch(sessions.getCurrent())
  }
  if (!algoliaToken && storedAlgoliaToken) {
    dispatch(sessions.setAlgoliaToken(storedAlgoliaToken))
  }

  return (
    <Route
      {...otherProps}
      render={({ location }) => ((token || storedToken) ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))
      }
    />
  )
}

export default PrivateRoute
