import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { Divider } from 'components/ui'
import {
  AddressSection, ContactSection, Destroy,
} from 'components/common'
import OrderInformation from './OrderInformation'
import UpdatesHistory from './UpdatesHistory'

function OrderDetail({ selected, loading }) {
  return (
    <>
      <OrderInformation
        code={selected.get('code')}
        internalCode={selected.get('internal_code')}
        assigneeName={selected.getIn(['assignee', 'name'])}
        assigneeId={selected.getIn(['assignee', 'id'])}
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        createdByName={selected.getIn(['created_by', 'name'])}
        createdById={selected.getIn(['created_by', 'id'])}
        notes={selected.get('notes')}
        createdAt={selected.get('created')}
        resolveBy={selected.get('resolve_by')}
        status={selected.get('status')}
        designation={selected.get('designation')}
        loading={loading}
        duration={selected.getIn(['metadata', 'duration'])}
        deliveryDate={selected.getIn(['metadata', 'delivery_date'])}
        purchaseOrder={selected.getIn(['metadata', 'purchase_order'])}
        givenPrice={selected.getIn(['metadata', 'price_given'])}
        transportGivenPrice={selected.getIn(['metadata', 'transport_price_given'])}
        currentSupplier={selected.getIn(['metadata', 'current_supplier'])}
        // FIXME:
        companyName={selected.getIn(['metadata', 'company'])}
        companyId={selected.getIn(['metadata', 'company', 'id'])}
        equipmentName={selected.getIn(['metadata', 'equipment'])}
        equipmentId={selected.getIn(['metadata', 'equipment', 'id'])}
      />
      <Divider spacing={20} />
      <ContactSection
        list={selected.getIn(['metadata', 'contacts'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <UpdatesHistory
        stateHistory={selected.get('state_history')}
        statusHistory={selected.get('status_history')}
        loading={loading}
      />
      <Destroy resource={SERVICE_REQUEST} id={selected.get('id')} />
    </>
  )
}


export default OrderDetail
