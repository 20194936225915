import React, { useState } from 'react'
import { Divider } from 'components/ui'
import { useTheme } from 'components/hooks'
import { Input, Logo, Button } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { EMAIL } from 'constants/patterns'
import { Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { changePassword } from 'services/sessions'


function AuthForm() {
  const [success, setSuccess] = useState(false)
  const location = useLocation()
  const { t } = useTranslation(['session', 'error'])
  const [theme] = useTheme()
  const {
    handleSubmit, errors, register, watch,
  } = useForm({ mode: 'onBlur' })
  const token = new URLSearchParams(location.search).get('token')

  const onSubmit = (data) => {
    changePassword({
      email: data.email,
      password: data.password,
      token,
    }).then((response) => {
      if (response) setSuccess(true)
    })
  }


  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={4} md={3}>
        <Grid container justify="center">
          <Logo height={30} color={theme.color.secondary} />
        </Grid>
        <Divider spacing={20} />
        {
          success ? <h3>{t('session:reset.success')}</h3> : null
        }
        {
          token && !success ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="email"
                forwardRef={register({
                  required: {
                    value: true,
                    message: t('error:email.required'),
                  },
                  pattern: {
                    value: EMAIL,
                    message: t('error:email.pattern'),
                  },
                })}
                error={errors.email?.message}
                label={t('session:login.email')}
                placeholder="you@rentallconstruction.com"
                type="email"
              />
              <Divider spacing={20} />
              <Input
                name="password"
                forwardRef={register({
                  required: {
                    value: true,
                    message: t('error:shared.required'),
                  },
                })}
                error={errors.password?.message}
                label={t('session:login.password')}
                placeholder="*********"
                type="password"
              />
              <Divider spacing={20} />
              <Input
                name="confirm"
                forwardRef={register({
                  required: {
                    value: true,
                    message: t('error:shared.required'),
                  },
                  validate: {
                    equality: value => value === watch('password') || t('session:reset.confirmError'),
                  },
                })}
                error={errors.confirm?.message}
                label={t('session:reset.confirm')}
                placeholder="*********"
                type="password"
              />
              <Divider spacing={20} />
              <Button fullSize>{t('session:reset.submit')}</Button>
            </form>
          ) : null
        }
        {
          !token && !success ? (
            <h3>{t('session:reset.empty')}</h3>
          ) : null
        }
      </Grid>
    </Grid>
  )
}

export default AuthForm
