import {
  takeLatest,
  call,
  select,
} from 'redux-saga/effects'
import { SERVICE_REQUEST } from 'constants/resources'
import {
  LIST,
  LIST_RESET,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import { SERVICE_REQUEST_PING } from 'store/actions/serviceRequests'
import { notify } from 'utils'
import i18n from 'config/i18n'
import {
  ping as pingService,
} from 'services/service-requests'
import {
  list, resetList, get, create, update, destroy,
} from './factory'
import nprogress from './nprogress'

function* pingServiceRequest({ payload }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  const name = yield select(state => state.getIn(['serviceRequest', 'current', 'data', 'assignee', 'name']))
  const response = yield call(pingService, id)
  if (response) {
    notify({
      text: name ? i18n.t('common:shared.successReminder', { name }) : i18n.t('common:shared.successReminderAnonymous'),
    })
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
}


export default function* serviceRequestSaga() {
  yield takeLatest(LIST_RESET(SERVICE_REQUEST), resetList)
  yield takeLatest(LIST(SERVICE_REQUEST), list)
  yield takeLatest(GET(SERVICE_REQUEST), get)
  yield takeLatest(CREATE(SERVICE_REQUEST), create)
  yield takeLatest(UPDATE(SERVICE_REQUEST), update)
  yield takeLatest(DELETE(SERVICE_REQUEST), destroy)
  yield takeLatest(SERVICE_REQUEST_PING, pingServiceRequest)
}
