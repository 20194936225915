import React from 'react'
import { NotificationTable, NotificationDetail } from 'components/pages/notifications'
import { TableLayout } from 'components/layouts'
import { NOTIFICATION } from 'constants/resources'

function Notifications() {
  return (
    <TableLayout
      disableActions
      resource={NOTIFICATION}
      table={NotificationTable}
      detail={NotificationDetail}

    />
  )
}

export default Notifications
