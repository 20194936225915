import React, { useEffect, useState } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { list as listCategories } from 'services/categories'
import PropTypes from 'prop-types'
import { CATEGORY } from 'constants/resources'

function CategorySelect({
  loading, disabled, ...otherProps
}) {
  const { t } = useTranslation(CATEGORY)
  const [options, setOptions] = useState([])

  useEffect(() => {
    listCategories({
      limit: 100,
      parent: null,
    }).then((response) => {
      const opts = response.results.map(category => ({
        value: category.id,
        label: category.name,
      }))
      setOptions(opts)
    })
  }, [])

  return (
    <Input
      name="category"
      label={t('select.label')}
      placeholder={t('select.placeholder')}
      type="select"
      disabled={disabled}
      options={options}
      {...otherProps}
    />
  )
}

CategorySelect.propTypes = {
  disabled: PropTypes.bool,
}

CategorySelect.defaultProps = {
  disabled: false,
}

export default CategorySelect
