import React from 'react'
import PropTypes from 'prop-types'

function AddressLink({
  state, country, street, city, zipCode, children,
}) {
  if (!children) return null

  const isMobile = window.innerWidth < 720
  const url = `https://www.google.com/maps/search/?api=1&query=${street} ${city} ${zipCode} ${state} ${country}`
  const handleClick = (event) => {
    event.stopPropagation()
  }

  return (!isMobile) ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      href={url}
    >
      {children}
    </a>
  ) : (
    <span>{children}</span>
  )
}

AddressLink.propTypes = {
  state: PropTypes.string,
  country: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
}

AddressLink.defaultProps = {
  state: '',
  country: '',
  street: '',
  zipCode: '',
  city: '',
}

export default AddressLink
