import React from 'react'
import {
  TableRow, TableCell, PreviewLink, FacetFilter,
} from 'components/common'
import PropTypes from 'prop-types'
import { CATEGORY, EQUIPMENT } from 'constants/resources'

function EquipmentRow({
  name,
  description,
  code,
  categoryId,
  categoryName,
  subcategoryId,
  subcategoryName,
  totalOrders,
  active,
  onClick,
  templateColumns,
  shrink,
}) {
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell md>
        <p>{code}</p>
      </TableCell>
      <TableCell sm>
        <p>{name}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <p>{description}</p>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'categories',
            value: categoryId,
            label: categoryName,
          }}
          resource={EQUIPMENT}
        >
          <PreviewLink hideMobile id={categoryId} resource={CATEGORY}>
            {categoryName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'categories',
            value: subcategoryId,
            label: subcategoryName,
          }}
          resource={EQUIPMENT}
        >
          <PreviewLink hideMobile id={subcategoryId} resource={CATEGORY}>
            {subcategoryName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell md>
        <p>{totalOrders}</p>
      </TableCell>
    </TableRow>
  )
}

EquipmentRow.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  code: PropTypes.string,
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  subcategoryId: PropTypes.string,
  subcategoryName: PropTypes.string,
  totalOrders: PropTypes.number,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
}

EquipmentRow.defaultProps = {
  name: '',
  description: '',
  code: '',
  categoryId: '',
  categoryName: '',
  subcategoryId: '',
  subcategoryName: '',
  totalOrders: 0,
  active: false,
  shrink: false,
  onClick: null,
}
export default EquipmentRow
