import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import { useQuickPreview } from 'components/hooks'
import { ORDER_ITEM } from 'constants/resources'
import { EDIT } from 'constants/crud'
import { useDispatch } from 'react-redux'
import { orderItems } from 'store/action-creators'
import { Map } from 'immutable'
import PropTypes from 'prop-types'

function PlaceService({ orderItem }) {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()

  const handleChipClick = (event) => {
    event.stopPropagation()
    dispatch(orderItems.setCurrentId(orderItem.get('id')))
    dispatch(orderItems.setCurrentData(orderItem))
    openPreview(ORDER_ITEM, EDIT)
  }

  return (
    <Chip
      onClick={handleChipClick}
      outline
      icon="edit"
      label={t('shared.edit')}
    />
  )
}

PlaceService.propTypes = {
  orderItem: PropTypes.instanceOf(Map).isRequired,
}

export default PlaceService
