import React from 'react'
import { Divider } from 'components/ui'
import { useCurrent, useResource } from 'components/hooks'
import { ORDER_ITEM } from 'constants/resources'
import {
  ContactSection, AddressSection, Destroy, TableDetail,
} from 'components/common'
import { CANCELLED, FINALIZED } from 'constants/status'
import { useTranslation } from 'react-i18next'
import Order from 'components/pages/orders/detail/Information'
import Information from './detail/Information'
import Header from './detail/Header'
import Supplier from './detail/Supplier'
import TransportSupplier from './detail/TransportSupplier'

function OrderItemDetail() {
  const { t } = useTranslation('common')
  const selected = useCurrent(ORDER_ITEM)
  const loading = useResource(ORDER_ITEM, 'current', 'loading')
  const canDestroy = [CANCELLED, FINALIZED].includes(selected.get('status'))

  return (
    <TableDetail>
      <Header
        status={selected.get('status')}
        title={selected.get('internal_code')}
        code={selected.get('internal_code')}
        orderItem={selected}
        orderItemId={selected.get('id')}
        orderId={selected.getIn(['order', 'id'])}
        equipmentName={selected.getIn(['equipment', 'name'])}
        loading={loading}
      />
      <Divider spacing={28} />
      <Information
        code={selected.get('code')}
        internalCode={selected.get('internal_code')}
        offRentAt={selected.get('off_rented_at')}
        equipmentName={selected.getIn(['equipment', 'name'])}
        equipmentId={selected.getIn(['equipment', 'id'])}
        quantity={selected.get('quantity')}
        purchaseOrder={selected.getIn(['metadata', 'external_id'])}
        deliveryCost={selected.getIn(['metadata', 'delivery_cost'])}
        pickupCost={selected.getIn(['metadata', 'pickup_cost'])}
        dailyRate={selected.getIn(['metadata', 'daily_rate'])}
        weeklyRate={selected.getIn(['metadata', 'weekly_rate'])}
        monthlyRate={selected.getIn(['metadata', 'monthly_rate'])}
        rentalProtection={selected.getIn(['metadata', 'rental_protection'])}
        envCompensation={selected.getIn(['metadata', 'env_compensation'])}
        invoicesSent={selected.get('invoices_sent') || selected.getIn(['metadata', 'invoices_sent'])}
        invoicesReceived={selected.get('invoices_received') || selected.getIn(['metadata', 'invoices_received'])}
        notes={selected.get('notes')}
        loading={loading}
      />
      <Divider spacing={20} />
      <Order
        title={t('common:resources.orders.singular')}
        code={selected.getIn(['order', 'code'])}
        internalCode={selected.getIn(['order', 'internal_code'])}
        companyName={selected.getIn(['order', 'company', 'name'])}
        companyId={selected.getIn(['order', 'company', 'id'])}
        purchaseOrder={selected.getIn(['order', 'external_id'])}
        rentallPurchaseOrder={selected.getIn(['order', 'metadata', 'rentall_external_id'])}
        notes={selected.getIn(['order', 'notes'])}
        startDate={selected.getIn(['order', 'start_date'])}
        billingDate={selected.getIn(['order', 'billing_date'])}
        siteContact={selected.getIn(['order', 'metadata', 'contact_name'])}
        contactNumber={selected.getIn(['order', 'metadata', 'contact_number'])}
        id={selected.getIn(['order', 'id'])}
        salesRepName={selected.getIn(['order', 'sales_rep', 'name'])}
        salesRepId={selected.getIn(['order', 'sales_rep', 'id'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <ContactSection
        list={selected.getIn(['order', 'metadata', 'contacts'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.getIn(['order', 'address_line1'])}
        streetDetails={selected.getIn(['order', 'address_line2'])}
        city={selected.getIn(['order', 'address_city'])}
        state={selected.getIn(['order', 'address_state'])}
        zipcode={selected.getIn(['order', 'address_zip'])}
        country={selected.getIn(['order', 'address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <Supplier
        supplierName={selected.getIn(['supplier', 'name'])}
        supplierId={selected.getIn(['supplier', 'id'])}
        dailyRate={selected.getIn(['metadata', 'supplier', 'daily_rate'])}
        weeklyRate={selected.getIn(['metadata', 'supplier', 'weekly_rate'])}
        monthlyRate={selected.getIn(['metadata', 'supplier', 'monthly_rate'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <TransportSupplier
        supplierName={selected.getIn(['transport_supplier', 'name'])}
        supplierId={selected.getIn(['transport_supplier', 'id'])}
        deliveryCost={selected.getIn(['metadata', 'transport_supplier', 'delivery_cost'])}
        pickupCost={selected.getIn(['metadata', 'transport_supplier', 'pickup_cost'])}
        loading={loading}
      />
      <Destroy resource={ORDER_ITEM} id={selected.get('id')} show={canDestroy} />
    </TableDetail>
  )
}

export default OrderItemDetail
