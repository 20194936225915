import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/orders/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/orders/${id}/`)
)

export const create = async ({
  external_id,
  company,
  start_date,
  end_date,
  billing_date,
  notes,
  metadata,
  address_line1,
  address_line2,
  address_city,
  address_state,
  address_zip,
  address_country,
  sales_rep,
}) => (
  api.post('/app/orders/', {
    external_id,
    company,
    start_date,
    end_date,
    billing_date,
    notes,
    metadata,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    sales_rep,
  })
)

export const update = async (id, {
  external_id,
  start_date,
  end_date,
  billing_date,
  notes,
  metadata,
  address_line1,
  address_line2,
  address_city,
  address_state,
  address_zip,
  address_country,
  sales_rep,
}) => (
  api.patch(`/app/orders/${id}/`, {
    external_id,
    start_date,
    end_date,
    billing_date,
    notes,
    metadata,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    sales_rep,
  })
)

export const destroy = async id => (
  api.post(`/app/orders/${id}/cancel/`)
)
