import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/notifications/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/notifications/${id}/`)
)

export const acknowledge = async id => (
  api.post(`/app/notifications/${id}/`, {})
)
