import React from 'react'
import styled from 'styled-components'
import { Navbar } from 'components/common'
import { MD } from 'constants/breakpoints'

const Container = styled.div`
  padding: 0 20px 20px 20px;
  box-sizing: border-box;

  @media (max-width: ${MD}px) {
    padding: 0 10px 10px 10px;
  }
`

function MainLayout({ children }) {
  return (
    <Container>
      <Navbar />
      {children}
    </Container>
  )
}

export default MainLayout
