import React from 'react'
import {
  Input, AddressFields, Button, Flex,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { ORDER, SERVICE_REQUEST } from 'constants/resources'
import { EquipmentSelect } from 'components/pages/equipment'
import { CompanySelect } from 'components/pages/companies'


function Form({ current, loading, update }) {
  const { t } = useTranslation([SERVICE_REQUEST, ORDER, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      designation: 'rental-price',
      metadata: {
        company: current.getIn(['metadata', 'company']),
        equipment: current.getIn(['metadata', 'equipment']),
        duration: current.getIn(['metadata', 'duration']),
        delivery_date: current.getIn(['metadata', 'delivery_date']),
        last_quote: current.getIn(['metadata', 'last_quote']),
        quote_type: current.getIn(['metadata', 'quote_type']),
        current_supplier: current.getIn(['metadata', 'current_supplier']),
      },
      state: current.get('state'),
      assignee: current.getIn(['assignee', 'id']),
      notes: current.get('notes'),
      address_line1: current.get('address_line1'),
      address_line2: current.get('address_line2'),
      address_zip: current.get('address_zip'),
      address_city: current.get('address_city'),
      address_state: current.get('address_state'),
      address_country: current.getIn(['address_country', 'code']),
    },
  })

  const onSubmit = (body) => {
    if (update) {
      dispatch(serviceRequests.update(body))
    } else {
      dispatch(serviceRequests.create(body))
    }
  }

  const handleChange = (results) => {
    const [value] = results
    return (value && value.label) || value
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="designation" value="rental-price" ref={register} />

      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <Controller
            as={CompanySelect}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:company.required'),
              },
            }}
            required
            error={errors?.metadata?.company?.message}
            name="metadata.company"
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            defaultValue={current.getIn(['metadata', 'company'])}
            onChange={handleChange}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={EquipmentSelect}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:equipment.required'),
              },
            }}
            required
            error={errors?.metadata?.equipment?.message}
            name="metadata.equipment"
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            defaultValue={current.getIn(['metadata', 'equipment'])}
            onChange={handleChange}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.assignee?.message}
            name="assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
            disabled={loading || !update}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-service-request-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.duration"
            defaultValue={current.getIn(['metadata', 'duration'])}
            error={errors.duration?.message}
            type="text"
            rules={{
              required: {
                value: true,
                message: t('error:duration.required'),
              },
            }}
            required
            label={t('serviceRequest:shared.duration')}
            placeholder={t('serviceRequest:new.duration.placeholder')}
            id="new-service-request-duration"
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.delivery_date"
            error={errors.delivery_date?.message}
            defaultValue={current.getIn(['metadata', 'delivery_date'])}
            type="text"
            required
            label={t('serviceRequest:shared.deliveryDate')}
            placeholder={t('serviceRequest:new.deliveryDate.placeholder')}
            id="new-service-request-deliveryDate"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            type="text"
            name="metadata.last_quote"
            defaultValue={current.getIn(['metadata', 'last_quote'])}
            error={errors.last_quote?.message}
            label={t('serviceRequest:shared.lastQuote')}
            placeholder={t('serviceRequest:new.lastQuote.placeholder')}
            id="new-service-request-lastQuote"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.quote_type"
            error={errors.quote_type?.message}
            defaultValue={current.getIn(['metadata', 'quote_type'])}
            type="select"
            options={[
              { value: 'daily', label: t('orderItem:shared.dailyRate') },
              { value: 'weekly', label: t('orderItem:shared.weeklyRate') },
              { value: 'monthly', label: t('orderItem:shared.monthlyRate') },
              { value: 'transport', label: t('orderItem:shared.deliveryCost') },
            ]}
            label={t('serviceRequest:shared.priceType')}
            placeholder={t('serviceRequest:new.priceType.placeholder')}
            id="new-service-request-priceType"
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.current_supplier"
            defaultValue={current.getIn(['metadata', 'current_supplier'])}
            error={errors.current_supplier?.message}
            type="text"
            label={t('serviceRequest:shared.currentSupplier')}
            placeholder={t('serviceRequest:new.currentSupplier.placeholder')}
            id="new-service-request-supplier"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
          cityRequired
          stateRequired
        />

        {
          update ? (
            <Grid item xs={12} md={12}>
              <Controller
                as={Input}
                control={control}
                error={errors.state?.message}
                name="state"
                type="text"
                multiline
                label={t('common:shared.status')}
                placeholder={t('serviceRequest:new.state.placeholder')}
                id="new-service-request-state"
                defaultValue={current.get('state')}
                disabled={loading}
              />
            </Grid>
          ) : null
        }
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            multiline
            label={t('common:shared.notes')}
            placeholder={t('serviceRequest:new.notes.placeholder')}
            id="new-service-request-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('serviceRequest:edit.submitRequest') : t('serviceRequest:new.submitRequest')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
