import React from 'react'
import { ORDER_ITEM } from 'constants/resources'
import { NEW } from 'constants/crud'
import { orderItems } from 'store/action-creators'
import { CANCELLED, FINALIZED } from 'constants/status'
import { useDispatch } from 'react-redux'
import { useQuickPreview } from 'components/hooks'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'

function OrderItemButton({ status, id }) {
  const { t } = useTranslation('common')
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleChipClick = () => {
    openPreview(ORDER_ITEM, NEW)
    dispatch(orderItems.setCreateData({ order: { id } }))
  }

  if ([CANCELLED, FINALIZED].includes(status)) return null

  return (
    <Chip
      outline
      icon="add"
      onClick={handleChipClick}
      label={t('common:resources.orderItems.singular')}
    />
  )
}

OrderItemButton.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
}

OrderItemButton.defaultProps = {
  id: '',
  status: '',
}


export default OrderItemButton
