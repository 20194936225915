import React from 'react'
import { SupplierTable, SupplierDetail } from 'components/pages/suppliers'
import { TableLayout } from 'components/layouts'
import { SUPPLIER } from 'constants/resources'

function Supplier() {
  return (
    <TableLayout
      resource={SUPPLIER}
      table={SupplierTable}
      detail={SupplierDetail}
    />
  )
}

export default Supplier
