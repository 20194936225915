import React, { Suspense } from 'react'
import { Loading } from 'components/layouts'
import Component from 'Component'
import ThemeProvider from 'components/providers/Theme'
import StoreProvider from 'components/providers/Store'
import HistoryProvider from 'components/providers/History'
import { BrowserRouter } from 'react-router-dom'
import Styles from 'config/style'
import QuickPreview from 'components/layouts/QuickPreview'
import DevelopmentBanner from 'components/common/DevelopmentBanner'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'config/noty'
import 'config/i18n'
import 'config/nprogress'
import 'config/one-signal'

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <StoreProvider>
        <HistoryProvider>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Styles />
              <BrowserRouter>
                <DevelopmentBanner />
                <Component />
                <QuickPreview />
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </HistoryProvider>
      </StoreProvider>
    </Suspense>
  )
}

export default App
