import MaterialCheckbox from '@material-ui/core/Checkbox'
import React from 'react'


function Checkbox({ label, ...otherProps }) {
  return (
    <div>
      <MaterialCheckbox {...otherProps} />
      <span>{label}</span>
    </div>
  )
}

export default Checkbox
