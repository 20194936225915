export const LIST_RESET = RESOURCE => `GET_${RESOURCE}_LIST_RESET`
export const LIST = RESOURCE => `GET_${RESOURCE}_LIST`
export const SET_LIST_DATA = RESOURCE => `SET_${RESOURCE}_LIST_DATA`
export const PREPEND_LIST_ITEM = RESOURCE => `PREPEND_${RESOURCE}_LIST_ITEM`
export const UPDATE_LIST_ITEM = RESOURCE => `UPDATE_${RESOURCE}_LIST_ITEM`
export const REMOVE_LIST_ITEM = RESOURCE => `REMOVE_${RESOURCE}_LIST_ITEM`
export const SET_LIST_SEARCH_INDEX = RESOURCE => `SET_${RESOURCE}_LIST_INDEX`
export const CLEAR_LIST_DATA = RESOURCE => `CLEAR_${RESOURCE}_LIST_DATA`
export const SET_LIST_SEARCH_QUERY = RESOURCE => `SET_${RESOURCE}_LIST_SEARCH_QUERY`
export const CLEAR_LIST_SEARCH_FACET_FILTERS = RESOURCE => `SET_${RESOURCE}_LIST_FACET_FILTERS`
export const ADD_LIST_SEARCH_FACET_FILTERS = RESOURCE => `ADD_${RESOURCE}_LIST_FACET_FILTERS`
export const REMOVE_LIST_SEARCH_FACET_FILTERS = RESOURCE => `REMOVE_${RESOURCE}_LIST_FACET_FILTERS`
export const EMPTY_LIST_SEARCH_FACET_FILTERS = RESOURCE => `EMPTY_${RESOURCE}_LIST_FACET_FILTERS`
export const CLEAR_LIST_SEARCH_FILTERS = RESOURCE => `SET_${RESOURCE}_LIST_FILTERS`
export const ADD_LIST_SEARCH_FILTERS = RESOURCE => `ADD_${RESOURCE}_LIST_FILTERS`
export const REMOVE_LIST_SEARCH_FILTERS = RESOURCE => `REMOVE_${RESOURCE}_LIST_FILTERS`
export const EMPTY_LIST_SEARCH_FILTERS = RESOURCE => `EMPTY_${RESOURCE}_LIST_FILTERS`
export const SET_LIST_METADATA = RESOURCE => `SET_${RESOURCE}_LIST_METADATA`
export const SET_LIST_LOADING = RESOURCE => `SET_${RESOURCE}_LIST_LOADING`
export const GET = RESOURCE => `GET_${RESOURCE}`
export const SET = RESOURCE => `SET_${RESOURCE}`
export const SET_CURRENT_DATA = RESOURCE => `SET_${RESOURCE}_CURRENT_DATA`
export const CLEAR_CURRENT_DATA = RESOURCE => `CLEAR_${RESOURCE}_CURRENT_DATA`
export const SET_CURRENT_ID = RESOURCE => `SET_${RESOURCE}_CURRENT_ID`
export const SET_CURRENT_LOADING = RESOURCE => `SET_${RESOURCE}_CURRENT_LOADING`
export const CREATE = RESOURCE => `CREATE_${RESOURCE}`
export const SET_CREATE_DATA = RESOURCE => `SET_${RESOURCE}_CREATE_DATA`
export const CLEAR_CREATE_DATA = RESOURCE => `CLEAR_${RESOURCE}_CREATE_DATA`
export const SET_CREATE_LOADING = RESOURCE => `SET_${RESOURCE}_CREATE_LOADING`
export const UPDATE = RESOURCE => `UPDATE_${RESOURCE}`
export const UPDATE_STATUS = RESOURCE => `UPDATE_${RESOURCE}_STATUS`
export const SET_UPDATE_DATA = RESOURCE => `SET_${RESOURCE}_UPDATE_DATA`
export const CLEAR_UPDATE_DATA = RESOURCE => `CLEAR_${RESOURCE}_UPDATE_DATA`
export const SET_UPDATE_LOADING = RESOURCE => `SET_${RESOURCE}_UPDATE_LOADING`
export const DELETE = RESOURCE => `DELETE_${RESOURCE}`
export const SET_DELETE_LOADING = RESOURCE => `SET_${RESOURCE}_DELETE_LOADING`
