import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip, DetailHeader } from 'components/common'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { sessions } from 'store/action-creators'
import { USER } from 'constants/resources'

function Header({
  isOwner,
  isAdmin,
  isActive,
  title,
  email,
  loading,
}) {
  const { t } = useTranslation([USER, 'session', 'common'])
  const dispatch = useDispatch()

  const handleResetClick = () => {
    if (email && window.confirm(t('session:password.resetConfirm'))) {
      dispatch(sessions.resetPassword(email))
    }
  }
  return (
    <DetailHeader title={title} loading={loading}>
      <Chip
        outline
        icon="vpn_key"
        onClick={handleResetClick}
        label={t('session:password.reset')}
      />
      <Chip
        outline={!isActive}
        label={t('user:shared.active')}
      />
      {
        isAdmin ? (
          <Chip label={t('user:shared.admin')} />
        ) : null
      }
      {
       isOwner ? (
         <Chip label={t('common:shared.owner')} />
       ) : null
      }
    </DetailHeader>
  )
}
Header.propTypes = {
  isOwner: PropTypes.bool,
  isActive: PropTypes.bool,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  email: PropTypes.string,
}

Header.defaultProps = {
  isOwner: false,
  isActive: true,
  isAdmin: false,
  loading: true,
  title: '',
  email: '',
}


export default Header
