export const CREATED = 'created'
export const IN_PROGRESS = 'in-progress'
export const RESOLVED = 'resolved'
export const CANCELLED = 'cancelled'
export const ACCEPTED = 'accepted'
export const ACTIVE = 'active'
export const FINALIZED = 'finalized'
export const SCHEDULED = 'scheduled'
export const ON_RENT = 'on-rent'
export const OFF_RENT = 'off-rent'
export const RE_OPENED = 're-opened'
export const ACKNOWLEDGED = 'acknowledged'
