import React from 'react'
import PropTypes from 'prop-types'
import { PHONE_FORMAT } from 'constants/patterns'

function PhoneNumber({ number }) {
  if (!number) return null

  const formattedNumber = number.replace(PHONE_FORMAT, '$1 $2 $3 $4')
  return (
    <a href={`tel:${number}`}>{formattedNumber}</a>
  )
}

PhoneNumber.propTypes = {
  number: PropTypes.string,
}

PhoneNumber.defaultProps = {
  number: '',
}

export default PhoneNumber
