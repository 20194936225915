import {
  COMPANY,
  CATEGORY,
  EQUIPMENT,
  NOTIFICATION,
  ORDER_ITEM,
  ORDER,
  RESERVATION,
  SERVICE_REQUEST,
  SUPPLIER,
  SUPPLIER_REVIEW,
  USER,
  RESERVATION_ITEM,
  ORDER_SERVICE,
} from 'constants/resources'
import application from './application'
import navigation from './navigation'
import sessions from './sessions'
import reservations from './reservations'
import serviceRequests from './serviceRequests'
import notifications from './notifications'
import equipment from './equipment'
import createActionsWithResource from './factory'

const companies = createActionsWithResource(COMPANY)
const categories = createActionsWithResource(CATEGORY)
const orders = createActionsWithResource(ORDER)
const suppliers = createActionsWithResource(SUPPLIER)
const supplierReviews = createActionsWithResource(SUPPLIER_REVIEW)
const users = createActionsWithResource(USER)
const orderItems = createActionsWithResource(ORDER_ITEM)
const reservationItems = createActionsWithResource(RESERVATION_ITEM)
const orderServices = createActionsWithResource(ORDER_SERVICE)

const actions = {}
actions[COMPANY] = companies
actions[CATEGORY] = categories
actions[EQUIPMENT] = equipment
actions[NOTIFICATION] = notifications
actions[ORDER_ITEM] = orderItems
actions[ORDER] = orders
actions[RESERVATION] = reservations
actions[RESERVATION_ITEM] = reservationItems
actions[SERVICE_REQUEST] = serviceRequests
actions[SUPPLIER] = suppliers
actions[SUPPLIER_REVIEW] = supplierReviews
actions[USER] = users
actions[ORDER_SERVICE] = orderServices
actions.navigation = navigation

export {
  application,
  companies,
  categories,
  equipment,
  notifications,
  orderItems,
  orders,
  reservations,
  reservationItems,
  serviceRequests,
  sessions,
  suppliers,
  supplierReviews,
  users,
  navigation,
  orderServices,
}

export default actions
