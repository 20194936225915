import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/orders/items/?${buildQueryString(filters)}`)
)


export const get = async id => (
  api.get(`/app/orders/items/${id}/`)
)

export const create = async ({
  status,
  order,
  equipment,
  supplier,
  transport_supplier,
  metadata,
  invoices_sent,
  invoices_received,
  off_rented_at,
  quantity,
  notes,
}) => (
  api.post('/app/orders/items/', {
    status,
    order,
    equipment,
    supplier,
    transport_supplier,
    metadata,
    invoices_sent,
    invoices_received,
    off_rented_at,
    quantity,
    notes,
  })
)

export const update = async (id, {
  status,
  order,
  equipment,
  supplier,
  transport_supplier,
  metadata,
  invoices_sent,
  invoices_received,
  off_rented_at,
  quantity,
  notes,
}) => (
  api.patch(`/app/orders/items/${id}/`, {
    status,
    order,
    equipment,
    supplier,
    transport_supplier,
    metadata,
    invoices_sent,
    invoices_received,
    off_rented_at,
    quantity,
    notes,
  })
)

export const destroy = async id => (
  api.delete(`/app/orders/items/${id}/`)
)
