import React, { useState, useEffect } from 'react'
import { Divider } from 'components/ui'
import { useCurrent, useResource } from 'components/hooks'
import { NOTIFICATION } from 'constants/resources'
import { TableDetail } from 'components/common'
import { ACCOUNT_REQUEST } from 'constants/designations'
import { Map, fromJS } from 'immutable'
import { get as getReservation } from 'services/reservations'
import { get as getReservationItem } from 'services/reservation-items'
import { get as getServiceRequest } from 'services/service-requests'
import Header from './detail/Header'
import Account from './detail/Account'
import Reservation from './detail/Reservation'
import ServiceRequest from './detail/ServiceRequest'
import ReservationItem from './detail/ReservationItem'

function NotificationDetail() {
  const selected = useCurrent(NOTIFICATION)
  const loading = useResource(NOTIFICATION, 'current', 'loading')
  const [reservation, setReservation] = useState(new Map())
  const [reservationItem, setReservationItem] = useState(new Map())
  const [fetchingReservation, setFetchingReservation] = useState(false)
  const [fetchingReservationItem, setFetchingReservationItem] = useState(false)
  const [serviceRequest, setServiceRequest] = useState(new Map())
  const [fetchingService, setFetchingService] = useState(false)
  const reservationItemId = selected.getIn(['reservation_item', 'id'])
  const reservationId = selected.getIn(['reservation', 'id'])
  const serviceRequestId = selected.getIn(['service_request', 'id'])

  useEffect(() => {
    if (serviceRequestId) {
      setFetchingService(true)
      getServiceRequest(serviceRequestId)
        .then((response) => {
          setServiceRequest(fromJS(response))
          setFetchingService(false)
        })
    }
    return () => { setServiceRequest(new Map()) }
  }, [serviceRequestId])

  useEffect(() => {
    if (reservationId) {
      setFetchingReservation(true)
      getReservation(reservationId)
        .then((response) => {
          setReservation(fromJS(response))
          setFetchingReservation(false)
        })
    }
    return () => { setReservation(new Map()) }
  }, [reservationId])

  useEffect(() => {
    if (reservationItemId) {
      setFetchingReservationItem(true)
      getReservationItem(reservationItemId)
        .then((response) => {
          setReservationItem(fromJS(response))
          setFetchingReservationItem(false)
        })
    }
    return () => { setReservationItem(new Map()) }
  }, [reservationItemId])

  console.log(serviceRequest)
  return (
    <TableDetail>
      <Header
        designation={selected.get('designation')}
        isAcknowledged={!!selected.get('acknowledged_at')}
        loading={loading}
        id={selected.get('id')}
        company={selected.getIn(['metadata', 'company'])}
        contactNumber={selected.getIn(['metadata', 'contact_number'])}
        email={selected.getIn(['metadata', 'email'])}
        name={selected.getIn(['metadata', 'name'])}
        reservationStartDate={reservation.get('start_date')}
        reservationCompany={reservation.getIn(['company', 'id'])}
        reservationId={reservationId}
        reservationItemId={reservationItemId}
        reservationStatus={reservation.get('designation')}
        serviceRequest={serviceRequest}
      />
      <Divider spacing={28} />
      {
        selected.get('designation') === ACCOUNT_REQUEST ? (
          <Account
            company={selected.getIn(['metadata', 'company'])}
            contactNumber={selected.getIn(['metadata', 'contact_number'])}
            email={selected.getIn(['metadata', 'email'])}
            name={selected.getIn(['metadata', 'name'])}
            loading={loading}
            isAcknowledged={!!selected.get('acknowledged_at')}
          />
        ) : null
      }
      <Reservation
        id={reservationId}
        designation={selected.get('designation')}
        isAcknowledged={!!selected.get('acknowledged_at')}
        loading={fetchingReservation}
        companyName={reservation.getIn(['company', 'name']) || reservation.getIn(['metadata', 'company'])}
        companyId={reservation.getIn(['company', 'id'])}
        contactName={selected.getIn(['actor', 'name']) || reservation.getIn(['metadata', 'name'])}
        contactId={selected.getIn(['actor', 'id'])}
        contactNumber={reservation.getIn(['metadata', 'contact_number'])}
        contactEmail={reservation.getIn(['metadata', 'email'])}
        postalCode={reservation.get('postal_code')}
        startDate={reservation.get('start_date')}
        endDate={reservation.get('end_date')}
        voiceNoteUrl={reservation.get('voice_note')}
        voiceNoteExpiration={reservation.get('voice_note_expires_at')}
        items={reservation.get('items')}
      />
      <ReservationItem
        id={reservationItemId}
        equipmentId={reservationItem.getIn(['equipment', 'id'])}
        equipmentName={reservationItem.getIn(['equipment', 'name'])}
        quantity={reservationItem.get('quantity')}
        loading={fetchingReservationItem}
      />
      <ServiceRequest
        id={serviceRequestId}
        selected={serviceRequest}
        loading={fetchingService}
      />
    </TableDetail>
  )
}

export default NotificationDetail
