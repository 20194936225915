import React, { useMemo } from 'react'
import {
  InlineTable, TableRow, PreviewLink,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { list as listCompanies } from 'services/companies'
import { USER, COMPANY } from 'constants/resources'
import { usePaginatedList, useQuickPreview } from 'components/hooks'
import { Grid } from '@material-ui/core'
import { SHOW } from 'constants/crud'
import { useDispatch } from 'react-redux'
import { companies } from 'store/action-creators'

function ServiceCallId({ userId }) {
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()
  const { t } = useTranslation([USER, 'common', 'orderItem'])
  const templateColumns = '1fr'
  const options = useMemo(() => (userId ? ({
    sales_rep: userId,
    limit: 5,
  }) : null), [userId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listCompanies, options)

  const handleRowClick = (data) => {
    dispatch(companies.setCurrentData(data))
    openPreview(COMPANY, SHOW)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('user:shared.assignedCompanies')} (${count})`}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(item => (
            <TableRow
              key={item.get('id')}
              fullSize
              templateColumns={templateColumns}
              onClick={() => { handleRowClick(item) }}
            >
              <PreviewLink
                resource={COMPANY}
                id={item.get('id')}
              >
                {item.get('name')}
              </PreviewLink>
            </TableRow>
          ))
        }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

ServiceCallId.propTypes = {
  userId: PropTypes.string,
}

ServiceCallId.defaultProps = {
  userId: '',
}

export default ServiceCallId
