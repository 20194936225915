import React, { useMemo } from 'react'
import {
  Chip, InlineTable, TableRow, TableHeader, PreviewLink, Date,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { list as listServiceRequest } from 'services/service-requests'
import {
  SERVICE_REQUEST, ORDER_ITEM, USER,
} from 'constants/resources'
import { usePaginatedList, useStatusColors, useQuickPreview } from 'components/hooks'
import { Grid } from '@material-ui/core'
import { SERVICE_CALL } from 'constants/designations'
import { SHOW } from 'constants/crud'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'

function ServiceCallId({ userId }) {
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()
  const { t } = useTranslation([USER, 'common', 'orderItem'])
  const statusColors = useStatusColors(SERVICE_REQUEST)
  const templateColumns = '1fr 1fr 2fr'
  const options = useMemo(() => (userId ? ({
    assignee: userId,
    designation: SERVICE_CALL,
    limit: 5,
  }) : null), [userId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listServiceRequest, options)

  const handleRowClick = (data) => {
    dispatch(serviceRequests.setCurrentData(data))
    openPreview(SERVICE_REQUEST, SHOW)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('user:serviceCallList.header')} (${count})`}
          header={(
            <TableHeader
              fullSize
              templateColumns={templateColumns}
            >
              <p>{t('common:shared.code')}</p>
              <p>{t('common:resources.orderItems.singular')}</p>
              <p>{t('common:shared.createdAt')}</p>
            </TableHeader>
          )}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(item => (
            <TableRow
              key={item.get('id')}
              fullSize
              templateColumns={templateColumns}
              onClick={() => { handleRowClick(item) }}
            >
              <div>
                <Chip
                  background={statusColors[item.get('status')]}
                  label={t(`serviceRequest:status.${item.get('status')}`)}
                />
              </div>
              <PreviewLink
                resource={ORDER_ITEM}
                id={item.getIn(['order_item', 'id'])}
              >
                {item.getIn(['order_item', 'code'])}
              </PreviewLink>
              <Date variant="datetime">{item.get('created')}</Date>
            </TableRow>
          ))
        }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

ServiceCallId.propTypes = {
  userId: PropTypes.string,
}

ServiceCallId.defaultProps = {
  userId: '',
}

export default ServiceCallId
