import React, { useMemo } from 'react'
import { NotificationRow } from 'components/pages/notifications'
import { TableContent, TableLoading } from 'components/common'
import { NOTIFICATION } from 'constants/resources'
import {
  useResource, usePagination, useSelectResource, useDeviceColumns, useResourceList,
} from 'components/hooks'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

function NotificationContent({ columns }) {
  const id = useSelector(state => state.getIn(['session', 'user', 'id']))
  const selected = useResource(NOTIFICATION, 'current', 'id')
  const loading = useResource(NOTIFICATION, 'list', 'loading')
  const handleRowClick = useSelectResource(NOTIFICATION)
  const { refineNext, hasMore } = usePagination(NOTIFICATION)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const params = useMemo(() => ({ status: 'created', limit: 50, receiver: id }), [id])
  const list = useResourceList(NOTIFICATION, params)
  return (
    <TableContent
      next={refineNext}
      dataLength={list.count}
      hasMore={hasMore}
    >
      <TableLoading loading={loading} />
      {
        list.map(notification => (
          <NotificationRow
            key={notification.get('id')}
            id={notification.get('id')}
            templateColumns={templateColumns}
            designation={notification.get('designation')}
            status={notification.get('status')}
            body={notification.get('body')}
            createdAt={notification.get('created')}
            acknowledgedAt={notification.get('acknowledged_at')}
            acknowledgedBy={notification.getIn(['acknowledged_by', 'name'])}
            actorName={notification.getIn(['actor', 'name'])}
            actorId={notification.getIn(['actor', 'id'])}
            companyName={notification.getIn(['actor', 'company', 'name'])}
            companyId={notification.getIn(['actor', 'company', 'id'])}
            active={notification.get('id') === selected}
            onClick={() => handleRowClick(notification.get('id'))}
            shrink={!!selected}
          />
        ))
      }
    </TableContent>
  )
}


NotificationContent.propTypes = {
  columns: PropTypes.object.isRequired,
}


export default NotificationContent
