import shared from './shared'
import common from './color/common'
import black from './color/black'
import white from './color/white'

const light = {
  name: 'light',
  color: {
    primary: white['200'],
    primary100: white['100'],
    primary200: white['200'],
    primary300: white['300'],
    primary400: white['400'],
    primary500: white['500'],
    secondary: black['200'],
    secondary100: black['100'],
    secondary200: black['200'],
    secondary300: black['300'],
    secondary400: black['400'],
    secondary500: black['500'],
    ...common,
  },
  ...shared,
}

export default light
