import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Divider } from 'components/ui'
import styled from 'styled-components'
import Information from './detail/Information'
import Header from './detail/Header'
import Supplier from './detail/Supplier'
import TransportSupplier from './detail/TransportSupplier'

const StyledCollapsible = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.primary200};
  padding: 1em 0;
  .collapsible-visible {
    cursor: pointer;
  }
  .collapsible-content {
    box-sizing: border-box;
    transition: 300ms ease-out;
    max-height: ${({ open }) => (open ? 1500 : 1)}px;
    overflow: hidden;
  }
  &:last-child {
    margin-bottom: 80px;
  }
`

function OrderItemDetail({
  selected, loading, open, onClick,
}) {
  return (
    <StyledCollapsible open={open}>
      <div className="collapsible-visible" onClick={() => onClick(selected.get('id'))}>
        <Header
          small
          isEdit
          title={selected.getIn(['equipment', 'name'])}
          status={selected.get('status')}
          code={selected.get('code')}
          orderItemId={selected.get('id')}
          orderId={selected.getIn(['order', 'id'])}
          orderItem={selected}
          equipmentName={selected.getIn(['equipment', 'name'])}
          loading={loading}
        />
      </div>
      <div className="collapsible-content">
        <Divider spacing={20} />
        <Information
          offRentAt={selected.get('off_rented_at')}
          equipmentName={selected.getIn(['equipment', 'name'])}
          equipmentId={selected.getIn(['equipment', 'id'])}
          quantity={selected.get('quantity')}
          deliveryCost={selected.getIn(['metadata', 'delivery_cost'])}
          pickupCost={selected.getIn(['metadata', 'pickup_cost'])}
          dailyRate={selected.getIn(['metadata', 'daily_rate'])}
          weeklyRate={selected.getIn(['metadata', 'weekly_rate'])}
          monthlyRate={selected.getIn(['metadata', 'monthly_rate'])}
          rentalProtection={selected.getIn(['metadata', 'rental_protection'])}
          envCompensation={selected.getIn(['metadata', 'env_compensation'])}
          invoicesSent={selected.get('invoices_sent') || selected.getIn(['metadata', 'invoices_sent'])}
          invoicesReceived={selected.get('invoices_received') || selected.getIn(['metadata', 'invoices_received'])}
          notes={selected.get('notes')}
          code={selected.get('code')}
          internalCode={selected.get('internal_code')}
          loading={loading}
        />
        <Divider spacing={20} />
        <Supplier
          supplierName={selected.getIn(['supplier', 'name'])}
          supplierId={selected.getIn(['supplier', 'id'])}
          dailyRate={selected.getIn(['metadata', 'supplier', 'daily_rate'])}
          weeklyRate={selected.getIn(['metadata', 'supplier', 'weekly_rate'])}
          monthlyRate={selected.getIn(['metadata', 'supplier', 'monthly_rate'])}
          loading={loading}
        />
        <Divider spacing={20} />
        <TransportSupplier
          supplierName={selected.getIn(['transport_supplier', 'name'])}
          supplierId={selected.getIn(['transport_supplier', 'id'])}
          deliveryCost={selected.getIn(['metadata', 'transport_supplier', 'delivery_cost'])}
          pickupCost={selected.getIn(['metadata', 'transport_supplier', 'pickup_cost'])}
          loading={loading}
        />
      </div>
    </StyledCollapsible>
  )
}

OrderItemDetail.propTypes = {
  selected: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

OrderItemDetail.defaultProps = {
  selected: new Map(),
  loading: false,
  open: false,
}


export default OrderItemDetail
