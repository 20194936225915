
import React from 'react'
import { RequestsRow } from 'components/pages/requests'
import { TableContent, TableLoading } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import {
  useResource, useStatusColors, useSelectResource, useDeviceColumns, useStoreAlgolia, useFacetFilters,
} from 'components/hooks'
import PropTypes from 'prop-types'
import { serviceRequests } from 'store/action-creators'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'

function RequestsContent({
  hits, hasMore, refineNext, hasPrevious, refinePrevious, columns,
}) {
  const statusColors = useStatusColors(SERVICE_REQUEST)
  const selected = useResource(SERVICE_REQUEST, 'current', 'id')
  const list = useStoreAlgolia(SERVICE_REQUEST, serviceRequests, hits)
  const loading = useResource(SERVICE_REQUEST, 'list', 'loading')
  const handleRowClick = useSelectResource(SERVICE_REQUEST)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(SERVICE_REQUEST)


  return (
    <TableContent
      next={refineNext}
      dataLength={list.count}
      hasMore={hasMore}
    >
      <Configure
        hitsPerPage={40}
        filters="designation:off-rent OR designation:order OR designation:rental-price OR designation:purchase-price"
        facetFilters={facetFilters}
      />
      <TableLoading loading={loading} />
      {
        list.filter(req => !req.get('is_redacted')).map(serviceRequest => (
          <RequestsRow
            statusColor={statusColors[serviceRequest.get('status')]}
            key={serviceRequest.get('id')}
            id={serviceRequest.get('id')}
            shrink={!!selected}
            templateColumns={templateColumns}
            code={serviceRequest.get('code')}
            internalCode={serviceRequest.get('internal_code')}
            status={serviceRequest.get('status')}
            companyName={serviceRequest.getIn(['order_item', 'order', 'company', 'name']) || serviceRequest.getIn(['metadata', 'company'])}
            companyId={serviceRequest.getIn(['order_item', 'order', 'company', 'id']) || serviceRequest.getIn(['metadata', 'company', 'id'])}
            equipmentName={serviceRequest.getIn(['order_item', 'equipment', 'name']) || serviceRequest.getIn(['metadata', 'equipment'])}
            equipmentId={serviceRequest.getIn(['order_item', 'equipment', 'id']) || serviceRequest.getIn(['metadata', 'equipment', 'id'])}
            assigneeName={serviceRequest.getIn(['assignee', 'name'])}
            assigneeId={serviceRequest.getIn(['assignee', 'id'])}
            salesRepName={serviceRequest.getIn(['sales_rep', 'name'])}
            salesRepId={serviceRequest.getIn(['sales_rep', 'id'])}
            equipment={serviceRequest.getIn(['order_item', 'equipment'])}
            createdAt={serviceRequest.get('created')}
            createdByName={serviceRequest.getIn(['created_by', 'name'])}
            createdById={serviceRequest.getIn(['created_by', 'id'])}
            designation={serviceRequest.get('designation')}
            active={serviceRequest.get('id') === selected}
            onClick={() => handleRowClick(serviceRequest.get('id'))}
          />
        ))
      }
    </TableContent>
  )
}


RequestsContent.propTypes = {
  columns: PropTypes.object.isRequired,
}


export default connectInfiniteHits(RequestsContent)
