import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects'
import {
  createSession as createSessionService,
  verifySession as verifySessionService,
  getCurrent as getCurrentService,
  resetPassword as resetPasswordService,
  changePassword as changePasswordService,
} from 'services/sessions'
import { sessions } from 'store/action-creators'
import {
  CREATE_SESSION,
  VERIFY_SESSION,
  GET_CURRENT_SESSION,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from 'store/actions/sessions'
import { push } from 'connected-react-router'
import api from 'config/axios'
import { notify } from 'utils'
import Cookies from 'universal-cookie'
import i18n from 'config/i18n'
import nprogress from './nprogress'

function* createSession({ payload }) {
  yield call(nprogress, true)
  const query = yield select(state => state.getIn(['router', 'location', 'query']))
  const response = yield call(createSessionService, payload)
  if (response.status === 200) {
    const cookies = new Cookies()
    yield put(sessions.setToken(response.token))
    api.defaults.headers.common.Authorization = `Bearer ${response.token}`
    cookies.set('jwt', response.token, {
      expires: new Date(response.pk * 1000 + 604800000),
    })
    notify({
      text: i18n.t('session:login.success'),
    })
    if (query.l) {
      yield put(push(query.l))
    } else {
      yield put(push('/companies'))
    }
    yield call(nprogress, false)
  }
}

function* verifySession() {
  yield call(nprogress, true)
  const response = yield call(verifySessionService, { jwt: '' })
  if (response.status === 200) {
    yield call(nprogress, false)
  }
  yield call(nprogress, false)
}

function* getCurrent() {
  yield call(nprogress, true)
  const response = yield call(getCurrentService)
  if (response) {
    yield put(sessions.setCurrent(response))
    yield call(nprogress, false)
  }
  yield call(nprogress, false)
}

function* resetPassword({ payload }) {
  yield call(nprogress, true)
  const { body: email, onSuccess, onError } = payload
  const response = yield call(resetPasswordService, { email })
  if (response) {
    notify({
      text: i18n.t('session:password.success', { email }),
    })
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
}

function* changePassword({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const response = yield call(changePasswordService, body)
  if (response) {
    notify({
      text: i18n.t('session:reset.success'),
    })
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
}

export default function* sessionSaga() {
  yield takeLatest(GET_CURRENT_SESSION, getCurrent)
  yield takeLatest(CHANGE_PASSWORD, changePassword)
  yield takeLatest(CREATE_SESSION, createSession)
  yield takeLatest(VERIFY_SESSION, verifySession)
  yield takeLatest(RESET_PASSWORD, resetPassword)
}
