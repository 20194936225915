import React from 'react'
import { EquipmentTable, EquipmentDetail } from 'components/pages/equipment'
import { TableLayout } from 'components/layouts'
import { EQUIPMENT } from 'constants/resources'

function Equipment() {
  return (
    <TableLayout
      resource={EQUIPMENT}
      table={EquipmentTable}
      detail={EquipmentDetail}
    />
  )
}

export default Equipment
