import React from 'react'
import PropTypes from 'prop-types'
import {
  Detail, PhoneNumber, SectionHeader,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import { ORDER } from 'constants/resources'

function ContactSection({
  list,
  loading,
}) {
  const { t } = useTranslation('common', ORDER)
  if (!list.size) return null

  return (
    <Grid container spacing={2}>
      <SectionHeader title={t('order:shared.siteContacts')} />
      {
        list.map(contact => (
          <>
            <Grid item xs={12} md={6}>
              <Detail
                text={contact.get('name')}
                loading={loading}
                label={t('common:shared.name')}
              >
                {contact.get('name')}
              </Detail>
            </Grid>
            <Grid item xs={12} md={6}>
              <Detail
                text={contact.get('number')}
                loading={loading}
                label={t('common:shared.phone')}
              >
                <PhoneNumber number={contact.get('number')} />
              </Detail>
            </Grid>
          </>
        ))
      }
    </Grid>
  )
}


ContactSection.propTypes = {
  list: PropTypes.instanceOf(List),
  loading: PropTypes.bool,
}

ContactSection.defaultProps = {
  list: new List(),
  loading: false,
}


export default ContactSection
