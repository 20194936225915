import React from 'react'
import {
  TableHeader, TableRow, PreviewLink, Flex, InlineTable, Chip,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { USER } from 'constants/resources'
import { Configure, connectHits, connectPagination } from 'react-instantsearch-dom'
import { Grid } from '@material-ui/core'
import { fromJS } from 'immutable'
import { USER_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'

function Employees({
  companyId,
  hits,
  refine,
  nbPages,
  currentRefinement,
  loading,
}) {
  const { t } = useTranslation(['common', USER])
  const templateColumns = '3fr 1fr'

  const list = fromJS(hits)

  const refineNext = () => {
    refine(currentRefinement + 1)
  }
  const refinePrevious = () => {
    refine(currentRefinement - 1)
  }

  const hasMore = currentRefinement < nbPages
  const hasPrevious = currentRefinement > 1

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Configure
          filters={`serialized_company.id:${companyId}`}
          hitsPerPage={5}
        />
        <InlineTable
          title={t('company:employeeList.header')}
          header={(
            <TableHeader
              fullSize
              templateColumns={templateColumns}
            >
              <p>{t('common:shared.name')}</p>
            </TableHeader>
          )}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
            list.map(user => (
              <TableRow
                key={user.get('objectID')}
                fullSize
                templateColumns={templateColumns}
              >
                <div>
                  <PreviewLink resource={USER} id={user.get('objectID')}>
                    {user.get('name')}
                  </PreviewLink>
                </div>
                <Flex justify="flex-end">
                  {user.get('is_owner') ? (
                    <Chip label={t('common:shared.owner')} />
                  ) : null}
                </Flex>
              </TableRow>
            ))
      }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

Employees.propTypes = {
  companyId: PropTypes.string,
  hits: PropTypes.array.isRequired,
  refine: PropTypes.func.isRequired,
  nbPages: PropTypes.number.isRequired,
  currentRefinement: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

Employees.defaultProps = {
  companyId: '',
  loading: false,
}

export default withAlgolia(USER_INDEX)(connectHits(connectPagination(Employees)))
