import { useEffect, useState } from 'react'
// import { parseQueryString } from 'utils'

function useAllRecords(service, filters) {
  const [list, setList] = useState([])
  const limit = 100

  useEffect(() => {
    const call = (nextFilters = {}) => {
      service({
        limit,
        ...filters,
        ...nextFilters,
      }).then((response) => {
        setList([
          ...list,
          ...response.results,
        ])
        // if (response.next) {
        //   const next = new URL(response.next)
        //   const nextParams = parseQueryString(next.search.substring(1))
        //   call(nextParams)
        // }
      })
    }

    call()
  }, [filters, list, service])

  return list
}


export default useAllRecords
