import {
  SET_CURRENT_SESSION,
  GET_CURRENT_SESSION,
  CREATE_SESSION,
  VERIFY_SESSION,
  SET_SESSION_TOKEN,
  SET_ALGOLIA_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from 'store/actions/sessions'
import { createAction } from './factory'

export default {
  getCurrent: createAction(GET_CURRENT_SESSION),
  setCurrent: createAction(SET_CURRENT_SESSION),
  create: createAction(CREATE_SESSION),
  verify: createAction(VERIFY_SESSION),
  setToken: createAction(SET_SESSION_TOKEN),
  setAlgoliaToken: createAction(SET_ALGOLIA_TOKEN),
  resetPassword: createAction(RESET_PASSWORD),
  changePassword: createAction(CHANGE_PASSWORD),
}
