import React from 'react'
import { NotificationContent, NotificationHeader, NotificationControls } from 'components/pages/notifications'
import { NOTIFICATION } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'

function NotificationTable() {
  const { t } = useTranslation(['common', NOTIFICATION])
  const columns = {
    sm: '2fr 1fr 0.5fr',
    md: '2fr 2fr 2fr 2fr 0.5fr',
    lg: '2fr 1fr 2fr 2fr 4fr 0.5fr',
    xl: '2fr 1fr 2fr 2fr 4fr 0.5fr',
  }

  return (
    <Table
      title={t('"common:resources.notifications.plural')}
      controls={<NotificationControls />}
      resource={NOTIFICATION}
      header={(
        <NotificationHeader
          columns={columns}
        />
          )}
      content={(
        <NotificationContent
          columns={columns}
        />
      )}
    />
  )
}

export default NotificationTable
