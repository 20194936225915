import React from 'react'
import { ORDER_SERVICE } from 'constants/resources'
import { useSelector } from 'react-redux'
import { TableDetail } from 'components/common'
import Form from './Form'

function New() {
  const current = useSelector(state => state.getIn([ORDER_SERVICE, 'create']))
  return (
    <TableDetail>
      <Form
        loading={current.get('loading')}
        current={current.get('data')}
      />
    </TableDetail>
  )
}

export default New
