import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: ${({ show }) => (show ? 2 : -1)};
  background-color: ${({ theme }) => ((theme?.color) ? theme.color.primary : 'white')};
  opacity: ${({ opacity }) => opacity};
  display: ${({ center }) => center && 'flex'};
  justify-content: ${({ center }) => center && 'center'};
  align-items: ${({ center }) => center && 'center'};
`

export default Overlay
