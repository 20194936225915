import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Chip, Flex, TableHeader, TableRow, PreviewLink, InlineTable, TableCell,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EQUIPMENT } from 'constants/resources'
import { usePaginatedList } from 'components/hooks'
import { list as listEquipment } from 'services/equipment'

function EquipmentList({ categoryId, onCount }) {
  const { t } = useTranslation(['common', EQUIPMENT])
  const templateColumns = '2fr 0.5fr 1fr'
  const options = useMemo(() => (categoryId ? ({
    category: categoryId,
    limit: 5,
  }) : null), [categoryId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listEquipment, options)
  useEffect(() => {
    if (onCount) onCount(count)
  }, [onCount, count])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('common:resources.equipment.singular')} (${count})`}
          header={(
            <TableHeader
              fullSize
              templateColumns={templateColumns}
            >
              <p>{t('common:shared.name')}</p>
              <p>{t('supplier:header.totalOrders')}</p>
            </TableHeader>
          )}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >

          {
          list.map(equipment => (
            <TableRow
              key={equipment.get('id')}
              fullSize
              templateColumns={templateColumns}
            >
              <TableCell sm>
                <PreviewLink resource={EQUIPMENT} id={equipment.get('id')}>
                  {equipment.get('name')}
                </PreviewLink>
              </TableCell>
              <TableCell sm>
                {equipment.get('number_of_orders')}
              </TableCell>
              <Flex justify="flex-end">
                {
                equipment.get('is_promoted') ? (
                  <Chip label={t('equipment:shared.promoted')} />
                ) : null
                }
              </Flex>
            </TableRow>
          ))
      }
        </InlineTable>
      </Grid>
    </Grid>
  )
}


EquipmentList.propTypes = {
  categoryId: PropTypes.string,
  onCount: PropTypes.func,
}

EquipmentList.defaultProps = {
  categoryId: '',
  onCount: null,
}


export default EquipmentList
