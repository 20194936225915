import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useResource } from 'components/hooks'

function useStoreAlgolia(resource, actions, results) {
  const dispatch = useDispatch()
  const list = useResource(resource, 'list')

  useEffect(() => {
    // if (results.length) {
    dispatch(actions.setListData(results))
    dispatch(actions.setListLoading(false))
    // }
  }, [actions, dispatch, results])

  return list
}

export default useStoreAlgolia
