import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { OrderServiceNew, OrderServiceEdit } from 'components/pages/order-service'
import { CompanyDetail, CompanyNew, CompanyEdit } from 'components/pages/companies'
import { NotificationDetail } from 'components/pages/notifications'
import { EquipmentDetail, EquipmentNew, EquipmentEdit } from 'components/pages/equipment'
import { OrderItemDetail, OrderItemNew, OrderItemEdit } from 'components/pages/order-items'
import { OrderDetail, OrderNew, OrderEdit } from 'components/pages/orders'
import { SupplierDetail, SupplierNew, SupplierEdit } from 'components/pages/suppliers'
import { UserDetail, UserNew, UserEdit } from 'components/pages/users'
import { CategoryDetail, CategoryNew, CategoryEdit } from 'components/pages/categories'
import { ServiceRequestDetail, ServiceRequestNew, ServiceRequestEdit } from 'components/pages/service-requests'
import { SupplierReviewNew } from 'components/pages/supplier-reviews'
import { useQuickPreview, useQuickPreviewHotkeys } from 'components/hooks'
import {
  COMPANY,
  EQUIPMENT,
  ORDER_ITEM,
  ORDER_SERVICE,
  ORDER,
  SERVICE_REQUEST,
  SUPPLIER,
  SUPPLIER_REVIEW,
  USER,
  CATEGORY,
  NOTIFICATION,
} from 'constants/resources'
import { NEW, SHOW, EDIT } from 'constants/crud'
import { Chip } from 'components/common'
import { useTranslation } from 'react-i18next'

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: ${({ show }) => (show ? 1 : -1)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  background-color: ${({ theme }) => theme.color.primary}E6;
  justify-content: center;
  transition: 200ms ease-in;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`

const Content = styled.div`
  cursor: default;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .overlay-action-button {
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`

function QuickPreview() {
  useQuickPreviewHotkeys()
  const overlay = useSelector(state => state.getIn(['application', 'overlay']))
  const [openPreview, closePreview] = useQuickPreview()
  const { t } = useTranslation('common')

  let content
  if (overlay.get('action') === NEW) {
    switch (overlay.get('resource')) {
      case COMPANY: content = <CompanyNew />; break
      case ORDER_SERVICE: content = <OrderServiceNew />; break
      case CATEGORY: content = <CategoryNew />; break
      case EQUIPMENT: content = <EquipmentNew />; break
      case ORDER_ITEM: content = <OrderItemNew />; break
      case ORDER: content = <OrderNew />; break
      case SUPPLIER: content = <SupplierNew />; break
      case SUPPLIER_REVIEW: content = <SupplierReviewNew />; break
      case USER: content = <UserNew />; break
      case SERVICE_REQUEST: content = <ServiceRequestNew />; break
      default: break
    }
  } else if (overlay.get('action') === SHOW) {
    switch (overlay.get('resource')) {
      case COMPANY: content = <CompanyDetail />; break
      case CATEGORY: content = <CategoryDetail />; break
      case EQUIPMENT: content = <EquipmentDetail />; break
      case ORDER_ITEM: content = <OrderItemDetail />; break
      case ORDER: content = <OrderDetail />; break
      case SUPPLIER: content = <SupplierDetail />; break
      case USER: content = <UserDetail />; break
      case SERVICE_REQUEST: content = <ServiceRequestDetail />; break
      case NOTIFICATION: content = <NotificationDetail />; break
      default: break
    }
  } else if (overlay.get('action') === EDIT) {
    switch (overlay.get('resource')) {
      case COMPANY: content = <CompanyEdit />; break
      case CATEGORY: content = <CategoryEdit />; break
      case EQUIPMENT: content = <EquipmentEdit />; break
      case ORDER_ITEM: content = <OrderItemEdit />; break
      case ORDER_SERVICE: content = <OrderServiceEdit />; break
      case ORDER: content = <OrderEdit />; break
      case SUPPLIER: content = <SupplierEdit />; break
      case USER: content = <UserEdit />; break
      case SERVICE_REQUEST: content = <ServiceRequestEdit />; break
      default: break
    }
  }

  const handleOverlayClick = () => {
    if (overlay.get('action') === SHOW) {
      closePreview(overlay.get('resource'), overlay.get('action'))
    }
  }

  const handleCloseClick = () => {
    closePreview(overlay.get('resource'), overlay.get('action'))
  }

  const handleEditButton = () => {
    openPreview(overlay.get('resource'), EDIT)
  }

  return (
    <Overlay
      show={overlay.get('active')}
      onClick={handleOverlayClick}
      clickable={overlay.get('action') === SHOW}
    >
      <Container style={{ height: '100%' }}>
        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
          <Grid item xs={12} md={5}>
            <Content
              onClick={(event) => {
                event.stopPropagation()
              }}
              style={{ zIndex: 2 }}
            >
              {
                content
              }
              {
                overlay.get('action') === SHOW ? (
                  <Chip
                    className="overlay-action-button"
                    outline
                    icon="edit"
                    onClick={handleEditButton}
                    label={t('shared.edit')}
                  />
                ) : (
                  <Chip
                    className="overlay-action-button"
                    outline
                    icon="clear"
                    onClick={handleCloseClick}
                    label={t('shared.close')}
                  />
                )
              }
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Overlay>
  )
}

export default QuickPreview
