import React from 'react'
import styled from 'styled-components'
import MaterialTextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

const TextFieldStyled = styled(MaterialTextField)`
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.color.secondary500};
  }
  .MuiFormLabel-root.Mui-disabled {
    color: ${({ theme }) => theme.color.secondary500}80;
  }
  .MuiTextField-root, .Mui-focused, .MuiInputBase-input {
    color: ${({ theme }) => theme.color.secondary} !important;
  }
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.color.secondary500}80 !important;
  }
  .Mui-error {
    color: ${({ theme }) => theme.color.accent} !important;
  }
  .MuiFilledInput-root {
    background-color: transparent;
  }
  .MuiInput-underline {
    &:after, &:before {
      border-bottom: 1px solid ${({ theme }) => theme.color.secondary}88;
    }
  }
  .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none; 
  }
  .MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
    border-bottom: none; 
  }
  .MuiInputAdornment-root {
    * {
      color: ${({ theme }) => theme.color.secondary500};
    }
  }
  .MuiChip-root {
    background-color: ${({ theme }) => theme.color.primary300};
  }
`

function TextField({
  shrink,
  label,
  id,
  value,
  defaultValue,
  onChange,
  onFileChange,
  type,
  forwardRef,
  mask,
  placeholder,
  ...otherProps
}) {
  const handleChange = (event) => {
    if (onFileChange) {
      onFileChange(event)
    }
    if (onChange) {
      const val = (type === 'number') ? Number(event.target.value) : event.target.value
      onChange(val)
    }
    return event
  }

  const shrinkProps = shrink ? { shrink: true } : {}

  if (mask) {
    return (
      <InputMask
        mask={mask}
        maskChar=" "
        value={value}
        onChange={handleChange}
        {...otherProps}
      >
        {inputProps => (
          <TextFieldStyled
            className="masked-input"
            inputRef={forwardRef}
            type={type}
            placeholder={placeholder}
            id={id}
            label={label}
            variant="filled"
            defaultValue={defaultValue}
            InputLabelProps={{ ...shrinkProps }}
            {...inputProps}
          />
        )}
      </InputMask>
    )
  }
  return (
    <TextFieldStyled
      type={type}
      placeholder={placeholder}
      id={id}
      label={label}
      variant="filled"
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      inputRef={forwardRef}
      InputLabelProps={{ ...shrinkProps }}
      {...otherProps}
    />
  )
}

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  forwardRef: PropTypes.any,
  mask: PropTypes.string,
  shrink: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
}

TextField.defaultProps = {
  shrink: true,
  onChange: null,
}

export default TextField
