import React from 'react'
import { useCurrent, useResource } from 'components/hooks'
import { USER } from 'constants/resources'
import { Divider } from 'components/ui'
import { Destroy, TableDetail } from 'components/common'
import Header from './detail/Header'
import Information from './detail/Information'
import ServiceCallList from './detail/ServiceCallList'
import AssignedCompanies from './detail/AssignedCompanies'

function UserDetail() {
  const selected = useCurrent(USER)
  const loading = useResource(USER, 'current', 'loading')

  return (
    <TableDetail>
      <Header
        isOwner={selected.get('is_owner')}
        isAdmin={selected.get('is_admin')}
        isActive={selected.get('is_active')}
        loading={loading}
        email={selected.get('email')}
        title={selected.get('name')}
      />
      <Divider spacing={28} />
      <Information
        name={selected.get('name')}
        companyName={selected.getIn(['company', 'name'])}
        companyId={selected.getIn(['company', 'id'])}
        email={selected.get('email')}
        contactNumber={selected.get('contact_number')}
        loading={loading}
      />
      {
        selected.get('is_admin') ? (
          <>
            <Divider spacing={20} />
            <AssignedCompanies
              userId={selected.get('id')}
            />
            <Divider spacing={20} />
            <ServiceCallList
              userId={selected.get('id')}
            />
          </>
        ) : null
      }
      <Destroy resource={USER} id={selected.get('id')} />
    </TableDetail>
  )
}


export default UserDetail
