import React from 'react'
import Noty from './noty'
import GlobalStyle from './global'
import ResetStyle from './reset'
import NProgress from './nprogress'
import MaterialUIOverride from './material-ui-override'

const BaseStyles = () => (
  <>
    <ResetStyle />
    <GlobalStyle />
    <Noty />
    <NProgress />
    <MaterialUIOverride />
  </>
)

export default BaseStyles
