import React from 'react'
import { TableControls } from 'components/common'
import { useTranslation } from 'react-i18next'
import NotificationSwitch from './NotificationSwitch'

function NotificationControls(props) {
  const { t } = useTranslation('common')

  return (
    <TableControls
      title={t('resources.notifications.plural')}
      {...props}
    >
      <NotificationSwitch />
    </TableControls>
  )
}

export default NotificationControls
