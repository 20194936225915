import React, { useEffect, useState } from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import {
  TableRow, TableLoading, SectionHeader,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import Status from 'components/pages/service-requests/Status'
import { Icon, Tooltip } from 'components/ui'
import { DATETIME } from 'constants/formats'
import moment from 'moment'

function UpdatesHistory({
  stateHistory, statusHistory, loading,
}) {
  const [ordered, setOrdered] = useState(new List())
  const { t } = useTranslation(['common', SERVICE_REQUEST])
  const templateColumns = '2fr 4fr 0.5fr'

  useEffect(() => {
    if (stateHistory.size || statusHistory.size) {
      const records = stateHistory.concat(statusHistory)
      const orderedList = records.sort((a, b) => {
        if (a.get('timestamp') > b.get('timestamp')) { return -1 }
        if (a.get('timestamp') < b.get('timestamp')) { return 1 }
        if (a.get('timestamp') === b.get('timestamp')) { return 0 }
        return 0
      })
      setOrdered(orderedList)
    }
  }, [stateHistory, statusHistory])

  if (!stateHistory.size && !statusHistory.size) return null

  return (
    <div>
      <SectionHeader title={t('serviceRequest:timeline.header')} fullSize />
      <TableLoading loading={loading} rows={3} fullSize />
      {
          (ordered && !loading) ? ordered.map(state => (
            <TableRow
              key={state.get('timestamp')}
              fullSize
              height="auto"
              templateColumns={templateColumns}
            >
              <span>{moment(state.get('timestamp')).from(moment())}</span>
              {
                state.get('new_value')
                  ? <Status defaultValue={state.get('new_value')} disabled />
                  : <p style={{ whiteSpace: 'pre-wrap' }}>{state.get('description')}</p>
              }
              <Tooltip title={(
                <span>
                  {state.get('actor_name')}
                  <br />
                  {state.get('timestamp') && moment(state.get('timestamp')).format(DATETIME)}
                </span>
              )}
              >
                <span style={{ textAlign: 'center' }}><Icon name="person" size={16} /></span>
              </Tooltip>
            </TableRow>
          )) : null
      }
    </div>
  )
}

UpdatesHistory.propTypes = {
  stateHistory: PropTypes.instanceOf(List),
  statusHistory: PropTypes.instanceOf(List),
  loading: PropTypes.bool,
}

UpdatesHistory.defaultProps = {
  stateHistory: new List(),
  statusHistory: new List(),
  loading: false,
}


export default UpdatesHistory
