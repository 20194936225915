import { useSelector, useDispatch } from 'react-redux'
import actions from 'store/action-creators'
import { useEffect } from 'react'

function useResourceList(resource, filters) {
  const dispatch = useDispatch()
  const list = useSelector(state => state.getIn([resource, 'list', 'data']))
  const isAuth = useSelector(state => state.getIn(['session', 'token']))
  useEffect(() => {
    if (isAuth) dispatch(actions[resource].getListReset(filters))
  }, [dispatch, filters, isAuth, resource])
  return list
}

export default useResourceList
