import React from 'react'
import {
  TableRow, TableCell, PreviewLink, FacetFilter,
} from 'components/common'
import PropTypes from 'prop-types'
import { COMPANY, USER } from 'constants/resources'

function CompanyRow({
  name,
  salesRepName,
  salesRepId,
  ownerName,
  ownerId,
  onRent,
  totalOrders,
  active,
  onClick,
  templateColumns,
  isRedacted,
  totalUsers,
  shrink,
}) {
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick} disabled={isRedacted}>
      <TableCell sm>
        <p>{name}</p>
      </TableCell>
      <TableCell md>
        <PreviewLink
          hideMobile
          resource={USER}
          id={ownerId}
        >
          {ownerName}
        </PreviewLink>
      </TableCell>
      <TableCell md>
        <p>{totalUsers}</p>
      </TableCell>
      <TableCell sm>
        <p>{onRent}</p>
      </TableCell>
      <TableCell md>
        <p>{totalOrders}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_sales_rep.id',
            value: salesRepId,
            label: salesRepName,
          }}
          resource={COMPANY}
        >
          <PreviewLink
            hideMobile
            resource={USER}
            id={salesRepId}
          >
            {salesRepName}
          </PreviewLink>
        </FacetFilter>

      </TableCell>
    </TableRow>
  )
}

CompanyRow.propTypes = {
  name: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  ownerId: PropTypes.string,
  ownerName: PropTypes.string,
  onRent: PropTypes.number,
  totalOrders: PropTypes.number,
  totalUsers: PropTypes.number,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  isRedacted: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
}

CompanyRow.defaultProps = {
  name: '',
  salesRepName: '',
  salesRepId: '',
  ownerId: '',
  ownerName: '',
  onRent: 0,
  totalOrders: 0,
  totalUsers: 0,
  active: false,
  shrink: false,
  isRedacted: false,
  onClick: null,
}

export default CompanyRow
