import { useState, useEffect, useCallback } from 'react'
import { parseQueryString } from 'utils'
import { List, fromJS } from 'immutable'

function usePaginatedList(service, initialOptions) {
  const [list, setList] = useState(new List())
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [nextParams, setNextParams] = useState({})
  const [previousParams, setPreviousParams] = useState({})

  const fetchService = useCallback((options) => {
    setLoading(true)
    service(options)
      .then(({
        next, previous, results, count: total,
      }) => {
        const nextObj = next ? parseQueryString(new URL(next).search.substring(1)) : null
        const previousObj = previous ? parseQueryString(new URL(previous).search.substring(1)) : null
        setCount(total)
        setNextParams(nextObj)
        setPreviousParams(previousObj)
        setList(fromJS(results))
        setLoading(false)
      })
  }, [service])

  useEffect(() => {
    if (initialOptions) fetchService(initialOptions)
  }, [fetchService, initialOptions])

  const hasMore = !!nextParams

  const refineNext = useCallback(() => {
    fetchService(nextParams)
  },
  [fetchService, nextParams])

  const hasPrevious = !!previousParams

  const refinePrevious = useCallback(() => {
    fetchService(previousParams)
  },
  [fetchService, previousParams])

  return {
    hasMore, refineNext, hasPrevious, refinePrevious, list, loading, count,
  }
}

export default usePaginatedList
