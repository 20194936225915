import {
  put,
  select,
  call,
  takeLatest,
} from 'redux-saga/effects'
import {
  acknowledge as acknowledgeService,
} from 'services/notifications'
import { NOTIFICATION } from 'constants/resources'
import {
  LIST,
  LIST_RESET,
  GET,
} from 'store/actions/factory'
import { ACKNOWLEDGE_NOTIFICATION } from 'store/actions/notifications'
import { notifications, application } from 'store/action-creators'
import nprogress from './nprogress'
import {
  list, resetList, get,
} from './factory'

function* acknwoledge({ payload }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  const response = yield call(acknowledgeService, id)
  if (response) {
    yield put(notifications.setCurrentData(response))
    yield put(notifications.removeListItem(id))
    const unread = yield select(state => state.getIn([NOTIFICATION, 'list', 'data']))
    if (!unread.length) yield put(application.setNotification(false))
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
}

export default function* notificationSaga() {
  yield takeLatest(LIST(NOTIFICATION), list)
  yield takeLatest(LIST_RESET(NOTIFICATION), resetList)
  yield takeLatest(GET(NOTIFICATION), get)
  yield takeLatest(ACKNOWLEDGE_NOTIFICATION, acknwoledge)
}
