import React, { useState } from 'react'
import { Menu } from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  CREATED, ACCEPTED, CANCELLED, FINALIZED, SCHEDULED, ON_RENT, OFF_RENT,
} from 'constants/status'
import PropTypes from 'prop-types'
import { useStatusColors } from 'components/hooks'
import { ORDER_ITEM } from 'constants/resources'
import { orderItems } from 'store/action-creators'
import { useDispatch } from 'react-redux'

function Status({
  id, defaultValue, disabled, onChange, ...otherProps
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation([ORDER_ITEM, 'error'])
  const color = useStatusColors(ORDER_ITEM)
  const [selected, setSelected] = useState(defaultValue)
  const canEdit = ![CANCELLED, FINALIZED].includes(defaultValue)

  const handleUpdateSuccess = (response) => {
    setSelected(response.status)
  }

  const handleChange = (status) => {
    if (onChange) {
      onChange(status)
      setSelected(status)
    } else {
      let confirmed = true
      if ([CANCELLED, FINALIZED].includes(status)) {
        confirmed = window.confirm(t('error:status.warning'))
      }
      if (confirmed) {
        setSelected(status)
        dispatch(orderItems.setCurrentId(id))
        dispatch(orderItems.update(
          { status },
          handleUpdateSuccess,
        ))
      }
    }
  }

  return (
    <Menu
      {...otherProps}
      value={selected}
      disabled={disabled || !canEdit}
      options={[
        { label: t(`status.${CREATED}`), value: CREATED, color: color[CREATED] },
        { label: t(`status.${ACCEPTED}`), value: ACCEPTED, color: color[ACCEPTED] },
        { label: t(`status.${SCHEDULED}`), value: SCHEDULED, color: color[SCHEDULED] },
        { label: t(`status.${ON_RENT}`), value: ON_RENT, color: color[ON_RENT] },
        { label: t(`status.${OFF_RENT}`), value: OFF_RENT, color: color[OFF_RENT] },
        { label: t(`status.${FINALIZED}`), value: FINALIZED, color: color[FINALIZED] },
        { label: t(`status.${CANCELLED}`), value: CANCELLED, color: color[CANCELLED] },
      ]}
      onChange={handleChange}
    />
  )
}

Status.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
}

Status.defaultProps = {
  defaultValue: '',
  disabled: false,
  id: '',
}


export default Status
