import {
  takeLatest,
} from 'redux-saga/effects'
import { USER } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  list, get, create, update, destroy,
} from './factory'


export default function* userSaga() {
  yield takeLatest(LIST(USER), list)
  yield takeLatest(GET(USER), get)
  yield takeLatest(CREATE(USER), create)
  yield takeLatest(UPDATE(USER), update)
  yield takeLatest(DELETE(USER), destroy)
}
