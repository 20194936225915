import { fork } from 'redux-saga/effects'
import sessions from './sessions'
import companies from './companies'
import categories from './categories'
import equipment from './equipment'
import notifications from './notifications'
import orders from './orders'
import orderItems from './order-items'
import orderServices from './order-services'
import serviceRequests from './service-requests'
import reservations from './reservations'
import reservationItems from './reservation-items'
import suppliers from './suppliers'
import supplierReviews from './supplier-reviews'
import users from './users'
import navigation from './navigation'

export default function* rootSaga() {
  yield fork(sessions)
  yield fork(companies)
  yield fork(categories)
  yield fork(equipment)
  yield fork(notifications)
  yield fork(orders)
  yield fork(orderItems)
  yield fork(orderServices)
  yield fork(serviceRequests)
  yield fork(reservations)
  yield fork(reservationItems)
  yield fork(suppliers)
  yield fork(supplierReviews)
  yield fork(users)
  yield fork(navigation)
}
