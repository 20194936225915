import React from 'react'
import {
  TableControls, AlgoliaSearch, ActiveFilters,
} from 'components/common'
import { orders } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { ORDER } from 'constants/resources'
import Hidden from '@material-ui/core/Hidden'
import Filters from './Filters'

function OrderControls(props) {
  const dispatch = useDispatch()
  const handleSearchChange = (value) => {
    dispatch(orders.setListSearchQuery(value))
  }
  return (
    <TableControls
      {...props}
    >
      <Hidden xsDown>
        <ActiveFilters resource={ORDER} />
        <Filters />
      </Hidden>
      <AlgoliaSearch onChange={handleSearchChange} />
    </TableControls>
  )
}

export default OrderControls
