import React from 'react'
import {
  Input, AddressFields, Button, Flex,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import { EquipmentSelect } from 'components/pages/equipment'
import { CompanySelect } from 'components/pages/companies'
import useMultipleContacts from 'components/hooks/useMultipleContacts'

function Form({ current, loading, update }) {
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register, getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      designation: 'order',
      metadata: {
        company: current.getIn(['metadata', 'company']),
        equipment: current.getIn(['metadata', 'equipment']),
        duration: current.getIn(['metadata', 'duration']),
        delivery_date: current.getIn(['metadata', 'delivery_date']),
        price_given: current.getIn(['metadata', 'price_given']),
        transport_price_given: current.getIn(['metadata', 'transport_price_given']),
        purchase_order: current.getIn(['metadata', 'purchase_order']),
        current_supplier: current.getIn(['metadata', 'current_supplier']),
      },
      state: current.get('state'),
      assignee: current.getIn(['assignee', 'id']),
      notes: current.get('notes'),
      address_line1: current.get('address_line1'),
      address_line2: current.get('address_line2'),
      address_zip: current.get('address_zip'),
      address_city: current.get('address_city'),
      address_state: current.get('address_state'),
      address_country: current.getIn(['address_country', 'code']),
      contacts: current.getIn(['metadata', 'contacts'])?.map(contact => contact.toJS()),
    },
  })
  const {
    fields, onBlur, cleanContacts,
  } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues,
  })

  const onSubmit = (body) => {
    if (update) {
      dispatch(serviceRequests.update(cleanContacts(body)))
    } else {
      dispatch(serviceRequests.create(cleanContacts(body)))
    }
  }


  const handleChange = (results) => {
    const [value] = results
    return (value && value.label) || value
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="designation" value="order" ref={register} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={CompanySelect}
            name="metadata.company"
            error={errors?.metadata?.company?.message}
            defaultValue={current.getIn(['metadata', 'company'])}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:company.required'),
              },
            }}
            required
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            onChange={handleChange}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={EquipmentSelect}
            name="metadata.equipment"
            defaultValue={current.getIn(['metadata', 'equipment'])}
            error={errors?.metadata?.equipment?.message}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:equipment.required'),
              },
            }}
            required
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            onChange={handleChange}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.assignee?.message}
            name="assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
            disabled={loading || !update}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-service-request-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.duration"
            error={errors.metadata?.duration?.message}
            defaultValue={current.getIn(['metadata', 'duration'])}
            type="text"
            required
            rules={{
              required: {
                value: true,
                message: t('error:duration.required'),
              },
            }}
            label={t('serviceRequest:shared.duration')}
            placeholder={t('serviceRequest:new.duration.placeholder')}
            id="new-service-request-duration"
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.delivery_date"
            error={errors.metadata?.delivery_date?.message}
            defaultValue={current.getIn(['metadata', 'delivery_date'])}
            type="text"
            label={t('serviceRequest:shared.deliveryDate')}
            placeholder={t('serviceRequest:new.deliveryDate.placeholder')}
            id="new-service-request-deliveryDate"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="text"
            name="metadata.price_given"
            error={errors.metadata?.price_given?.message}
            defaultValue={current.getIn(['metadata', 'price_given'])}
            control={control}
            label={t('serviceRequest:shared.priceGiven')}
            placeholder={t('serviceRequest:new.priceGiven.placeholder')}
            id="new-service-request-priceGiven"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="text"
            control={control}
            name="metadata.transport_price_given"
            defaultValue={current.getIn(['metadata', 'transport_price_given'])}
            error={errors.metadata?.transport_price_given?.message}
            label={t('serviceRequest:shared.transportPriceGiven')}
            placeholder={t('serviceRequest:new.transportPriceGiven.placeholder')}
            id="new-service-request-transportPriceGiven"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.purchase_order"
            error={errors.metadata?.purchase_order?.message}
            defaultValue={current.getIn(['metadata', 'purchase_order'])}
            type="text"
            label={t('serviceRequest:shared.purchaseOrder')}
            placeholder={t('serviceRequest:new.purchaseOrder.placeholder')}
            id="new-service-request-purchaseOrder"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.current_supplier"
            error={errors.metadata?.current_supplier?.message}
            defaultValue={current.getIn(['metadata', 'current_supplier'])}
            type="text"
            label={t('serviceRequest:shared.currentSupplier')}
            id="new-service-request-currentSupplier"
            placeholder={t('serviceRequest:new.currentSupplier.placeholder')}
            disabled={loading}
          />
        </Grid>
        {
          fields.map(i => (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].name`}
                  control={control}
                  error={errors.metadata?.contacts?.[i]?.name?.message}
                  type="text"
                  label={t('order:shared.siteContact')}
                  placeholder={t('order:new.siteContact.placeholder')}
                  id="new-service-request-contact-name"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].number`}
                  control={control}
                  error={errors.metadata?.contacts?.[i]?.number?.message}
                  type="tel"
                  label={t('common:shared.contactNumber')}
                  id="new-service-request-contact-number"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
            </>
          ))
        }

        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
          streetRequired
          zipcodeRequired
          cityRequired
          stateRequired
          countryRequired
        />
        {
          update ? (
            <Grid item xs={12} md={12}>
              <Controller
                as={Input}
                control={control}
                error={errors.state?.message}
                name="state"
                type="text"
                multiline
                label={t('common:shared.status')}
                placeholder={t('serviceRequest:new.state.placeholder')}
                id="new-service-request-state"
                defaultValue={current.get('state')}
                disabled={loading}
              />
            </Grid>
          ) : null
        }
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            multiline
            label={t('common:shared.notes')}
            placeholder={t('serviceRequest:new.notes.placeholder')}
            id="new-service-request-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('serviceRequest:edit.submitRequest') : t('serviceRequest:new.submitRequest')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
