import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/orders/services/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/orders/services/${id}/`)
)

export const create = async ({
  rate,
  order,
  description,
}) => (
  api.post('/app/orders/services/', {
    rate,
    order,
    description,
  })
)

export const update = async (id, {
  rate,
  // order,
  description,
}) => (
  api.patch(`/app/orders/services/${id}/`, {
    rate,
    // order,
    description,
  })
)

export const destroy = async id => (
  api.delete(`/app/orders/services/${id}/`)
)
