import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, PreviewLink, Date, SectionHeader, Price,
} from 'components/common'
import {
  COMPANY, USER, SERVICE_REQUEST, EQUIPMENT,
} from 'constants/resources'

function Information({
  assigneeName,
  assigneeId,
  salesRepName,
  salesRepId,
  createdByName,
  createdById,
  notes,
  code,
  internalCode,
  companyName,
  companyId,
  equipmentName,
  equipmentId,
  budget,
  equipmentYear,
  equipmentState,
  equipmentModel,
  createdAt,
  loading,
  id,

  resolveBy,
  status,
  designation,

  action,
  title,

}) {
  const { t } = useTranslation(['common', SERVICE_REQUEST])

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={createdByName}
          loading={loading}
          label={t('common:shared.createdBy')}
        >
          <PreviewLink id={createdById} resource={USER}>
            {createdByName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={createdAt}
          loading={loading}
          label={t('common:shared.createdAt')}
        >
          <Date variant="datetime">
            {createdAt}
          </Date>
        </Detail>
      </Grid>

      <Grid item xs={12} md={6}>
        <Detail
          text={internalCode}
          loading={loading}
          label={t('common:shared.id')}
        >
          {internalCode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={code}
          loading={loading}
          label={t('common:shared.publicCode')}
        >
          {code}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          {
            <PreviewLink id={companyId} resource={COMPANY}>
              {companyName}
            </PreviewLink>
          }
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={assigneeName}
          loading={loading}
          label={t('serviceRequest:shared.handledBy')}
        >
          <PreviewLink id={assigneeId} resource={USER}>
            {assigneeName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('shared.salesRep')}
          empty={t('company:shared.emptySalesRep')}
        >
          <PreviewLink
            resource={USER}
            id={salesRepId}
          >
            {salesRepName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={equipmentName}
          loading={loading}
          label={t('common:resources.equipment.singular')}
        >
          {
            <PreviewLink id={equipmentId} resource={EQUIPMENT}>
              {equipmentName.split(',').map(machine => (<p>{machine}</p>))}
            </PreviewLink>
          }
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={budget}
          loading={loading}
          label={t('serviceRequest:shared.budget')}
        >
          <Price amount={budget} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={equipmentYear}
          loading={loading}
          label={t('serviceRequest:shared.year')}
        >
          {equipmentYear}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={equipmentState}
          loading={loading}
          label={t('serviceRequest:shared.equipmentState')}
        >
          {equipmentState}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={equipmentModel}
          loading={loading}
          label={t('serviceRequest:shared.model')}
        >
          {equipmentModel}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.description')}
        >
          {notes}
        </Detail>
      </Grid>
    </Grid>
  )
}


Information.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  code: PropTypes.string,
  createdAt: PropTypes.string,
  resolveBy: PropTypes.string,
  status: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  createdByName: PropTypes.string,
  createdById: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
  designation: PropTypes.string,
  budget: PropTypes.number,
  equipmentYear: PropTypes.number,
  equipmentState: PropTypes.string,
  equipmentModel: PropTypes.string,
  internalCode: PropTypes.string,
  id: PropTypes.string,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
}


Information.defaultProps = {
  action: null,
  title: '',
  code: '',
  createdAt: '',
  resolveBy: '',
  status: '',
  companyName: '',
  companyId: '',
  assigneeName: '',
  assigneeId: '',
  salesRepName: '',
  salesRepId: '',
  createdByName: '',
  createdById: '',
  notes: '',
  loading: false,
  designation: '',
  budget: null,
  equipmentYear: null,
  equipmentState: '',
  equipmentModel: '',
  internalCode: '',
  id: '',
  equipmentName: '',
  equipmentId: '',
}

export default Information
