import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/ui/Icon'
import ChipUI from 'components/ui/Chip'
import styled from 'styled-components'

const ChipStyled = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.xsmall}px;
  &:last-child {
    margin: 0;
  }
`
function Chip({
  icon, label, ...otherProps
}) {
  return (
    <ChipStyled>
      <ChipUI type="button" {...otherProps}>
        {icon ? <Icon name={icon} size={14} /> : null}
        {label.replace(/-/g, ' ')}
      </ChipUI>
    </ChipStyled>
  )
}

Chip.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
}

Chip.defaultProps = {
  icon: '',
  label: '',
}

export default Chip
