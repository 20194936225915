import { useContext, useCallback } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { application } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import useNotification from './use-notification'

export default function useTheme() {
  const dispatch = useDispatch()
  const notify = useNotification()
  const theme = useContext(ThemeContext) || {}
  const { t } = useTranslation('common')
  const cookies = new Cookies()

  const setTheme = useCallback(
    (value) => {
      dispatch(application.setTheme(value))
      cookies.set('theme', value)
      notify({ text: t(`themes.change.${value}`) })
    }, [cookies, dispatch, notify, t],
  )

  return [theme, setTheme]
}
