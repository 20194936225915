import React from 'react'
import { Chip } from 'components/common'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ORDER } from 'constants/resources'
import { useStatusColors } from 'components/hooks'

function Status({ value }) {
  const { t } = useTranslation(ORDER)
  const color = useStatusColors(ORDER)

  return (
    <Chip
      label={t(`status.${value}`)}
      background={color[value]}
    />
  )
}

Status.propTypes = {
  value: PropTypes.string,
}

Status.defaultProps = {
  value: '',
}


export default Status
