import React from 'react'
import PropTypes from 'prop-types'
import { CollapsibleOrderItemDetail } from 'components/pages/order-items'
import { List } from 'immutable'

function Items({ list, selected, onClick }) {
  return (
    <div>
      {
       list.map(orderItem => (
         <CollapsibleOrderItemDetail
           key={orderItem.get('id')}
           selected={orderItem}
           open={selected === orderItem.get('id')}
           onClick={onClick}
         />
       ))
     }
    </div>
  )
}

Items.propTypes = {
  list: PropTypes.instanceOf(List),
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

Items.defaultProps = {
  list: new List(),
}


export default Items
