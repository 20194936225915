import React from 'react'
import { AdminUserContent, AdminHeader, UserControls } from 'components/pages/users'
import { withAlgolia } from 'components/hocs'
import { USER } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import { Link } from 'react-router-dom'
import {
  USER_INDEX,
  USER_LASTSESSION_ASC_INDEX,
  USER_LASTSESSION_DESC_INDEX,
} from 'constants/algolia'
import routes from 'constants/routes'

function UserTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1fr 1fr',
    md: '1fr 1fr 1fr',
    lg: '1fr 1fr 1fr',
    xl: '1fr 1fr 1fr',
  }

  return (
    <Table
      title={t('shared.admin')}
      subtitle={<Link to={routes.users}><h1>{t('resources.users.plural')}</h1></Link>}
      controls={<UserControls />}
      resource={USER}
      header={(
        <AdminHeader
          columns={columns}
          defaultRefinement={USER_INDEX}
          items={[
            USER_INDEX,
            USER_LASTSESSION_ASC_INDEX,
            USER_LASTSESSION_DESC_INDEX,
          ]}
        />
        )}
      content={(
        <AdminUserContent
          columns={columns}
        />
      )}
    />
  )
}

export default withAlgolia(USER_INDEX)(UserTable)
