import React from 'react'
import { DateFilter, QuickFilters } from 'components/common'
import { ORDER } from 'constants/resources'
import { ACTIVE, CREATED } from 'constants/status'
import { useTranslation } from 'react-i18next'


function Filters() {
  const { t } = useTranslation(ORDER)
  return (
    <>
      <QuickFilters
        resource={ORDER}
        options={[
          {
            value: [
              {
                attribute: 'status',
                value: ACTIVE,
                label: t(`status.${ACTIVE}`),
              },
              {
                attribute: 'status',
                value: CREATED,
                label: t(`status.${CREATED}`),
              },
            ],
            label: t('filters.current'),
          },
        ]}
      />
      <div>
        <DateFilter
          resource={ORDER}
          id="start_date_timestamp"
          attribute="start_date_timestamp"
          label="Start date"
          icon="today"
        />
      </div>
      <div>
        <DateFilter
          resource={ORDER}
          id="billing_date_timestamp"
          attribute="billing_date_timestamp"
          label="Billing date"
          icon="event"
        />
      </div>
    </>
  )
}

export default Filters
