import {
  takeLatest,
} from 'redux-saga/effects'
import {
  SET_NAVIGATION_QUERY,
  DELETE_NAVIGATION_QUERY,
} from 'store/actions/navigation'


export function* setQueryString({ payload }) {
  const { body } = payload
  const queryParams = Object.entries(body)
  const query = yield new URLSearchParams(window.location.search)
  queryParams.forEach(([param, value]) => {
    query.set(param, value)
  })
  window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`)
}

export function* removeQueryString({ payload }) {
  const { body: param } = payload
  const query = yield new URLSearchParams(window.location.search)
  query.delete(param)
  window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`)
}

export default function* filters() {
  yield takeLatest(SET_NAVIGATION_QUERY, setQueryString)
  yield takeLatest(DELETE_NAVIGATION_QUERY, removeQueryString)
}
