import React from 'react'
import { DetailHeader } from 'components/common'
import PropTypes from 'prop-types'
import PromoteButton from './PromoteButton'

function Header({ isPromoted, title, loading }) {
  return (
    <DetailHeader title={title} loading={loading}>
      <PromoteButton
        value={isPromoted}
      />
    </DetailHeader>
  )
}
Header.propTypes = {
  isPromoted: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
}

Header.defaultProps = {
  isPromoted: false,
  loading: false,
  title: '',
}


export default Header
