import React from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { orders, orderServices } from 'store/action-creators'
import { Flex, SectionHeader } from 'components/common'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ORDER_SERVICE } from 'constants/resources'
import { Icon } from 'components/ui'
import { useQuickPreview } from 'components/hooks'
import { useDispatch } from 'react-redux'
import { EDIT } from 'constants/crud'

function Items({ list, order }) {
  const { t } = useTranslation(ORDER_SERVICE)
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleServiceEdit = (service) => {
    openPreview(ORDER_SERVICE, EDIT)
    dispatch(orderServices.setCurrentId(service.get('id')))
    dispatch(orderServices.setCurrentData(service))
  }
  const handleServiceDelete = (id) => {
    dispatch(orderServices.delete(
      id,
      () => {
        const serviceIndex = order.get('services').findIndex(value => value.get('id') === id)
        const newOrder = order.deleteIn(['services', serviceIndex])
        dispatch(orders.setCurrentData(newOrder))
      },
    ))
  }
  if (!list.size) return null

  return (
    <Grid container spacing={2}>
      <SectionHeader title={t('orderService:shared.additionalServices')} />
      {
          list.map(orderService => (
            <React.Fragment key={orderService.get('id')}>
              <Grid item xs={6} md={6}>
                <p>
                  {orderService.get('description')}
                </p>
              </Grid>
              <Grid item xs={4} md={4}>
                <p>
                  <span>$</span>
                  {orderService.get('rate')}
                </p>
              </Grid>
              <Grid item xs={1} md={1}>
                <Flex justify="flex-end">
                  <Icon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleServiceEdit(orderService)}
                    name="edit"
                    size={16}
                  />
                </Flex>
              </Grid>
              <Grid item xs={1} md={1}>
                <Flex justify="flex-end">
                  <Icon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleServiceDelete(orderService.get('id'))}
                    name="delete"
                    size={16}
                  />
                </Flex>
              </Grid>
            </React.Fragment>
          ))
     }
    </Grid>
  )
}

Items.propTypes = {
  list: PropTypes.instanceOf(List),
  order: PropTypes.instanceOf(Map),
}

Items.defaultProps = {
  list: new List(),
  order: new Map(),
}


export default Items
