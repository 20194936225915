import React from 'react'
import { Link, Icon } from 'components/ui'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const StyledPagination = styled.div`
  height: 50px;
  display: flex; 
  justify-content: space-between;
  align-items: center;
  .arrow {
    display: flex;
    align-items: center;
  }
`

function Pagination({
  onNext,
  onPrevious,
  hasNext,
  hasPrevious,
}) {
  const { t } = useTranslation('common')
  if (!hasNext && !hasPrevious) return null

  return (
    <StyledPagination>
      {
        hasPrevious ? (
          <div className="arrow previous-page">
            <Icon name="arrow_back" size={16} />
            <Link onClick={onPrevious}>
              <span>{t('common:shared.previous')}</span>
            </Link>
          </div>
        ) : <span>{' '}</span>
      }
      {
        hasNext ? (
          <div className="arrow next-page">
            <Link onClick={onNext}>
              <span>{t('common:shared.next')}</span>
            </Link>
            <Icon name="arrow_forward" size={16} />
          </div>
        ) : <span>{' '}</span>
      }
    </StyledPagination>
  )
}

Pagination.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  hasNext: PropTypes.bool,
  hasPrevious: PropTypes.bool,
}

Pagination.defaultProps = {
  onNext: null,
  onPrevious: null,
  hasNext: false,
  hasPrevious: false,
}


export default Pagination
