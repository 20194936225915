import React from 'react'
import { SearchField } from 'components/common'
import { connectSearchBox } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

function AlgoliaSearch({
  currentRefinement, refine, onChange,
}) {
  const handleChange = (value) => {
    if (onChange) onChange(value)
    refine(value)
  }

  const handleClear = () => {
    refine('')
  }

  return (
    <SearchField
      value={currentRefinement}
      onChange={handleChange}
      onClear={handleClear}
    />
  )
}

AlgoliaSearch.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  onChange: PropTypes.func,
}

AlgoliaSearch.defaultProps = {
  onChange: null,
}

export default connectSearchBox(AlgoliaSearch)
