import React from 'react'
import { OrderItemTable, OrderItemDetail } from 'components/pages/order-items'
import { TableLayout } from 'components/layouts'
import { ORDER_ITEM } from 'constants/resources'

function OrderItems() {
  return (
    <TableLayout
      disableNew
      resource={ORDER_ITEM}
      table={OrderItemTable}
      detail={OrderItemDetail}
    />
  )
}

export default OrderItems
