import React from 'react'
import { DetailHeader, Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function Header({ title, isParent, loading }) {
  const { t } = useTranslation('common')

  return (
    <DetailHeader title={title} loading={loading}>
      <Chip
        outline={!isParent}
        label={
        isParent
          ? t('common:resources.categories.singular')
          : t('common:resources.subcategories.singular')
        }
      />
    </DetailHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  isParent: PropTypes.bool,
  loading: PropTypes.bool,
}

Header.defaultProps = {
  title: '',
  isParent: false,
  loading: false,
}
export default Header
