import { MainLayout } from 'components/layouts'
// import { useHotkeys } from 'components/hooks'
import Routes from 'Routes'
import React from 'react'

function Component() {
  // useHotkeys()
  return (
    <MainLayout>
      <Routes />
    </MainLayout>
  )
}

export default Component
