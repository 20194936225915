import React from 'react'
import { OrderTable, OrderDetail } from 'components/pages/orders'
import { TableLayout } from 'components/layouts'
import { ORDER } from 'constants/resources'

function Orders() {
  return (
    <TableLayout
      resource={ORDER}
      table={OrderTable}
      detail={OrderDetail}
    />
  )
}

export default Orders
