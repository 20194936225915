import React from 'react'
import { CompanyTable, CompanyDetail } from 'components/pages/companies'
import { TableLayout } from 'components/layouts'
import { COMPANY } from 'constants/resources'

function Companies() {
  return (
    <TableLayout
      resource={COMPANY}
      table={CompanyTable}
      detail={CompanyDetail}
    />
  )
}

export default Companies
