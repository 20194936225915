import React from 'react'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SUPPLIER } from 'constants/resources'

function PriceListButton({ url }) {
  const { t } = useTranslation(SUPPLIER)

  const handleChipClick = () => {
    window.open(url, '_blank')
  }
  if (!url) return null

  return (
    <Chip
      icon="visibility"
      outline
      onClick={handleChipClick}
      label={t('supplier:detail.priceList')}
    />
  )
}

PriceListButton.propTypes = {
  url: PropTypes.string,
}

PriceListButton.defaultProps = {
  url: '',
}

export default PriceListButton
