import {
  MD, LG, XL,
} from 'constants/breakpoints'
import { useState, useEffect } from 'react'

function useBreakpoint() {
  const [size, setSize] = useState('sm')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < MD) {
        setSize('sm')
      } else if (window.innerWidth < LG) {
        setSize('md')
      } else if (window.innerWidth < XL) {
        setSize('lg')
      } else {
        setSize('xl')
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}

export default useBreakpoint
