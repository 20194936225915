import styled, { keyframes } from 'styled-components'
import React, { useState, useEffect } from 'react'
import {
  IconButton, NavLink, Hint, Logo,
} from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  useTheme, useLanguage, useSessions, useNotifications,
} from 'components/hooks'
import { Link, useHistory } from 'react-router-dom'
import { Icon } from 'components/ui'
import routes from 'constants/routes'
import { useSelector } from 'react-redux'
import NavigationOverlay from './NavigationOverlay'

const swing = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  2.5% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  5% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  7.5% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  10% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  12.5% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
`

const blink = keyframes`
  20% { transform: scale(1.5) }
`

const Container = styled.div`
  display: flex;
  width: 100%; 
  align-items: center;
  justify-content: space-between;
  height: 100px;
  .nav-links {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    > a, > div {
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .nav-actions {
    display: flex;
    justify-content: flex-end;
    > * {
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .swing > span {
    transform-origin: top center;
    animation-name: ${swing};
    animation-duration: 8s;
    animation-iteration-count: infinite;
  }
  .dot {
    animation-name: ${blink};
    animation-duration: 300ms;
  }
`

function Navbar() {
  const { t } = useTranslation('common')
  const [menu, setMenu] = useState(false)
  const [theme, setTheme] = useTheme()
  const [language, setLanguage] = useLanguage()
  const [, logout] = useSessions()
  const unread = useSelector(state => state.getIn(['application', 'isNotification']))
  const isAuth = useSelector(state => state.getIn(['session', 'token']))
  const id = useSelector(state => state.getIn(['session', 'user', 'id']))
  const [getUnread] = useNotifications()
  const history = useHistory()

  useEffect(() => {
    if (isAuth && id) getUnread()
  }, [getUnread, id, isAuth])

  const handleLanguageClick = () => {
    if (language === 'en') {
      setLanguage('fr')
    } else {
      setLanguage('en')
    }
  }
  const handleThemeClick = () => {
    if (theme.name === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }
  const handleNotificationClick = () => {
    history.push(routes.notifications)
    getUnread()
  }

  if (!isAuth) return null

  if (!isAuth) return null

  return (
    <Container>
      <Link to={routes.orders}>
        <Logo
          height="24"
          color={theme.color.secondary}
          style={{ marginLeft: 16 }}
        />
      </Link>
      <Icon
        id="hamburger"
        name="menu"
        size={30}
        className="mobile-only"
        onClick={() => { setMenu(true) }}
      />
      <div className="nav-links desktop-only">
        <Hint hotKey="c">
          <NavLink
            data-test-id="route-companies-desktop"
            to={routes.companies}
          >
            {t('resources.companies.plural')}
          </NavLink>
        </Hint>
        <Hint hotKey="o">
          <NavLink
            data-test-id="route-orders-desktop"
            to={routes.orders}
          >
            {t('resources.orders.plural')}
          </NavLink>
        </Hint>
        <Hint hotKey="e">
          <NavLink
            data-test-id="route-equipment-desktop"
            to={routes.equipment}
          >
            {t('resources.equipment.plural')}
          </NavLink>
        </Hint>
        <Hint hotKey="u">
          <NavLink
            data-test-id="route-users-desktop"
            to={routes.users}
          >
            {t('resources.users.plural')}
          </NavLink>
        </Hint>
        <Hint hotKey="s">
          <NavLink
            data-test-id="route-suppliers-desktop"
            to={routes.suppliers}
          >
            {t('resources.suppliers.plural')}
          </NavLink>
        </Hint>
        <Hint hotKey="l">
          <NavLink
            data-test-id="route-service-calls-desktop"
            to={routes.serviceCalls}
          >
            {t('resources.serviceRequests.plural')}
          </NavLink>
        </Hint>
        <Hint hotKey="lr">
          <NavLink
            data-test-id="route-requests-desktop"
            to={routes.requests}
          >
            {t('resources.requests.plural')}
          </NavLink>
        </Hint>
      </div>
      <div className="nav-actions desktop-only">
        <IconButton icon="eject" onClick={logout} />
        <IconButton icon="notifications" dot={unread} className={unread && 'swing'} onClick={handleNotificationClick} />
        <IconButton icon={theme.name === 'dark' ? 'brightness_7' : 'brightness_2'} className="theme-switch" onClick={handleThemeClick} />
        <IconButton icon="translate" onClick={handleLanguageClick} className="language-switch" />
      </div>
      <NavigationOverlay
        handleHideClick={() => { setMenu(false) }}
        handleLogoutClick={logout}
        isNotification={unread}
        show={menu}
        handleLanguageClick={handleLanguageClick}
        handleNotificationClick={handleNotificationClick}
        handleThemeClick={handleThemeClick}
        theme={theme.name}
      />
    </Container>
  )
}

export default Navbar
