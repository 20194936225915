import { useEffect } from 'react'
import { isInput } from 'utils'
import { escape } from 'constants/keycodes'
import { useQuickPreview } from 'components/hooks'
import { useSelector } from 'react-redux'

function useQuickPreviewHotkeys() {
  const [, closePreview] = useQuickPreview()
  const overlay = useSelector(state => state.getIn(['application', 'overlay']))

  useEffect(() => {
    const exitPreview = () => {
      closePreview(overlay.get('resource'), overlay.get('action'))
    }

    const setHotKeys = (event) => {
      const { keyCode, target } = event

      if (!isInput(target)) {
        switch (keyCode) {
          case escape: exitPreview(); break
          default: break
        }
      }
    }

    document.addEventListener('keydown', setHotKeys)
    return () => { document.removeEventListener('keydown', setHotKeys) }
  }, [closePreview, overlay])
}

export default useQuickPreviewHotkeys
