import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AudioButton from './AudioButton'

const VoiceNoteStyled = styled.div`
  > span {
    font-size: 10px;
    color: ${({ theme }) => theme.color.secondary500};
  }
`

function VoiceNote({ src, expiration }) {
  const { t } = useTranslation('common')
  const expires = expiration && moment(expiration).fromNow()
  const isGreater = expiration && moment(expiration).isAfter(moment())
  if (!expires) return null

  return (
    <VoiceNoteStyled>
      <AudioButton src={src} />
      <br />
      <span>
        {
          isGreater ? t('shared.expires') : t('shared.expired')
        }
        {' '}
        {expires}
      </span>
    </VoiceNoteStyled>
  )
}

VoiceNote.propTypes = {
  src: PropTypes.string,
  expiration: PropTypes.string,
}

VoiceNote.defaultProps = {
  src: '',
  expiration: '',
}

export default VoiceNote
