import React from 'react'
import { useCurrency } from 'components/hooks'
import PropTypes from 'prop-types'

function Price({ amount }) {
  const formatted = useCurrency(amount)
  if (typeof amount !== 'number') return null

  return (
    <span>{formatted}</span>
  )
}

Price.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

export default Price
