import React from 'react'
import { AdminUserTable, UserDetail } from 'components/pages/users'
import { TableLayout } from 'components/layouts'
import { USER } from 'constants/resources'
import { useDispatch } from 'react-redux'
import { users } from 'store/action-creators'

function Users() {
  const dispatch = useDispatch()
  const handleNew = () => {
    dispatch(users.setCreateData({ is_admin: true }))
  }
  return (
    <TableLayout
      resource={USER}
      table={AdminUserTable}
      detail={UserDetail}
      onNew={handleNew}
    />
  )
}

export default Users
