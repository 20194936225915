export default list => list.map((obj) => {
  const newObj = {
    ...obj,
    id: obj.objectID || obj.id,
  }
  Object.keys(obj).forEach((key) => {
    if (/^serialized_/.test(key)) newObj[key.replace('serialized_', '')] = obj[key]
  })
  return newObj
})
