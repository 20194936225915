import React from 'react'

function Logo({ color, ...otherProps }) {
  return (
    <svg viewBox="0 0 713 136" {...otherProps} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.7489 0H113.733C116.03 0 118.671 0.172239 121.599 0.516688C124.527 0.861137 127.34 1.60746 130.038 2.75562C132.737 3.90378 135.033 5.51121 136.928 7.63532C138.822 9.75942 139.913 12.5724 140.085 16.0169V17.6243C139.913 23.9966 139.052 29.7375 137.617 34.6746L136.756 36.9709C134.746 43.9747 131.99 49.4285 128.546 53.3322C125.101 57.236 120.91 60.3934 116.03 62.7472L124.125 94.0346H71.5354L61.4309 62.5749L52.6469 94.0346H3.96143L31.7489 0ZM76.3006 14.9261L67.0573 46.2136C73.9467 46.2136 79.4009 45.1228 83.2475 42.9987C87.1515 40.8746 89.9073 37.0857 91.5148 31.6319C91.9741 29.2782 92.376 26.6948 92.6631 23.9966C92.9501 21.2985 93.1224 18.2558 93.1224 14.9261H76.3006Z" fill={color} />
      <path d="M139.97 85.1938C139.97 82.9548 140.487 79.3381 141.578 74.3436C142.611 69.3491 144.047 63.436 145.884 56.5471C147.147 51.6674 148.697 46.8451 150.592 42.0802C152.486 37.2579 154.496 32.9523 156.62 29.106C158.802 25.2596 161.041 22.1022 163.452 19.691C165.863 17.2799 168.217 16.0743 170.514 16.0743H240.843C240.729 16.4187 240.556 17.6243 240.327 19.6336C240.097 21.6429 239.695 23.48 239.121 25.1448C238.145 27.3837 237.284 28.5319 236.538 28.5319H198.473C198.359 28.5319 197.899 29.2208 196.981 30.656C196.062 32.0912 195.316 33.5838 194.742 35.0764L193.306 40.5876C193.191 41.0469 193.134 41.7358 193.134 42.5395C193.134 44.2043 193.306 45.7543 193.708 47.1895H227.754L224.309 58.5564H189.287C188.541 58.786 187.795 60.1638 187.106 62.6324L185.67 67.4546C185.556 67.9139 185.498 68.4306 185.383 68.8898L183.776 80.4289H221.553L220.52 84.1604C220.118 85.7104 219.773 87.0308 219.429 88.179C219.084 89.3272 218.797 90.1309 218.51 90.5902C218.051 91.1642 217.477 91.9105 216.845 92.829C216.214 93.6902 215.812 94.1494 215.64 94.1494H145.769C145.597 94.1494 145.195 93.8624 144.678 93.3457C144.162 92.8291 143.587 92.1976 143.013 91.4513C142.439 90.7624 141.865 89.9587 141.291 89.1549C140.717 88.3512 140.258 87.6049 139.97 87.0308V85.1938Z" fill={color} />
      <path d="M327.593 94.0346H289.529L307.671 30.7708H287.232L267.942 94.0346H228.729L252.211 15.7872H338.559C339.592 15.7872 340.626 16.1317 341.602 16.7632C342.578 17.3947 343.439 18.2558 344.185 19.3466C344.931 20.4373 345.62 21.5855 346.137 22.791C346.654 24.054 346.998 25.2596 347.228 26.4078L327.593 94.0346Z" fill={color} />
      <path d="M375.877 28.4745H358.022L360.892 15.8447H439.432L436.848 28.4745H416.524L396.775 94.0921H356.873L375.877 28.4745Z" fill={color} />
      <path d="M546.964 0C546.964 12.1131 546.964 22.504 546.964 31.2875C546.964 40.0709 546.964 47.5914 546.907 53.9637C546.85 60.2786 546.792 65.5602 546.735 69.8084C546.677 73.9992 546.62 77.6159 546.505 80.5437C546.39 83.5289 546.275 86.0549 546.046 88.1216C545.874 90.1883 545.644 92.1976 545.472 94.092H491.734V80.4289H453.268L443.91 94.092H427.605L489.438 0.0574292H546.964V0ZM491.734 22.5614L463.372 65.3879H491.734V22.5614Z" fill="#F7931E" />
      <path d="M618.557 15.8447L598.98 80.8881H626.767C626.595 81.577 626.365 82.84 626.193 84.6197C626.021 86.3993 625.676 88.0068 625.16 89.442C624.758 90.7624 624.356 91.8531 623.839 92.7142C623.38 93.5753 622.806 94.0346 622.232 94.0346H555.806L579.287 15.7872H618.557V15.8447Z" fill="#F7931E" />
      <path d="M696.35 15.8447L676.773 80.8881H704.56C704.388 81.577 704.158 82.84 703.986 84.6197C703.814 86.3993 703.47 88.0068 702.953 89.442C702.551 90.7624 702.149 91.8531 701.632 92.7142C701.173 93.5753 700.599 94.0346 700.025 94.0346H633.599L657.081 15.7872H696.35V15.8447Z" fill="#F7931E" />
      <path d="M0 126.815C0 121.763 3.73178 117.687 9.12851 117.687C12.401 117.687 14.4104 118.778 16.018 120.385L13.5492 123.198C12.1713 121.993 10.8509 121.246 9.0711 121.246C6.14309 121.246 4.01884 123.715 4.01884 126.7V126.757C4.01884 129.743 6.08568 132.269 9.0711 132.269C11.0805 132.269 12.2862 131.465 13.6641 130.202L16.1328 132.67C14.353 134.622 12.3436 135.77 8.95627 135.77C3.78919 135.77 0 131.809 0 126.815Z" fill={color} />
      <path d="M25.4336 126.815C25.4336 121.763 29.3376 117.687 34.7343 117.687C40.1311 117.687 44.0351 121.705 44.0351 126.7V126.757C44.0351 131.752 40.1311 135.828 34.7343 135.828C29.3376 135.77 25.4336 131.752 25.4336 126.815ZM40.0162 126.815C40.0162 123.772 37.8346 121.246 34.7343 121.246C31.6341 121.246 29.5098 123.715 29.5098 126.7V126.757C29.5098 129.743 31.6915 132.269 34.7917 132.269C37.892 132.269 40.0162 129.8 40.0162 126.815Z" fill={color} />
      <path d="M54.9432 117.974H58.5028L66.7127 128.767V117.974H70.5019V135.483H67.2294L58.7324 124.346V135.483H54.9432V117.974Z" fill={color} />
      <path d="M81.0083 132.957L83.3048 130.259C84.8549 131.58 86.5199 132.383 88.5293 132.383C90.0794 132.383 91.0554 131.752 91.0554 130.718V130.661C91.0554 129.685 90.4813 129.168 87.5533 128.422C84.0511 127.504 81.7547 126.528 81.7547 123.083V123.026C81.7547 119.868 84.2808 117.744 87.8977 117.744C90.4239 117.744 92.6055 118.548 94.3853 119.983L92.3759 122.911C90.8258 121.82 89.2756 121.189 87.8403 121.189C86.405 121.189 85.6013 121.878 85.6013 122.739V122.796C85.6013 123.944 86.3476 124.346 89.3905 125.093C92.95 126.011 94.9594 127.274 94.9594 130.317V130.374C94.9594 133.876 92.3185 135.828 88.5293 135.828C85.7735 135.77 83.1325 134.852 81.0083 132.957Z" fill={color} />
      <path d="M109.427 121.533H104.088V117.974H118.613V121.533H113.274V135.483H109.427V121.533Z" fill={color} />
      <path d="M129.062 117.974H137.043C139.282 117.974 141.004 118.605 142.152 119.753C143.128 120.729 143.645 122.107 143.645 123.772V123.829C143.645 126.642 142.095 128.422 139.913 129.283L144.162 135.541H139.683L135.952 129.915H135.894H132.909V135.541H129.062V117.974ZM136.813 126.47C138.707 126.47 139.741 125.494 139.741 124.002V123.944C139.741 122.279 138.593 121.418 136.698 121.418H132.851V126.47H136.813Z" fill={color} />
      <path d="M154.381 128.02V118.031H158.228V127.905C158.228 130.776 159.663 132.211 162.017 132.211C164.371 132.211 165.806 130.776 165.806 128.02V117.974H169.653V127.848C169.653 133.13 166.667 135.77 161.959 135.77C157.252 135.77 154.381 133.13 154.381 128.02Z" fill={color} />
      <path d="M180.274 126.815C180.274 121.763 184.006 117.687 189.402 117.687C192.675 117.687 194.684 118.778 196.292 120.385L193.823 123.198C192.445 121.992 191.125 121.246 189.345 121.246C186.417 121.246 184.293 123.715 184.293 126.7V126.757C184.293 129.743 186.36 132.269 189.345 132.269C191.354 132.269 192.56 131.465 193.938 130.202L196.407 132.67C194.627 134.622 192.618 135.77 189.23 135.77C184.121 135.77 180.274 131.809 180.274 126.815Z" fill={color} />
      <path d="M211.104 121.533H205.765V117.974H220.29V121.533H214.951V135.483H211.104V121.533Z" fill={color} />
      <path d="M230.854 117.974H234.7V135.483H230.854V117.974Z" fill={color} />
      <path d="M245.838 126.815C245.838 121.763 249.742 117.687 255.139 117.687C260.536 117.687 264.44 121.705 264.44 126.7V126.757C264.44 131.752 260.536 135.828 255.139 135.828C249.685 135.77 245.838 131.752 245.838 126.815ZM260.421 126.815C260.421 123.772 258.239 121.246 255.139 121.246C252.039 121.246 249.915 123.715 249.915 126.7V126.757C249.915 129.743 252.096 132.269 255.197 132.269C258.297 132.269 260.421 129.8 260.421 126.815Z" fill={color} />
      <path d="M275.348 117.974H278.908L287.117 128.767V117.974H290.907V135.483H287.634L279.137 124.346V135.483H275.348V117.974Z" fill={color} />
      <path d="M321.68 121.533H316.34V117.974H330.866V121.533H325.526V135.483H321.68V121.533Z" fill={color} />
      <path d="M339.822 126.815C339.822 121.763 343.726 117.687 349.123 117.687C354.52 117.687 358.424 121.705 358.424 126.7V126.757C358.424 131.752 354.52 135.828 349.123 135.828C343.669 135.77 339.822 131.752 339.822 126.815ZM354.405 126.815C354.405 123.772 352.223 121.246 349.123 121.246C346.023 121.246 343.898 123.715 343.898 126.7V126.757C343.898 129.743 346.08 132.269 349.18 132.269C352.223 132.269 354.405 129.8 354.405 126.815Z" fill={color} />
      <path d="M368.585 126.815C368.585 121.763 372.489 117.687 377.886 117.687C383.283 117.687 387.187 121.705 387.187 126.7V126.757C387.187 131.752 383.283 135.828 377.886 135.828C372.432 135.77 368.585 131.752 368.585 126.815ZM383.11 126.815C383.11 123.772 380.929 121.246 377.829 121.246C374.728 121.246 372.604 123.715 372.604 126.7V126.757C372.604 129.743 374.786 132.269 377.886 132.269C380.986 132.269 383.11 129.8 383.11 126.815Z" fill={color} />
      <path d="M398.095 117.974H401.942V131.981H410.668V135.483H398.095V117.974Z" fill={color} />
      <path d="M419.854 132.957L422.151 130.259C423.701 131.58 425.366 132.383 427.375 132.383C428.925 132.383 429.901 131.752 429.901 130.718V130.661C429.901 129.685 429.327 129.168 426.399 128.422C422.897 127.504 420.601 126.528 420.601 123.083V123.026C420.601 119.868 423.127 117.744 426.744 117.744C429.27 117.744 431.451 118.548 433.231 119.983L431.222 122.911C429.672 121.82 428.122 121.189 426.686 121.189C425.251 121.189 424.447 121.878 424.447 122.739V122.796C424.447 123.944 425.194 124.346 428.236 125.093C431.796 126.011 433.805 127.274 433.805 130.317V130.374C433.805 133.876 431.164 135.828 427.375 135.828C424.677 135.77 421.978 134.852 419.854 132.957Z" fill={color} />
      <path d="M469.343 133.819C467.908 134.967 466.243 135.77 464.234 135.77C460.904 135.77 458.378 133.819 458.378 130.718V130.661C458.378 128.422 459.583 126.872 461.765 125.839C460.904 124.633 460.559 123.542 460.559 122.337V122.279C460.559 119.868 462.511 117.687 465.841 117.687C468.827 117.687 470.779 119.639 470.779 122.107V122.165C470.779 124.518 469.286 125.896 467.104 126.757L469.458 129.054C470.032 128.135 470.607 127.102 471.181 126.068L473.994 127.618C473.305 128.824 472.559 130.144 471.697 131.293L474.281 133.876L471.468 135.828L469.343 133.819ZM467.162 131.58L463.832 128.193C462.684 128.767 462.224 129.628 462.224 130.489V130.546C462.224 131.752 463.258 132.613 464.693 132.613C465.554 132.613 466.358 132.211 467.162 131.58ZM467.392 122.394C467.392 121.361 466.703 120.729 465.727 120.729C464.693 120.729 464.004 121.476 464.004 122.509V122.567C464.004 123.313 464.291 123.944 465.038 124.748C466.588 124.117 467.392 123.485 467.392 122.394Z" fill={color} />
      <path d="M499.485 117.974H512.69V121.418H503.331V124.978H511.599V128.422H503.331V132.096H512.804V135.541H499.485V117.974Z" fill={color} />
      <path d="M537.434 134.278C535.941 135.254 534.161 135.77 532.209 135.77C526.813 135.77 522.909 131.752 522.909 126.757V126.7C522.909 121.705 526.813 117.629 532.209 117.629C537.606 117.629 541.51 121.648 541.51 126.642V126.7C541.51 128.537 540.936 130.259 539.96 131.752L541.74 133.302L539.271 136L537.434 134.278ZM534.621 131.752L531.75 129.341L534.219 126.585L537.09 129.168C537.434 128.422 537.549 127.618 537.549 126.757V126.7C537.549 123.715 535.367 121.189 532.267 121.189C529.167 121.189 527.042 123.657 527.042 126.642V126.7C527.042 129.685 529.224 132.211 532.324 132.211C533.128 132.268 533.932 132.096 534.621 131.752Z" fill={color} />
      <path d="M552.246 128.02V118.031H556.093V127.905C556.093 130.776 557.528 132.211 559.882 132.211C562.236 132.211 563.671 130.776 563.671 128.02V117.974H567.518V127.848C567.518 133.13 564.533 135.77 559.825 135.77C555.117 135.77 552.246 133.13 552.246 128.02Z" fill={color} />
      <path d="M579.058 117.974H582.904V135.483H579.058V117.974Z" fill={color} />
      <path d="M594.789 117.974H601.965C606.156 117.974 608.682 120.442 608.682 124.002V124.059C608.682 128.135 605.525 130.202 601.621 130.202H598.693V135.426H594.846V117.974H594.789ZM601.736 126.815C603.688 126.815 604.778 125.667 604.778 124.174V124.116C604.778 122.394 603.573 121.476 601.678 121.476H598.693V126.815H601.736Z" fill={color} />
      <path d="M619.016 117.974H623.15L627.743 125.379L632.336 117.974H636.47V135.483H632.623V124.059L627.686 131.522H627.571L622.691 124.116V135.483H618.902V117.974H619.016Z" fill={color} />
      <path d="M648.239 117.974H661.444V121.418H652.028V124.978H660.296V128.422H652.028V132.096H661.501V135.541H648.182V117.974H648.239Z" fill={color} />
      <path d="M672.467 117.974H676.027L684.237 128.767V117.974H688.026V135.483H684.753L676.256 124.346V135.483H672.467V117.974Z" fill={color} />
      <path d="M703.814 121.533H698.475V117.974H713V121.533H707.661V135.483H703.814V121.533Z" fill={color} />
    </svg>

  )
}

export default Logo
