import React from 'react'
import {
  Chip, TableRow, PreviewLink, TableCell, Date, FacetFilter,
} from 'components/common'
import PropTypes from 'prop-types'
import {
  USER, COMPANY, EQUIPMENT, SERVICE_REQUEST,
} from 'constants/resources'

function ServiceRequestRow({
  status,
  code,
  companyName,
  companyId,
  createdByName,
  createdById,
  createdAt,
  equipmentName,
  equipmentId,
  assigneeName,
  assigneeId,
  active,
  shrink,
  onClick,
  templateColumns,
  statusColor,
  salesRepId,
  salesRepName,
}) {
  const createdAtFormatted = typeof createdAt === 'string' ? createdAt : createdAt * 1000
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell md>
        <p>{code}</p>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'status',
            value: status,
            label: status,
          }}
          resource={SERVICE_REQUEST}
        >
          <Chip
            background={statusColor}
            secondary={active}
            label={status}
          />
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_created_by.id',
            value: createdById,
            label: createdByName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink hideMobile resource={USER} id={createdById}>
            {createdByName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <Date variant="datetime">{createdAtFormatted}</Date>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'serialized_company.id',
            value: companyId,
            label: companyName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink hideMobile resource={COMPANY} id={companyId}>
            {companyName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell sm>
        <PreviewLink hideMobile resource={EQUIPMENT} id={equipmentId}>
          {equipmentName}
        </PreviewLink>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_assignee.id',
            value: assigneeId,
            label: assigneeName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink hideMobile resource={USER} id={assigneeId}>
            {assigneeName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_sales_rep.id',
            value: salesRepId,
            label: salesRepName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink
            hideMobile
            resource={USER}
            id={salesRepId}
          >
            {salesRepName}
          </PreviewLink>
        </FacetFilter>

      </TableCell>
    </TableRow>
  )
}

ServiceRequestRow.propTypes = {
  status: PropTypes.string,
  salesRepId: PropTypes.string,
  salesRepName: PropTypes.string,
  statusColor: PropTypes.string,
  code: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  createdByName: PropTypes.string,
  createdById: PropTypes.string,
  createdAt: PropTypes.string,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
}

ServiceRequestRow.defaultProps = {
  status: '',
  salesRepId: '',
  salesRepName: '',
  statusColor: '',
  code: '',
  companyName: '',
  companyId: '',
  createdByName: '',
  createdById: '',
  createdAt: '',
  equipmentName: '',
  equipmentId: '',
  assigneeName: '',
  assigneeId: '',
  active: false,
  shrink: false,
  onClick: null,
}

export default ServiceRequestRow
