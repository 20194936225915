import React from 'react'
import PropTypes from 'prop-types'
import { Detail, PhoneNumber } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION } from 'constants/resources'

function Account({
  company,
  contactNumber,
  email,
  name,
  loading,
}) {
  const { t } = useTranslation(['common', NOTIFICATION])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Detail
          text={name}
          loading={loading}
          label={t('common:shared.name')}
        >
          { name }
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={company}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          { company }
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={email}
          loading={loading}
          label={t('common:shared.email')}
        >
          <a href={`mailto:${email}`}>{email}</a>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.phone')}
        >
          <PhoneNumber number={contactNumber} />
        </Detail>
      </Grid>
    </Grid>
  )
}


Account.propTypes = {
  company: PropTypes.string,
  contactNumber: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
  isAcknowledged: PropTypes.bool,
}

Account.defaultProps = {
  company: '',
  contactNumber: '',
  email: '',
  name: '',
  loading: false,
  isAcknowledged: false,
}


export default Account
