import React from 'react'
import PropTypes from 'prop-types'
import Row from './Row'

function LoadingRow({ fullSize }) {
  return (
    <Row templateColumns="1fr" fullSize={fullSize}>
      <p className="loading" />
    </Row>
  )
}

LoadingRow.propTypes = {
  fullSize: PropTypes.bool,
}

LoadingRow.defaultProps = {
  fullSize: false,
}
export default LoadingRow
