import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import OffRentDetail from 'components/pages/service-requests/detail/OffRentDetail'
import OrderDetail from 'components/pages/service-requests/detail/OrderDetail'
import PurchasePriceDetail from 'components/pages/service-requests/detail/PurchasePriceDetail'
import RentalPriceDetail from 'components/pages/service-requests/detail/RentalPriceDetail'
import ServiceCallDetail from 'components/pages/service-requests/detail/ServiceCallDetail'
import {
  OFF_RENT, ORDER, PURCHASE_PRICE, RENTAL_PRICE, SERVICE_CALL,
} from 'constants/designations'

function ServiceRequest({
  id,
  loading,
  selected,
}) {
  if (!id) return null
  if (selected.get('designation') === OFF_RENT) return <OffRentDetail selected={selected} loading={loading} />
  if (selected.get('designation') === ORDER) return <OrderDetail selected={selected} loading={loading} />
  if (selected.get('designation') === PURCHASE_PRICE) return <PurchasePriceDetail selected={selected} loading={loading} />
  if (selected.get('designation') === RENTAL_PRICE) return <RentalPriceDetail selected={selected} loading={loading} />
  if (selected.get('designation') === SERVICE_CALL) return <ServiceCallDetail selected={selected} loading={loading} />

  return null
}


ServiceRequest.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  selected: PropTypes.instanceOf(Map),
}

ServiceRequest.defaultProps = {
  id: '',
  loading: false,
  selected: new Map(),
}


export default ServiceRequest
