import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, PreviewLink, Date, SectionHeader,
} from 'components/common'
import {
  COMPANY, USER, EQUIPMENT, ORDER_ITEM, SERVICE_REQUEST, ORDER,
} from 'constants/resources'

function Information({
  action,
  title,
  code,
  internalCode,
  companyName,
  companyId,
  assigneeName,
  assigneeId,
  salesRepName,
  salesRepId,
  createdByName,
  createdById,
  equipmentName,
  equipmentId,
  orderItemCode,
  orderItemId,
  orderCode,
  orderId,
  notes,
  createdAt,
  resolveBy,
  status,
  loading,
  designation,
  id,
}) {
  const { t } = useTranslation(['common', SERVICE_REQUEST])

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6}>
        <Detail
          text={createdByName}
          loading={loading}
          label={t('common:shared.createdBy')}
        >
          <PreviewLink id={createdById} resource={USER}>
            {createdByName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={createdAt}
          loading={loading}
          label={t('common:shared.createdAt')}
        >
          <Date variant="datetime">
            {createdAt}
          </Date>
        </Detail>
      </Grid>

      <Grid item xs={12} md={6}>
        <Detail
          text={internalCode}
          loading={loading}
          label={t('common:shared.id')}
        >
          {internalCode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={code}
          loading={loading}
          label={t('common:shared.publicCode')}
        >
          {code}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={orderCode}
          loading={loading}
          label={t('common:resources.orders.singular')}
        >
          <PreviewLink id={orderId} resource={ORDER}>
            {orderCode}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={orderItemCode}
          loading={loading}
          label={t('common:resources.orderItems.singular')}
        >
          <PreviewLink id={orderItemId} resource={ORDER_ITEM}>
            {orderItemCode}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={assigneeName}
          loading={loading}
          label={t('serviceRequest:shared.handledBy')}
        >
          <PreviewLink id={assigneeId} resource={USER}>
            {assigneeName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('shared.salesRep')}
          empty={t('company:shared.emptySalesRep')}
        >
          <PreviewLink
            resource={USER}
            id={salesRepId}
          >
            {salesRepName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink id={companyId} resource={COMPANY}>
            {companyName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={equipmentName}
          loading={loading}
          label={t('common:resources.equipment.singular')}
        >
          <PreviewLink id={equipmentId} resource={EQUIPMENT}>
            {equipmentName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={resolveBy}
          loading={loading}
          label={t('serviceRequest:shared.resolvedBy')}
        >
          <Date variant="datetime">
            {resolveBy}
          </Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.description')}
        >
          {notes}
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  code: PropTypes.string,
  createdAt: PropTypes.string,
  resolveBy: PropTypes.string,
  status: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  createdByName: PropTypes.string,
  createdById: PropTypes.string,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
  orderItemCode: PropTypes.string,
  orderItemId: PropTypes.string,
  designation: PropTypes.string,
  id: PropTypes.string,
  internalCode: PropTypes.string,
  orderId: PropTypes.string,
  orderCode: PropTypes.string,
}

Information.defaultProps = {
  action: null,
  title: '',
  code: '',
  createdAt: '',
  resolveBy: '',
  status: '',
  companyName: '',
  companyId: '',
  assigneeName: '',
  assigneeId: '',
  salesRepName: '',
  salesRepId: '',
  createdByName: '',
  createdById: '',
  equipmentName: '',
  equipmentId: '',
  notes: '',
  loading: false,
  orderItemCode: '',
  orderItemId: '',
  designation: '',
  id: '',
  internalCode: '',
  orderId: '',
  orderCode: '',
}

export default Information
