import { useSelector } from 'react-redux'
import { useMemo } from 'react'

function useDateFilters(resource) {
  const storeFilters = useSelector(state => state.getIn([resource, 'list', 'search', 'filters']))

  return useMemo(() => {
    if (!storeFilters.size) return ''

    const filters = []
    storeFilters.forEach((value, key) => {
      const timestamps = value.toJS().map(date => date.value)
      if (key === 'billing_date_timestamp' && storeFilters.has('start_date_timestamp')) {
        if (value.size > 1) {
          // Range
          const min = Math.min(...timestamps)
          const max = Math.max(...timestamps)
          filters.push(`${key}:${min} TO ${max}`)
        } else {
          // Single day
          filters.push(`${key}<=${value.getIn([0, 'value'])}`)
        }
      } else if (key === 'start_date_timestamp' && storeFilters.has('billing_date_timestamp')) {
        if (value.size > 1) {
          // Range
          const min = Math.min(...timestamps)
          const max = Math.max(...timestamps)
          filters.push(`${key}:${min} TO ${max}`)
        } else {
          // Single day
          filters.push(`${key}<=${value.getIn([0, 'value'])}`)
        }
      } else if (value.size > 1) {
        // Range
        const min = Math.min(...timestamps)
        const max = Math.max(...timestamps)
        filters.push(`${key}:${min} TO ${max}`)
      } else {
        // Single day
        filters.push(`${key}=${value.getIn([0, 'value'])}`)
      }
    })
    return filters
  }, [storeFilters])
}

export default useDateFilters
