import React, { useState } from 'react'
import { useCurrent, useResource, useLanguage } from 'components/hooks'
import { CATEGORY } from 'constants/resources'
import { categories } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Destroy, TableDetail } from 'components/common'
import Information from './detail/Information'
import Header from './detail/Header'
import ParentCategory from './detail/ParentCategory'
import SubcategoryList from './detail/SubcategoryList'
import EquipmentList from './detail/EquipmentList'


function CategoryDetail() {
  const [count, setCount] = useState(1)
  const selected = useCurrent(CATEGORY)
  const loading = useResource(CATEGORY, 'current', 'loading')
  const dispatch = useDispatch()
  const [language] = useLanguage()
  const canDestroy = selected.get('parent') && !count

  const onCategoryClick = (id) => {
    dispatch(categories.get(id))
  }

  return (
    <TableDetail>
      <Header
        title={(language === 'fr') ? selected.getIn(['translations', 'fr', 'name']) : selected.get('name')}
        isParent={!selected.get('parent')}
        loading={loading}
      />
      <Divider spacing={28} />
      <Information
        name={selected.get('name')}
        nameTranslation={selected.getIn(['translations', 'fr', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      {
        selected.get('parent') ? (
          <ParentCategory
            onClick={onCategoryClick}
            categoryId={selected.get('parent')}
            loading={loading}
          />
        ) : (
          <SubcategoryList
            onClick={onCategoryClick}
            categoryId={selected.get('id')}
            loading={loading}
          />)
      }
      <Divider spacing={20} />
      <EquipmentList
        onCount={setCount}
        loading={loading}
        categoryId={selected.get('id')}
      />
      <Destroy resource={CATEGORY} id={selected.get('id')} show={canDestroy} />
    </TableDetail>
  )
}


export default CategoryDetail
