import { useDispatch } from 'react-redux'
import nprogress from 'nprogress'
import api from 'config/axios'
import { sessions } from 'store/action-creators'
import {
  createSession,
  terminateSession,
  getCurrent,
} from 'services/sessions'
import { useLocation, useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import useNotification from './use-notification'

function useSessions() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const notify = useNotification()
  const { t } = useTranslation('session')

  const login = async (email, password) => {
    nprogress.start()
    const response = await createSession({ email, password })
    if (response) {
      api.defaults.headers.common.Authorization = `Bearer ${response.token}`
      getCurrent().then((user) => {
        console.log('Subscribing to push notifications')
        if (window.OneSignal) {
          window.OneSignal.push(() => {
            console.log(`${user.email} has been subscribed!`)
            window.OneSignal.setExternalUserId(user.id)
          })
        }
        dispatch(sessions.setCurrent(user))
      })
      dispatch(sessions.setToken(response.token))
      const decoded = jwtDecode(response.token)
      dispatch(sessions.setAlgoliaToken(decoded.algolia_token))
      const cookies = new Cookies()
      cookies.set('cypress-token', response.token)
      localStorage.setItem('jwt', response.token)
      localStorage.setItem('algolia', decoded.algolia_token)
      localStorage.setItem('exp', decoded.exp * 1000)
      notify({ text: t('login.success') })
      if (location?.state?.from) {
        history.push(location.state.from)
      } else {
        history.push('/')
      }
      nprogress.done()
    }
  }

  const logout = async () => {
    nprogress.start()
    history.push('/login')
    const response = await terminateSession()
    if (response) {
      if (window.OneSignal) {
        window.OneSignal.push(() => {
          console.log('Unsubscribing to push notifications')
          window.OneSignal.removeExternalUserId()
        })
      }
      api.defaults.headers.common.Authorization = ''
      localStorage.removeItem('jwt')
      localStorage.removeItem('exp')
      notify({ text: t('logout.success') })
      dispatch(sessions.setToken(null))
      nprogress.done()
    }
  }

  return [login, logout]
}

export default useSessions
