import {
  takeLatest,
  call,
  select,
  put,
} from 'redux-saga/effects'
import { EQUIPMENT } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import { PROMOTE_EQUIPMENT } from 'store/actions/equipment'
import {
  create as createService,
  update as updateService,
  updatePicture as updatePictureService,
} from 'services/equipment'
import { EDIT, NEW } from 'constants/crud'
import { equipment } from 'store/action-creators'
import notify from 'utils/notify'
import i18n from 'config/i18n'
import {
  list, get, destroy,
} from './factory'
import nprogress from './nprogress'
import { closePreview } from './preview'

function* create({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { image } = body
  yield put(equipment.setCreateLoading(true))
  const response = yield call(createService, body)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCreate', { resource: response.name }),
    })
    yield put(equipment.prependListItem(response))
    yield call(closePreview, EQUIPMENT, NEW)
    yield put(equipment.setCurrentData(response))
    if (image) {
      const pictureResponse = yield call(updatePictureService, response.id, image)
      yield put(equipment.setCurrentData(pictureResponse))
    }
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(equipment.setCreateLoading(false))
}

function* update({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { image } = body
  yield put(equipment.setUpdateLoading(true))
  const id = yield select(store => store.getIn([EQUIPMENT, 'current', 'id']))
  const response = yield call(updateService, id, body)
  let pictureResponse = null
  if (image) {
    pictureResponse = yield call(updatePictureService, id, image)
  }
  if (response) {
    notify({
      text: i18n.t('common:shared.successUpdate', { resource: response.name }),
    })
    yield put(equipment.setUpdateLoading(false))
    yield put(equipment.updateListItem(response))
    yield call(closePreview, EQUIPMENT, EDIT)
    yield put(equipment.setCurrentData(response))
    yield call(nprogress, false)
  }
  if (pictureResponse) {
    notify({
      text: i18n.t('error:picture.delay'),
    })
    yield put(equipment.setCurrentData(pictureResponse))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)

  yield call(nprogress, false)
  yield put(equipment.setUpdateLoading(false))
}

function* promote({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const id = yield select(store => store.getIn([EQUIPMENT, 'current', 'id']))
  const response = yield call(updateService, id, { is_promoted: body })
  if (response) {
    const text = body ? i18n.t('equipment:promoted.success', { machine: response.name }) : i18n.t('equipment:umpromoted.success', { machine: response.name })
    notify({ text })
    yield put(equipment.setCurrentData(response))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(equipment.setUpdateLoading(false))
}

export default function* equipmentSaga() {
  yield takeLatest(LIST(EQUIPMENT), list)
  yield takeLatest(GET(EQUIPMENT), get)
  yield takeLatest(CREATE(EQUIPMENT), create)
  yield takeLatest(PROMOTE_EQUIPMENT, promote)
  yield takeLatest(UPDATE(EQUIPMENT), update)
  yield takeLatest(DELETE(EQUIPMENT), destroy)
}
