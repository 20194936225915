import Switch from '@material-ui/core/Switch'
import styled from 'styled-components'

const SwitchStyled = styled(Switch)`
  .MuiSwitch-colorPrimary {
    color: red !important;
  }
  .MuiSwitch-colorSecondary {
    color: green !important;
  }
  .MuiSwitch-thumb {
    background-color: ${({ theme, checked }) => (checked ? theme.color.accent : theme.color.secondary)};
  }
  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.color.secondary};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.color.accent};
  }
`

export default SwitchStyled
