import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MaterialRating from '@material-ui/lab/Rating'


const StyledRating = styled(MaterialRating)`
  .MuiRating-iconFilled svg {
    font-size:  ${({ size }) => size === 'large' && '20px'};
    fill: ${({ theme }) => theme.color.secondary};
  }
  .MuiRating-iconEmpty svg {
    font-size:  ${({ size }) => size === 'large' && '20px'};
    fill:  ${({ theme }) => theme.color.secondary}33;
  }

`

function Rating({ score, loading, ...otherProps }) {
  return <StyledRating value={score} size="small" {...otherProps} />
}

Rating.propTypes = {
  score: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

Rating.defaultProps = {
  loading: false,
}


export default Rating
