import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextField from 'components/ui/TextField'

const DropZone = styled.div`
  width: 100%;
  position: relative;
  input {
    opacity: 0;
  }
  > div {
    width: 100%;
  }
  .filename {
    position: absolute;
    top: 28px;
    left: 12px;
    opacity: ${({ isFile }) => (isFile ? 1 : 0.5)};
  }
`
function FileField({
  onChange,
  value,
  max,
  maxMessage,
  placeholder,
  dropPlaceholder,
  helperText,
  ...otherProps
}) {
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0] && acceptedFiles[0].size / 1048576 < max) {
      if (onChange) onChange(acceptedFiles[0])
      setName(acceptedFiles[0].name)
      setError('')
    } else {
      setError(maxMessage)
    }
  }, [onChange, max, maxMessage])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const {
    ref, style, onChange: onFileChange, ...inputProps
  } = getInputProps()
  const label = isDragActive ? dropPlaceholder : (name || placeholder)

  return (
    <DropZone
      {...getRootProps()}
      active={isDragActive}
      isFile={!!name}
    >
      <TextField
        forwardRef={ref}
        onFileChange={onFileChange}
        {...inputProps}
        {...otherProps}
        error={!!error}
        helperText={error || helperText}
      />
      <p className="filename">{label}</p>
    </DropZone>
  )
}

FileField.propTypes = {
  onChange: PropTypes.func,
  max: PropTypes.number,
  value: PropTypes.any,
  maxMessage: PropTypes.string,
  placeholder: PropTypes.string,
  dropPlaceholder: PropTypes.string,
  helperText: PropTypes.string,
}

FileField.defaultProps = {
  onChange: null,
  max: 15,
  value: '',
  maxMessage: '',
  placeholder: '',
  dropPlaceholder: '',
  helperText: '',
}

export default FileField
