import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const HintStyled = styled.div`
  position: relative;
  .hint {
    position: absolute;
    opacity: 0;
    top: -10px;
    right: -20px;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    background-color: ${({ theme }) => theme.color.primary400};
    border-radius: ${({ theme }) => theme.radius}px;
    transition: opacity 300ms ease-in;
    &.show-hint {
      opacity: 1;
    }
  }
`

function Hint({ children, hotKey, ...otherProps }) {
  const show = useSelector(state => state.getIn(['application', 'showHints']))

  return (
    <HintStyled {...otherProps}>
      {children}
      <div className={`hint ${show ? 'show-hint' : ''}`}>{hotKey.toUpperCase()}</div>
    </HintStyled>
  )
}

Hint.propTypes = {
  hotKey: PropTypes.string.isRequired,
}

export default Hint
