import React from 'react'
import { DetailHeader } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  RESERVATION_CANCELLATION, ACCOUNT_REQUEST,
} from 'constants/designations'
import { CANCELLED } from 'constants/status'
import { Map } from 'immutable'
import { NOTIFICATION, RESERVATION } from 'constants/resources'
import { useDispatch } from 'react-redux'
import { notifications } from 'store/action-creators'
import NewOrderButton from './NewOrderButton'
import CancelReservationItemButton from './CancelReservationItemButton'
import NewUserButton from './NewUserButton'
import EditServiceButton from './EditServiceButton'
import AcknowledgeButton from './AcknowledgeButton'

function Header({
  designation,
  isAcknowledged,
  loading,
  id,
  company,
  contactNumber,
  email,
  name,
  reservationStartDate,
  reservationCompany,
  reservationId,
  reservationItemId,
  reservationStatus,
  serviceRequest,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(NOTIFICATION)

  const acknowledge = () => {
    if (id) {
      dispatch(notifications.acknowledge(id))
      dispatch(notifications.setCurrentId(''))
      dispatch(notifications.setCurrentData({}))
    }
  }
  let title = t(`designations.${designation}`)
  if (serviceRequest) title = t(`designations.${serviceRequest.get('designation')}`)
  return (
    <DetailHeader title={title} loading={loading}>
      <EditServiceButton
        acknowledge={acknowledge}
        serviceRequest={serviceRequest}
        show={!!serviceRequest}
      />
      <NewOrderButton
        acknowledge={acknowledge}
        isDate={!!reservationStartDate}
        isCompany={!!reservationCompany}
        reservationId={reservationId}
        show={designation === RESERVATION && reservationStatus !== CANCELLED}
      />
      <CancelReservationItemButton
        notificationId={id}
        reservationItemId={reservationItemId}
        show={designation === RESERVATION_CANCELLATION}
      />
      <NewUserButton
        acknowledge={acknowledge}
        company={company}
        contactNumber={contactNumber}
        email={email}
        name={name}
        show={designation === ACCOUNT_REQUEST}
      />
      <AcknowledgeButton
        show={!isAcknowledged}
        acknowledge={acknowledge}
      />
    </DetailHeader>
  )
}

Header.propTypes = {
  id: PropTypes.string,
  designation: PropTypes.string,
  loading: PropTypes.bool,
  isAcknowledged: PropTypes.bool,
  company: PropTypes.string,
  contactNumber: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  reservationStartDate: PropTypes.string,
  reservationCompany: PropTypes.string,
  reservationId: PropTypes.string,
  reservationItemId: PropTypes.string,
  reservationStatus: PropTypes.string,
  serviceRequest: PropTypes.instanceOf(Map),
}

Header.defaultProps = {
  id: '',
  designation: '',
  isAcknowledged: false,
  loading: false,
  company: '',
  contactNumber: '',
  email: '',
  name: '',
  reservationStartDate: '',
  reservationCompany: '',
  reservationId: '',
  reservationItemId: '',
  reservationStatus: '',
  serviceRequest: new Map(),
}


export default Header
