import {
  takeLatest,
} from 'redux-saga/effects'
import { ORDER } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  list, get, create, update, destroy,
} from './factory'


export default function* orderSaga() {
  yield takeLatest(LIST(ORDER), list)
  yield takeLatest(GET(ORDER), get)
  yield takeLatest(CREATE(ORDER), create)
  yield takeLatest(UPDATE(ORDER), update)
  yield takeLatest(DELETE(ORDER), destroy)
}
