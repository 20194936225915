import {
  takeLatest,
} from 'redux-saga/effects'
import { ORDER_ITEM } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  list, get, create, update, destroy,
} from './factory'

export default function* orderItemSaga() {
  yield takeLatest(LIST(ORDER_ITEM), list)
  yield takeLatest(GET(ORDER_ITEM), get)
  yield takeLatest(CREATE(ORDER_ITEM), create)
  yield takeLatest(UPDATE(ORDER_ITEM), update)
  yield takeLatest(DELETE(ORDER_ITEM), destroy)
}
