import React from 'react'
import { history } from 'store'
import { ConnectedRouter } from 'connected-react-router/immutable'


function History({ children }) {
  return (
    <ConnectedRouter history={history}>
      {children}
    </ConnectedRouter>
  )
}

export default History
