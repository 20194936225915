import React from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { SUPPLIER } from 'constants/resources'

function CategorySelect(props) {
  const { t } = useTranslation(['common', SUPPLIER])
  const options = [
    { label: t('supplier:categories.fencing'), value: 'fencing' },
    { label: t('supplier:categories.scaffolding'), value: 'scaffolding' },
    { label: t('supplier:categories.general'), value: 'general' },
    { label: t('supplier:categories.sanitization'), value: 'sanitization' },
    { label: t('supplier:categories.trailers'), value: 'trailers' },
    { label: t('supplier:categories.containers'), value: 'containers' },
    { label: t('supplier:categories.transport'), value: 'transport' },
    { label: t('supplier:categories.mechanics'), value: 'mechanics' },
    { label: t('supplier:categories.other'), value: 'other' },
  ]
  return (
    <Input
      label={t('common:resources.categories.singular')}
      type="select"
      multiple
      placeholder={t('supplier:categories.general')}
      options={options}
      {...props}
    />
  )
}

export default CategorySelect
