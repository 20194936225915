import React from 'react'
import { Chip } from 'components/common'
import { useDispatch } from 'react-redux'
import { reservationItems, notifications } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function CancelReservationButton({
  show,
  reservationItemId,
  notificationId,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const canCancel = show
  if (!canCancel) return null

  const handleNewOrderClick = () => {
    if (reservationItemId) {
      dispatch(notifications.acknowledge(
        notificationId,
        () => { dispatch(reservationItems.delete(reservationItemId)) },
      ))
    }
  }

  return (
    <Chip
      icon="clear"
      outline
      onClick={handleNewOrderClick}
      label={t('shared.cancel')}
    />
  )
}

CancelReservationButton.propTypes = {
  reservationItemId: PropTypes.string,
  notificationId: PropTypes.string.isRequired,
  show: PropTypes.bool,
}

CancelReservationButton.defaultProps = {
  reservationItemId: '',
  show: false,
}
export default CancelReservationButton
