import React, { useRef, useState, useEffect } from 'react'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function AudioButton({ src, disabled }) {
  const audio = useRef(null)
  const { t } = useTranslation('common')
  const [playing, setPlaying] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const record = audio.current
    const handleEndTrack = () => {
      setPlaying(false)
    }
    const handleLoadedTrack = () => {
      setLoaded(true)
    }
    setLoaded(false)
    setPlaying(false)
    if (record) {
      record.addEventListener('ended', handleEndTrack)
      record.addEventListener('loadeddata', handleLoadedTrack)
    }
    return () => {
      if (record) {
        record.removeEventListener('ended', handleEndTrack)
        record.removeEventListener('loadeddata', handleLoadedTrack)
      }
    }
  }, [src])

  const onVoiceNoteClick = () => {
    if (playing) {
      audio.current.pause()
      setPlaying(false)
    } else {
      audio.current.play()
      setPlaying(true)
    }
  }

  if (!src) return null

  return (
    <>
      <Chip
        outline
        disabled={disabled || !loaded}
        icon={playing ? 'pause' : 'play_arrow'}
        onClick={onVoiceNoteClick}
        label={playing ? t('shared.pause') : t('shared.play')}
      />
      <audio src={src} ref={audio} preload="auto" />
    </>
  )
}

AudioButton.propTypes = {
  src: PropTypes.string,
  disabled: PropTypes.bool,
}

AudioButton.defaultProps = {
  src: '',
  disabled: false,
}

export default AudioButton
