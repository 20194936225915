import React from 'react'
import copy from 'copy-to-clipboard'
import { Icon } from 'components/ui'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const hearBeat = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.5);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.5);
  }
  70% {
    transform: scale(1);
  }
`

const CopyStyled = styled.div`
  display: flex;
  align-items: center;
  .copy-icon {
    margin-left: .5em;
    cursor: pointer;
    opacity: 0;
    transition: 300ms ease;
    &:active {
      animation: ${hearBeat} 100ms linear;
    }
  }
  &:hover .copy-icon {
    opacity: 1;
  }
  > * {
    display: inline;
  }
`

function Copy({ text, children, onCopy }) {
  if (!text) return children

  const handleClick = () => {
    if (onCopy) onCopy()
    copy(text)
  }
  return (
    <CopyStyled>
      {children}
      <Icon
        size={14}
        className="copy-icon"
        name="flip_to_front"
        onClick={handleClick}
      />
    </CopyStyled>
  )
}

Copy.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onCopy: PropTypes.func,
}

Copy.defaultProps = {
  onCopy: null,
  text: '',
}

export default Copy
