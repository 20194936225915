import React from 'react'
import {
  Chip, TableRow, TableCell, PreviewLink, PhoneNumber, Flex, FacetFilter,
} from 'components/common'
import PropTypes from 'prop-types'
import { COMPANY, USER } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function UserRow({
  name,
  companyId,
  companyName,
  email,
  phoneNumber,
  active,
  onClick,
  templateColumns,
  isOwner,
  lastLogin,
  shrink,
}) {
  const { t } = useTranslation('common')
  const lastLoginDate = typeof lastLogin === 'string' ? moment(lastLogin).fromNow() : moment(lastLogin * 1000).fromNow()

  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell sm>
        <p>{name}</p>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'serialized_company.id',
            value: companyId,
            label: companyName,
          }}
          resource={USER}
        >
          <PreviewLink
            hideMobile
            resource={COMPANY}
            id={companyId}
          >
            {companyName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell md>
        <a href={`mailto:${email}`}>{email}</a>
      </TableCell>
      <TableCell md>
        <PhoneNumber number={phoneNumber} />
      </TableCell>
      <TableCell lg={!shrink}>
        {
          lastLogin ? <span>{lastLoginDate}</span> : null
        }
      </TableCell>
      <TableCell lg={!shrink}>
        {
          isOwner ? (
            <Flex justify="flex-end">
              <Chip
                secondary={active}
                label={t('common:shared.owner')}
              />
            </Flex>
          ) : null
        }
      </TableCell>
    </TableRow>
  )
}

UserRow.propTypes = {
  name: PropTypes.string,
  lastLogin: PropTypes.number,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
}

UserRow.defaultProps = {
  lastLogin: 0,
  name: '',
  companyId: '',
  companyName: '',
  email: '',
  phoneNumber: '',
  active: false,
  shrink: false,
  onClick: null,
  isOwner: false,
}

export default UserRow
