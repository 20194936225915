window.OneSignal = window.OneSignal || []

window.OneSignal.push(() => {
  window.OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_APP,
    autoRegister: true,
    autoResubscribe: true,
    notifyButton: {
      enable: false,
    },
    persistNotification: true,
    welcomeNotification: {
      title: 'Rentall Construction HQ',
      message: 'You\'ll now receive push notifications.',
      url: process.env.REACT_APP_HOST,
    },
  })
  window.OneSignal.showNativePrompt()
  window.OneSignal.setDefaultNotificationUrl(`${process.env.REACT_APP_HOST}/notifications`)
})
