import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ direction }) => (direction || 'row')};
  align-items: ${({ align }) => (align || 'center')};
  justify-content: ${({ justify }) => (justify || 'flex-start')};
  
  h1, h2, h3, h4, h5, h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }
`

export default Flex
