import {
  SET_APPLICATION_THEME,
  SET_APPLICATION_NOTIFICATION,
  SET_APPLICATION_LANGUAGE,
  SET_APPLICATION_OVERLAY_DISPLAY,
  SET_APPLICATION_OVERLAY_ACTION,
  SET_APPLICATION_HINTS,
  SET_APPLICATION_OVERLAY_RESOURCE,
} from 'store/actions/application'
import Cookies from 'universal-cookie'
import { fromJS } from 'immutable'

const cookies = new Cookies()

export const initialState = fromJS({
  theme: cookies.get('theme') || 'light',
  language: cookies.get('i18next') || 'en',
  showHints: false,
  isNotification: false,
  overlay: {
    active: false,
    action: '',
    resource: '',
  },
})

const application = (state = initialState, { type, payload = {} }) => {
  const { body } = payload
  switch (type) {
    case SET_APPLICATION_THEME:
      return state.set('theme', body)
    case SET_APPLICATION_NOTIFICATION:
      return state.set('isNotification', body)
    case SET_APPLICATION_HINTS:
      return state.set('showHints', body)
    case SET_APPLICATION_LANGUAGE:
      return state.set('language', body)
    case SET_APPLICATION_OVERLAY_DISPLAY:
      return state.setIn(['overlay', 'active'], body)
    case SET_APPLICATION_OVERLAY_ACTION:
      return state.setIn(['overlay', 'action'], body)
    case SET_APPLICATION_OVERLAY_RESOURCE:
      return state.setIn(['overlay', 'resource'], body)
    default: return state
  }
}

export default application
