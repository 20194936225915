import React from 'react'
import { CategoryRow } from 'components/pages/categories'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { CATEGORY } from 'constants/resources'
import {
  useResource, useStoreAlgolia, useSelectResource, useDeviceColumns, useFacetFilters,
} from 'components/hooks'
import { categories } from 'store/action-creators'
import PropTypes from 'prop-types'

function CategoryContent({
  hits, hasMore, refineNext, hasPrevious, refinePrevious, columns,
}) {
  const selected = useResource(CATEGORY, 'current', 'id')
  const list = useStoreAlgolia(CATEGORY, categories, hits)
  const loading = useResource(CATEGORY, 'list', 'loading')
  const handleRowClick = useSelectResource(CATEGORY)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(CATEGORY)

  return (
    <TableContent
      next={refineNext}
      dataLength={hits.length}
      hasMore={hasMore}
    >
      <Configure
        hitsPerPage={40}
        facetFilters={facetFilters}
      />
      <TableLoading loading={loading} />
      {
       list.map(category => (
         <CategoryRow
           key={category.get('id')}
           id={category.get('id')}
           shrink={!!selected}
           templateColumns={templateColumns}
           name={category.get('name')}
           translation={category.getIn(['translations', 'fr', 'name'])}
           subcategory={!!category.get('parent_id') || !!category.get('parent')}
           active={category.get('id') === selected}
           onClick={() => handleRowClick(category.get('id'))}
         />
       ))
      }
    </TableContent>
  )
}

CategoryContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  shrink: PropTypes.bool,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired,
}

export default connectInfiniteHits(CategoryContent)
