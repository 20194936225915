import React from 'react'
import { TableHeader, TableCell, Sort } from 'components/common'
import { useTranslation } from 'react-i18next'
import { COMPANY } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'
import PropTypes from 'prop-types'
import {
  COMPANY_INDEX,
  COMPANY_TOTALUSERS_ASC_INDEX,
  COMPANY_TOTALUSERS_DESC_INDEX,
  COMPANY_ONRENTORDERS_ASC_INDEX,
  COMPANY_ONRENTORDERS_DESC_INDEX,
  COMPANY_TOTALORDERS_ASC_INDEX,
  COMPANY_TOTALORDERS_DESC_INDEX,
} from 'constants/algolia'
import { connectSortBy } from 'react-instantsearch-dom'
import { useDispatch } from 'react-redux'
import { companies } from 'store/action-creators'

function CompanyHeader({
  refine, columns,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', COMPANY])
  const selected = useResource(COMPANY, 'current', 'id')
  const currentIndex = useResource(COMPANY, 'list', 'search', 'index')
  const templateColumns = useDeviceColumns(columns, !!selected)

  const handleSortClick = (value) => {
    dispatch(companies.setListIndex(value))
    if (refine) refine(value)
  }

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell sm>
        {t('common:shared.name')}
      </TableCell>
      <TableCell md>
        <p>{t('common:shared.owner')}</p>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={COMPANY_INDEX}
          ascendantIndex={COMPANY_TOTALUSERS_ASC_INDEX}
          descendantIndex={COMPANY_TOTALUSERS_DESC_INDEX}
        >
          {t('company:header.totalUsers')}
        </Sort>
      </TableCell>
      <TableCell sm>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={COMPANY_INDEX}
          ascendantIndex={COMPANY_ONRENTORDERS_ASC_INDEX}
          descendantIndex={COMPANY_ONRENTORDERS_DESC_INDEX}
        >
          {t('company:header.onRent')}
        </Sort>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={COMPANY_INDEX}
          ascendantIndex={COMPANY_TOTALORDERS_ASC_INDEX}
          descendantIndex={COMPANY_TOTALORDERS_DESC_INDEX}
        >
          {t('company:header.totalOrders')}
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:shared.salesRep')}</p>
      </TableCell>
    </TableHeader>
  )
}

CompanyHeader.propTypes = {
  columns: PropTypes.object.isRequired,
  refine: PropTypes.func,
}

CompanyHeader.defaultProps = {
  refine: null,
}

export default connectSortBy(CompanyHeader)
