import React from 'react'
import PropTypes from 'prop-types'
import {
  Detail, PreviewLink,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EQUIPMENT } from 'constants/resources'


function ReservationItem({
  id,
  equipmentId,
  equipmentName,
  quantity,
  loading,
}) {
  const { t } = useTranslation('common')

  if (!id) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Detail
          text={equipmentName}
          loading={loading}
          label={t('common:resources.equipment.singular')}
        >
          <PreviewLink
            resource={EQUIPMENT}
            id={equipmentId}
          >
            {equipmentName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={quantity}
          loading={loading}
          label={t('common:shared.quantity')}
        >
          {quantity}
        </Detail>
      </Grid>
    </Grid>
  )
}


ReservationItem.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  quantity: PropTypes.number,
}

ReservationItem.defaultProps = {
  id: '',
  loading: false,
  equipmentName: '',
  equipmentId: '',
  quantity: 0,
}


export default ReservationItem
