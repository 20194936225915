import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { TableRow, InlineTable } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { Link } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { usePaginatedList } from 'components/hooks'
import { list as listCategories } from 'services/categories'

function SubcategoryList({
  categoryId, onClick,
}) {
  const { t } = useTranslation('common')
  const options = useMemo(() => (categoryId ? ({
    parent: categoryId,
    limit: 5,
  }) : null), [categoryId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listCategories, options)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('common:resources.subcategories.singular')} (${count})`}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(category => (
            <TableRow
              key={category.get('id')}
              fullSize
              templateColumns="1fr"
            >
              <div onClick={() => {
                if (onClick) onClick(category.get('id'))
              }}
              >
                <Link>
                  {category.get('name')}
                </Link>
              </div>
            </TableRow>
          ))
        }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

SubcategoryList.propTypes = {
  categoryId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

SubcategoryList.defaultProps = {
  onClick: null,
}


export default SubcategoryList
