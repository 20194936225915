import React from 'react'
import { ServiceRequestDetail } from 'components/pages/service-requests'
import { RequestsTable } from 'components/pages/requests'
import { TableLayout } from 'components/layouts'
import { SERVICE_REQUEST } from 'constants/resources'
import LocalShipping from '@material-ui/icons/LocalShipping'
import Receipt from '@material-ui/icons/Receipt'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import { serviceRequests } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

function Requests() {
  const dispatch = useDispatch()
  const { t } = useTranslation(SERVICE_REQUEST)
  const actions = [
    {
      icon: <LocalShipping />,
      name: t('serviceRequest:shared.off-rent'),
      onClick: () => { dispatch(serviceRequests.setCreateData({ designation: 'off-rent' })) },
    },
    {
      icon: <Receipt />,
      name: t('serviceRequest:shared.order'),
      onClick: () => { dispatch(serviceRequests.setCreateData({ designation: 'order' })) },
    },
    {
      icon: <ShoppingBasket />,
      name: t('serviceRequest:shared.rental-price'),
      onClick: () => { dispatch(serviceRequests.setCreateData({ designation: 'rental-price' })) },
    },
    {
      icon: <ShoppingCart />,
      name: t('serviceRequest:shared.purchase-price'),
      onClick: () => { dispatch(serviceRequests.setCreateData({ designation: 'purchase-price' })) },
    },
  ]

  return (
    <TableLayout
      resource={SERVICE_REQUEST}
      table={RequestsTable}
      detail={ServiceRequestDetail}
      actions={actions}
    />
  )
}

export default Requests
