import React from 'react'
// import { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'components/ui/Icon'
import Fab from '@material-ui/core/Fab'

// const popup = keyframes`
//   0% {
//     transform: scale(0);
//   }
//   30% {
//     transform: scale(0.75);
//   }
//   100% {
//     transform: scale(1);
//   }
// `

// const Circle = styled(Button)`
//   width: ${({ size }) => size || '60px'};
//   height: ${({ size }) => size || '60px'};
//   border-radius: ${({ size }) => (size / 2) || '30px'};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0;
//   animation: ${popup};
//   animation-duration: 200ms;
//   * {
//     color: ${({ theme }) => theme.color.primary};
//   }
// `

function CircleButton({ icon, ...otherProps }) {
  return (
    <Fab color="secondary" {...otherProps}>
      <Icon name={icon} color="primary" />
    </Fab>
  )
}

CircleButton.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default CircleButton
