import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import UI from 'pages/Ui'
import Orders from 'pages/Orders'
import OrderItems from 'pages/OrderItems'
import Companies from 'pages/Companies'
import Users from 'pages/Users'
import AdminUsers from 'pages/AdminUsers'
import Equipment from 'pages/Equipment'
import Suppliers from 'pages/Suppliers'
import ServiceCalls from 'pages/ServiceCalls'
import Requests from 'pages/Requests'
import Notifications from 'pages/Notifications'
import Categories from 'pages/Categories'
import PasswordReset from 'pages/PasswordReset'
import Login from 'pages/Login'
import NotFound from 'pages/404'
import PrivateRoute from 'components/common/PrivateRoute'
import routes from 'constants/routes'

function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <Redirect to={routes.orders} />
      </PrivateRoute>
      <PrivateRoute exact path={routes.orders}>
        <Orders />
      </PrivateRoute>
      <PrivateRoute exact path={routes.orderItems}>
        <OrderItems />
      </PrivateRoute>
      <PrivateRoute exact path={routes.users}>
        <Users />
      </PrivateRoute>
      <PrivateRoute exact path={routes.adminUsers}>
        <AdminUsers />
      </PrivateRoute>
      <PrivateRoute exact path={routes.companies}>
        <Companies />
      </PrivateRoute>
      <PrivateRoute exact path={routes.equipment}>
        <Equipment />
      </PrivateRoute>
      <PrivateRoute exact path={routes.categories}>
        <Categories />
      </PrivateRoute>
      <PrivateRoute exact path={routes.suppliers}>
        <Suppliers />
      </PrivateRoute>
      <PrivateRoute exact path={routes.serviceCalls}>
        <ServiceCalls />
      </PrivateRoute>
      <PrivateRoute exact path={routes.requests}>
        <Requests />
      </PrivateRoute>
      <PrivateRoute exact path={routes.notifications}>
        <Notifications />
      </PrivateRoute>
      <Route exact path={routes.login}>
        <Login />
      </Route>
      <Route exact path={routes.reset}>
        <PasswordReset />
      </Route>
      <Route exact path="/ui">
        <UI />
      </Route>
      <Route exact path={routes.notFound}>
        <NotFound />
      </Route>
      <Redirect to={routes.orders} />
    </Switch>
  )
}

export default Routes
