import React from 'react'
import { ServiceRequestTable, ServiceRequestDetail } from 'components/pages/service-requests'
import { TableLayout } from 'components/layouts'
import { SERVICE_REQUEST } from 'constants/resources'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'

function ServiceCalls() {
  const dispatch = useDispatch()

  const handleOnNew = () => {
    dispatch(serviceRequests.setCreateData({ designation: 'service-call' }))
  }

  return (
    <TableLayout
      resource={SERVICE_REQUEST}
      table={ServiceRequestTable}
      detail={ServiceRequestDetail}
      onNew={handleOnNew}
    />
  )
}

export default ServiceCalls
