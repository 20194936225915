import {
  takeLatest,
} from 'redux-saga/effects'
import { COMPANY } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  create, list, get, update, destroy,
} from './factory'

export default function* companySaga() {
  yield takeLatest(LIST(COMPANY), list)
  yield takeLatest(GET(COMPANY), get)
  yield takeLatest(CREATE(COMPANY), create)
  yield takeLatest(UPDATE(COMPANY), update)
  yield takeLatest(DELETE(COMPANY), destroy)
}
