import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/equipment/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/equipment/${id}/`)
)

export const create = async ({
  name,
  description,
  code,
  translations,
  is_promoted,
  is_active,
  search_terms,
  category,
}) => (
  api.post('/app/equipment/', {
    name,
    description,
    code,
    translations,
    is_promoted,
    is_active,
    search_terms,
    category,
  })
)

export const update = async (id, {
  name,
  description,
  code,
  translations,
  is_promoted,
  is_active,
  search_terms,
  category,
}) => (
  api.patch(`/app/equipment/${id}/`, {
    name,
    description,
    code,
    translations,
    is_promoted,
    is_active,
    search_terms,
    category,
  })
)

export const updatePicture = async (id, body) => (
  api.patch(`/app/equipment/${id}/`, body)
)

export const destroy = async id => (
  api.delete(`/app/equipment/${id}/`)
)
