import {
  SET_NAVIGATION_QUERY,
  DELETE_NAVIGATION_QUERY,
} from 'store/actions/navigation'
import { createAction } from './factory'


export default {
  setQuery: createAction(SET_NAVIGATION_QUERY),
  removeQuery: createAction(DELETE_NAVIGATION_QUERY),
}
