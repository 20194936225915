import Noty from 'noty'

Noty.overrideDefaults({
  layout: 'topRight',
  closeWith: ['click'],
  progressBar: true,
  timeout: 3000,
  animation: {
    open: 'noty_effects_open',
    close: 'noty_effects_close',
  },
})
