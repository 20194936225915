import React, { useState } from 'react'
import { Menu } from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  CREATED, IN_PROGRESS, CANCELLED, RESOLVED, RE_OPENED,
} from 'constants/status'
import PropTypes from 'prop-types'
import { SERVICE_REQUEST } from 'constants/resources'
import { useStatusColors } from 'components/hooks'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'

function Status({ defaultValue, onChange }) {
  const dispatch = useDispatch()
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const color = useStatusColors(SERVICE_REQUEST)
  const [selected, setSelected] = useState(defaultValue)
  // const canEdit = ![CANCELLED, RESOLVED].includes(defaultValue)

  const handleUpdateSuccess = (response) => {
    setSelected(response.status)
  }

  const handleChange = (status) => {
    if (onChange) {
      onChange(status)
      setSelected(status)
    } else {
      let confirmed = true
      const body = { status }
      if ([CANCELLED, RESOLVED].includes(status)) {
        confirmed = window.confirm(t('error:status.warning'))
      }
      if (status === 're-opened') {
        body.state = window.prompt(t('serviceRequest:shared.statePrompt'))
        confirmed = !!body.state
      }
      if (confirmed) {
        setSelected(status)
        dispatch(serviceRequests.update(
          body,
          handleUpdateSuccess,
        ))
      }
    }
  }
  return (
    <Menu
      value={selected}
      // disabled={!canEdit}
      options={[
        { label: t(`status.${CREATED}`), value: CREATED, color: color[CREATED] },
        { label: t(`status.${IN_PROGRESS}`), value: IN_PROGRESS, color: color[IN_PROGRESS] },
        { label: t(`status.${RESOLVED}`), value: RESOLVED, color: color[RESOLVED] },
        { label: t(`status.${CANCELLED}`), value: CANCELLED, color: color[CANCELLED] },
        { label: t(`status.${RE_OPENED}`), value: RE_OPENED, color: color[RE_OPENED] },
      ]}
      onChange={handleChange}
    />
  )
}

Status.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

Status.defaultProps = {
  defaultValue: '',
}


export default Status
