import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const StyledSectionHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${({ fullSize }) => (fullSize ? 0 : 8)}px;
`

function SectionHeader({ title, children, fullSize }) {
  return (
    <StyledSectionHeader fullSize={fullSize}>
      <h3>{title}</h3>
      <div>
        {children}
      </div>
    </StyledSectionHeader>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  fullSize: PropTypes.bool,
}

SectionHeader.defaultProps = {
  fullSize: false,
}

export default SectionHeader
