import {
  takeLatest,
  call,
  select,
  put,
} from 'redux-saga/effects'
import { SUPPLIER } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  create as createService,
  update as updateService,
  updatePriceSheet as updatePriceSheetService,
} from 'services/suppliers'
import { EDIT, NEW } from 'constants/crud'
import { suppliers } from 'store/action-creators'
import notify from 'utils/notify'
import i18n from 'config/i18n'
import {
  list, get, destroy,
} from './factory'
import nprogress from './nprogress'
import { closePreview } from './preview'

function* create({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { priceSheet } = body
  yield put(suppliers.setCreateLoading(true))
  const response = yield call(createService, body)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCreate', { resource: response.name }),
    })
    yield put(suppliers.prependListItem(response))
    yield call(closePreview, SUPPLIER, NEW)
    yield put(suppliers.setCurrentData(response))
    if (priceSheet) {
      const priceSheetResponse = yield call(updatePriceSheetService, response.id, priceSheet)
      yield put(suppliers.setCurrentData(priceSheetResponse))
    }
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(suppliers.setCreateLoading(false))
}

function* update({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { priceSheet } = body
  yield put(suppliers.setUpdateLoading(true))
  const id = yield select(store => store.getIn([SUPPLIER, 'current', 'id']))
  const response = yield call(updateService, id, body)
  let priceSheetResponse = null
  if (priceSheet) {
    priceSheetResponse = yield call(updatePriceSheetService, id, priceSheet)
  }
  if (response) {
    notify({
      text: i18n.t('common:shared.successUpdate', { resource: response.name }),
    })
    yield put(suppliers.updateListItem(response))
    yield call(closePreview, SUPPLIER, EDIT)
    yield put(suppliers.setCurrentData(response))
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  if (priceSheetResponse) {
    notify({
      text: i18n.t('error:priceSheet.delay'),
    })
    yield put(suppliers.setCurrentData(priceSheetResponse))
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(suppliers.setUpdateLoading(false))
}

export default function* supplierSaga() {
  yield takeLatest(LIST(SUPPLIER), list)
  yield takeLatest(GET(SUPPLIER), get)
  yield takeLatest(CREATE(SUPPLIER), create)
  yield takeLatest(UPDATE(SUPPLIER), update)
  yield takeLatest(DELETE(SUPPLIER), destroy)
}
