import React from 'react'
import MaterialIcon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'

function Icon({
  name, color, size, style, ...otherProps
}) {
  return (
    <MaterialIcon
      color={color}
      style={{ color, fontSize: size, ...style }}
      {...otherProps}
    >
      {name}
    </MaterialIcon>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
}

Icon.defaultProps = {
  color: '',
}

export default Icon
