import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { useSelector } from 'react-redux'
import { APP } from 'constants/algolia'
import { Configure, InstantSearch } from 'react-instantsearch-dom'

const withAlgolia = (indexName, filters = null) => WrappedComponent => (props) => {
  const token = useSelector(state => state.getIn(['session', 'algoliaToken']))
  const searchClient = algoliasearch(APP, token)
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      refresh
    >
      {
        filters ? (
          <Configure
            filters={filters}
          />
        ) : null
      }
      <WrappedComponent {...props} />
    </InstantSearch>
  )
}

export default withAlgolia
