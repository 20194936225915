import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  padding: 10px ${({ fullSize }) => (fullSize ? 0 : '16px')};
  column-gap: 20px;
  background-color: ${({ theme, active }) => active && theme.color.secondary};
  height: ${({ height }) => height}px;
  align-items: center;
  position: relative;
  cursor: ${({ onClick }) => onClick && 'pointer'};
  transition: background-color 0s;
  transition: color 0s;
  border-bottom: 1px solid ${({ theme }) => theme.color.primary200};
  opacity: ${({ disabled }) => disabled && 0.5};
  p, span, a {
    color: ${({ theme, active }) => active && theme.color.primary};
    font-weight: ${({ active }) => (active ? 500 : 400)};
    &::after{
      transition: background-color 0s;
      background-color: ${({ theme, active }) => active && theme.color.primary};
    }
  }
`

function Row({
  templateColumns,
  children,
  active,
  height,
  fullSize,
  ...otherProps
}) {
  return (
    <RowGrid
      active={active}
      fullSize={fullSize}
      height={height}
      templateColumns={templateColumns}
      {...otherProps}
    >
      {children}
    </RowGrid>
  )
}

Row.propTypes = {
  templateColumns: PropTypes.string.isRequired,
  active: PropTypes.bool,
  height: PropTypes.number,
  fullSize: PropTypes.bool,
}

Row.defaultProps = {
  active: false,
  height: 30,
  fullSize: false,
}

export default Row
