import styled from 'styled-components'
import PropTypes from 'prop-types'

const Card = styled.div`
  background-color: ${({ theme }) => theme.color.primary100};
  border-color: ${({ theme }) => theme.color.primary300};
  border-width: 2px;
  border-style: solid;
  padding: ${({ theme, padding }) => (padding || theme.spacing.medium)}px;
  width: ${({ width }) => width && `${width}px`};
  border-radius: ${({ theme }) => theme.radius}px;
  box-sizing: border-box;
`

Card.propTypes = {
  padding: PropTypes.number,
  width: PropTypes.number,
}

Card.defaultProps = {
  padding: 0,
}

export default Card
