import React from 'react'
import { OrderRow } from 'components/pages/orders'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { ORDER } from 'constants/resources'
import {
  useResource,
  useStoreAlgolia,
  useStatusColors,
  useSelectResource,
  useDeviceColumns,
  useFacetFilters,
  useFilters,
} from 'components/hooks'
import { orders } from 'store/action-creators'
import PropTypes from 'prop-types'

function OrderContent({
  hits, hasMore, refineNext, hasPrevious, refinePrevious, columns,
}) {
  const statusColors = useStatusColors(ORDER)
  const selected = useResource(ORDER, 'current', 'id')
  const list = useStoreAlgolia(ORDER, orders, hits)
  const loading = useResource(ORDER, 'list', 'loading')
  const handleRowClick = useSelectResource(ORDER)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(ORDER)
  const filters = useFilters(ORDER)

  return (
    <TableContent
      next={refineNext}
      dataLength={hits.length}
      hasMore={hasMore}
    >
      <Configure
        hitsPerPage={40}
        facetFilters={facetFilters}
        filters={filters}
      />
      <TableLoading loading={loading} />
      {
        list.map(order => (
          <OrderRow
            statusColor={statusColors[order.get('status')]}
            key={order.get('id')}
            id={order.get('id')}
            shrink={!!selected}
            templateColumns={templateColumns}
            internalCode={order.get('internal_code')}
            status={order.get('status')}
            startDate={order.get('start_date')}
            billingDate={order.get('billing_date')}
            equipment={order.get('equipment')}
            companyName={order.getIn(['company', 'name'])}
            companyId={order.getIn(['company', 'id'])}
            equipmentList={order.get('items')}
            street={order.get('address_line1')}
            zipCode={order.get('address_zip')}
            city={order.get('address_city')}
            country={order.getIn(['address_country', 'name'])}
            state={order.get('address_state')}
            active={order.get('id') === selected}
            onClick={() => handleRowClick(order.get('id'))}
            salesRepName={order.getIn(['sales_rep', 'name'])}
            salesRepId={order.getIn(['sales_rep', 'id'])}
          />
        ))
        }
    </TableContent>
  )
}

OrderContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired,
}


export default connectInfiniteHits(OrderContent)
