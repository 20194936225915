import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/ui/Loader'
import ButtonUI from 'components/ui/Button'

function Button({
  loading, disabled, children, ...otherProps
}) {
  return (
    <ButtonUI
      disabled={disabled || loading}
      {...otherProps}
    >
      { children }
      {
        loading ? (
          <Loader
            className="loader"
            style={{ position: 'absolute', right: '7px' }}
          />
        ) : null
      }
    </ButtonUI>
  )
}

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  loading: false,
  disabled: false,
}

export default Button
