import { useQuickPreview } from 'components/hooks'
import { useCallback } from 'react'
import actions from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { SM } from 'constants/breakpoints'
import { SHOW } from 'constants/crud'

function useSelectResource(resource) {
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()

  return useCallback(
    (id) => {
      if (window.innerWidth <= SM) {
        openPreview(resource, SHOW)
      }
      dispatch(actions[resource].setCurrentId(id))
      dispatch(actions[resource].get(id))
      dispatch(actions.navigation.setQuery({ id }))
    },
    [dispatch, resource, openPreview],
  )
}

export default useSelectResource
