import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects'
import { RESERVATION, ORDER } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  createOrder as createOrderService,
  cancel as cancelService,
} from 'services/reservations'
import {
  CREATE_RESERVATION_ORDER,
  CANCEL_RESERVATION,
} from 'store/actions/reservations'
import { notify } from 'utils'
import { SHOW } from 'constants/crud'
import { orders } from 'store/action-creators'
import i18n from 'config/i18n'
import {
  list, get, create, update, destroy,
} from './factory'
import nprogress from './nprogress'
import { openPreview } from './preview'

function* createOrder({ payload }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  const response = yield call(createOrderService, id)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCreate', { resource: response.name }),
    })
    yield put(orders.setCurrentId(response.id))
    yield put(orders.setCurrentData(response))
    yield call(openPreview, ORDER, SHOW)
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
}

function* cancelReservation({ payload }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  const response = yield call(cancelService, id)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCancel'),
    })
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
}

export default function* reservationSaga() {
  yield takeLatest(LIST(RESERVATION), list)
  yield takeLatest(GET(RESERVATION), get)
  yield takeLatest(CREATE(RESERVATION), create)
  yield takeLatest(CREATE_RESERVATION_ORDER, createOrder)
  yield takeLatest(UPDATE(RESERVATION), update)
  yield takeLatest(CANCEL_RESERVATION, cancelReservation)
  yield takeLatest(DELETE(RESERVATION), destroy)
}
