import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { List } from 'immutable'

function useFacetFilters(resource) {
  const storeFacetFilters = useSelector(state => state.getIn([resource, 'list', 'search', 'facetFilters']))

  return useMemo(() => {
    if (!storeFacetFilters.size) return []

    const facetFilters = []
    storeFacetFilters.forEach((value, key) => {
      if (List.isList(value)) {
        const multiple = []
        value.forEach((val) => {
          multiple.push(`${key}:${val.get('value')}`)
        })
        facetFilters.push(multiple)
      } else {
        facetFilters.push(`${key}:${value.get('value')}`)
      }
    })
    return facetFilters
  }, [storeFacetFilters])
}

export default useFacetFilters
