import React from 'react'
import {
  AddressLink, Chip, Date, TableRow, PreviewLink, TableCell, FacetFilter,
} from 'components/common'
import {
  EQUIPMENT, ORDER_ITEM, COMPANY, USER,
} from 'constants/resources'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ACTIVE } from 'constants/status'

function OrderItemRow({
  internalCode,
  status,
  equipmentName,
  equipmentId,
  quantity,
  offRentedAt,
  orderCode,
  orderId,
  active,
  onClick,
  templateColumns,
  statusColor,
  shrink,
  companyName,
  companyId,
  startDate,
  billingDate,
  street,
  city,
  state,
  zipCode,
  country,
  salesRepName,
  salesRepId,
}) {
  const { t } = useTranslation('orderItem')
  const offRentedAtFormatted = typeof offRentedAt === 'string' ? offRentedAt : offRentedAt * 1000
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell md>
        <p>{internalCode}</p>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'status',
            value: status,
            label: t(`orderItem:status.${status}`),
          }}
          resource={ORDER_ITEM}
        >
          <Chip
            background={statusColor}
            secondary={active && !status === ACTIVE}
            label={t(`orderItem:status.${status}`)}
          />
        </FacetFilter>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'serialized_company.id',
            value: companyId,
            label: companyName,
          }}
          resource={ORDER_ITEM}
        >
          <PreviewLink
            hideMobile
            resource={COMPANY}
            id={companyId}
          >
            {companyName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell xl={!shrink}>
        <AddressLink
          city={city}
          zipCode={zipCode}
          state={state}
          country={country}
        >
          {street}
        </AddressLink>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'serialized_equipment.id',
            value: equipmentId,
            label: equipmentName,
          }}
          resource={ORDER_ITEM}
        >
          <PreviewLink
            hideMobile
            resource={EQUIPMENT}
            id={equipmentId}
          >
            {equipmentName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell md>
        <span>{quantity}</span>
      </TableCell>
      <TableCell sm>
        <Date variant="date">{startDate}</Date>
      </TableCell>
      <TableCell lg={!shrink}>
        <Date variant="date">{billingDate}</Date>
      </TableCell>
      <TableCell lg={!shrink}>
        <Date variant="datetime">{offRentedAtFormatted}</Date>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_order.sales_rep.id',
            value: salesRepId,
            label: salesRepName,
          }}
          resource={ORDER_ITEM}
        >
          <PreviewLink
            hideMobile
            resource={USER}
            id={salesRepId}
          >
            {salesRepName}
          </PreviewLink>
        </FacetFilter>

      </TableCell>
    </TableRow>
  )
}

OrderItemRow.propTypes = {
  internalCode: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  orderCode: PropTypes.string,
  orderId: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  billingDate: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  quantity: PropTypes.number,
  offRentedAt: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
}

OrderItemRow.defaultProps = {
  internalCode: '',
  salesRepName: '',
  salesRepId: '',
  status: '',
  statusColor: '',
  equipmentName: '',
  equipmentId: '',
  orderCode: '',
  orderId: '',
  active: false,
  shrink: false,
  onClick: null,
  quantity: 0,
  offRentedAt: '',
  companyName: '',
  companyId: '',
  startDate: '',
  billingDate: '',
  street: '',
  zipCode: '',
  state: '',
  city: '',
  country: '',
}

export default OrderItemRow
