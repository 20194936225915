import {
  takeLatest,
} from 'redux-saga/effects'
import { CATEGORY } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  list, get, create, update, destroy,
} from './factory'


export default function* categorySaga() {
  yield takeLatest(LIST(CATEGORY), list)
  yield takeLatest(GET(CATEGORY), get)
  yield takeLatest(CREATE(CATEGORY), create)
  yield takeLatest(UPDATE(CATEGORY), update)
  yield takeLatest(DELETE(CATEGORY), destroy)
}
