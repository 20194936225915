import React from 'react'
import { CompanyHeader, CompanyControls, CompanyContent } from 'components/pages/companies'
import { withAlgolia } from 'components/hocs'
import { COMPANY } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import {
  COMPANY_INDEX,
  COMPANY_TOTALUSERS_ASC_INDEX,
  COMPANY_TOTALUSERS_DESC_INDEX,
  COMPANY_ONRENTORDERS_ASC_INDEX,
  COMPANY_ONRENTORDERS_DESC_INDEX,
  COMPANY_TOTALORDERS_ASC_INDEX,
  COMPANY_TOTALORDERS_DESC_INDEX,
} from 'constants/algolia'

function CompanyTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '3fr 1fr',
    md: '2fr 1fr 1fr 1fr 1fr',
    lg: '2fr 1fr 1fr 1fr 1fr 1fr',
    xl: '2fr 1fr 1fr 1fr 1fr 1fr',
  }

  return (
    <Table
      title={t('resources.companies.plural')}
      controls={<CompanyControls />}
      resource={COMPANY}
      header={(
        <CompanyHeader
          columns={columns}
          defaultRefinement={COMPANY_INDEX}
          items={[
            COMPANY_INDEX,
            COMPANY_TOTALUSERS_ASC_INDEX,
            COMPANY_TOTALUSERS_DESC_INDEX,
            COMPANY_ONRENTORDERS_ASC_INDEX,
            COMPANY_ONRENTORDERS_DESC_INDEX,
            COMPANY_TOTALORDERS_ASC_INDEX,
            COMPANY_TOTALORDERS_DESC_INDEX,
          ]}
        />
        )}
      content={(
        <CompanyContent
          columns={columns}
        />
)}
    />
  )
}

export default withAlgolia(COMPANY_INDEX)(CompanyTable)
