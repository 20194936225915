import React, { useState, useEffect } from 'react'
import {
  Chip, Flex, Input, Button,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { equipment } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { CategorySelect, SubcategorySelect } from 'components/pages/categories'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { SEARCH_TERMS } from 'constants/patterns'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { EQUIPMENT } from 'constants/resources'

function Form({ current, loading, update }) {
  const [file, setFile] = useState(null)
  const { t } = useTranslation(['common', EQUIPMENT, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, setValue, getValues, register, watch,
  } = useForm({ mode: 'onBlur' })
  const chipLabel = watch('is_promoted') ? t('equipment:shared.promoted') : t('equipment:shared.unpromoted')
  const isParent = !current.getIn(['category', 'parent'])

  useEffect(() => {
    if (typeof getValues('is_promoted') === 'undefined') {
      register({ name: 'is_promoted' })
      setValue('is_promoted', current.get('is_promoted'))
    }
  }, [current, getValues, register, setValue])

  const onSubmit = (data) => {
    let formData
    if (file) {
      formData = new FormData()
      formData.append('image', file)
    }
    const body = {
      ...data,
      image: formData,
      is_active: true,
      search_terms: data.search_terms && data.search_terms.replace(' ', ''),
      category: data.subcategory || data.category,
    }
    if (update) {
      dispatch(equipment.update(body))
    } else {
      dispatch(equipment.create(body))
    }
  }

  const handleChipClick = () => {
    setValue('is_promoted', !getValues('is_promoted'))
  }

  const handleCategoryChange = ([data]) => {
    setValue('subcategory', '')
    return data
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        <Chip
          onClick={handleChipClick}
          outline={!watch('is_promoted')}
          label={chipLabel}
        />
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="is_active" ref={register} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:name.required'),
              },
            }}
            error={errors.name?.message}
            type="text"
            label={`${t('common:shared.name')} (${t('common:languages.en')})`}
            placeholder={t('equipment:new.name.en.placeholder')}
            id="new-equipment-name-english"
            defaultValue={current.get('name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:description.required'),
              },
            }}
            error={errors.description?.message}
            type="text"
            multiline
            placeholder={t('equipment:new.description.en.placeholder')}
            label={`${t('common:shared.description')} (${t('common:languages.en')})`}
            id="new-equipment-description-english"
            defaultValue={current.get('description')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="translations.fr.name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:name.required'),
              },
            }}
            error={errors.translations?.fr?.name?.message}
            type="text"
            label={`${t('common:shared.name')} (${t('common:languages.fr')})`}
            placeholder={t('equipment:new.name.fr.placeholder')}
            id="new-equipment-name-french"
            defaultValue={current.getIn(['translations', 'fr', 'name'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="translations.fr.description"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:description.required'),
              },
            }}
            error={errors.translations?.fr?.description?.message}
            type="text"
            multiline
            placeholder={t('equipment:new.description.fr.placeholder')}
            label={`${t('common:shared.description')} (${t('common:languages.fr')})`}
            id="new-equipment-description-french"
            defaultValue={current.getIn(['translations', 'fr', 'description'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={CategorySelect}
            name="category"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:category.required'),
              },
            }}
            error={errors.category?.message}
            id="new-equipment-category"
            defaultValue={isParent ? current.getIn(['category', 'id']) : current.getIn(['category', 'parent'])}
            disabled={loading}
            onChange={handleCategoryChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={SubcategorySelect}
            name="subcategory"
            control={control}
            error={errors.subcategory?.message}
            id="new-equipment-subcategory"
            defaultValue={!isParent && current.getIn(['category', 'id'])}
            disabled={loading}
            value={watch('subcategory')}
            parentId={watch('category') || current.getIn(['category', 'parent'])}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="search_terms"
            control={control}
            rules={{
              pattern: {
                value: SEARCH_TERMS,
                message: t('error:search_terms.pattern'),
              },
            }}
            error={errors.searchTerms?.message}
            type="text"
            multiline
            label={t('equipment:shared.searchTerms')}
            placeholder={t('equipment:new.searchTerms.placeholder')}
            id="new-equipment-search-terms"
            defaultValue={current.get('search_terms')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            type="file"
            max={15}
            name="picture"
            label={t('equipment:shared.picture')}
            maxMessage={t('error:file.max')}
            helperText={t('common:file.helper', { max: 15 })}
            placeholder={t('common:file.placeholder')}
            dropPlaceholder={t('common:file.dropPlaceholder')}
            id="new-equipment-picture"
            onChange={setFile}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('equipment:edit.submit') : t('equipment:new.submit')
        }
      </Button>
    </form>
  )
}


Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}


export default Form
