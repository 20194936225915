import React from 'react'
import PropTypes from 'prop-types'
import {
  Detail, AddressButton, SectionHeader,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

function AddressSection({
  street,
  streetDetails,
  city,
  state,
  zipcode,
  country,
  loading,
}) {
  const { t } = useTranslation('common')

  return (
    <Grid container spacing={2}>
      <SectionHeader title={t('shared.location')}>
        <AddressButton
          street={street}
          zipCode={zipcode}
          city={city}
          state={state}
          country={country}
        />
      </SectionHeader>
      <Grid item xs={12} md={6}>
        <Detail
          text={street}
          loading={loading}
          label={t('address.street.label')}
        >
          { street }
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={streetDetails}
          loading={loading}
          label={t('address.streetDetail.label')}
        >
          { streetDetails }
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={zipcode}
          loading={loading}
          label={t('address.zipcode.label')}
        >
          {zipcode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={city}
          loading={loading}
          label={t('address.city.label')}
        >
          {city}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={state}
          loading={loading}
          label={t('address.state.label')}
        >
          {state}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={country}
          loading={loading}
          label={t('address.country.label')}
        >
          {country}
        </Detail>
      </Grid>
    </Grid>
  )
}


AddressSection.propTypes = {
  street: PropTypes.string,
  streetDetails: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string,
  country: PropTypes.string,
  loading: PropTypes.bool,
}

AddressSection.defaultProps = {
  street: '',
  streetDetails: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  loading: false,
}


export default AddressSection
