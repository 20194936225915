import styled from 'styled-components'
import { Card } from 'components/ui'

const Detail = styled(Card)`
  max-height: 100%; 
  margin: 0;
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > div:first-child {
    flex-grow: 1
  }
`

export default Detail
