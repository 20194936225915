import React from 'react'
import { QuickFilters } from 'components/common'
import { SUPPLIER } from 'constants/resources'
import { useTranslation } from 'react-i18next'

function Filters() {
  const { t } = useTranslation(SUPPLIER)
  return (
    <QuickFilters
      resource={SUPPLIER}
      options={[
        {
          value: {
            attribute: 'categories',
            value: 'fencing',
            label: t('categories.fencing'),
          },
          label: t('categories.fencing'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'scaffolding',
            label: t('categories.scaffolding'),
          },
          label: t('categories.scaffolding'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'general',
            label: t('categories.general'),
          },
          label: t('categories.general'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'sanitization',
            label: t('categories.sanitization'),
          },
          label: t('categories.sanitization'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'trailers',
            label: t('categories.trailers'),
          },
          label: t('categories.trailers'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'containers',
            label: t('categories.containers'),
          },
          label: t('categories.containers'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'transport',
            label: t('categories.transport'),
          },
          label: t('categories.transport'),
        },
        {
          value: {
            attribute: 'categories',
            value: 'mechanics',
            label: t('categories.mechanics'),
          },
          label: t('categories.mechanics'),
        },
      ]}
    />
  )
}

export default Filters
