import React from 'react'
import { ORDER } from 'constants/resources'
import { TableDetail } from 'components/common'
import { useResource, useCurrent } from 'components/hooks'
import Form from './Form'

function Edit() {
  const current = useCurrent(ORDER)
  const loading = useResource(ORDER, 'update', 'loading')
  return (
    <TableDetail>
      <Form
        update
        loading={loading}
        current={current}
      />
    </TableDetail>
  )
}


export default Edit
