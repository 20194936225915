import { ACKNOWLEDGE_NOTIFICATION } from 'store/actions/notifications'
import { NOTIFICATION } from 'constants/resources'
import createActionsWithResource, { createAction } from './factory'

const notifications = createActionsWithResource(NOTIFICATION)

export default {
  ...notifications,
  acknowledge: createAction(ACKNOWLEDGE_NOTIFICATION),
}
