import React from 'react'
import { DetailHeader } from 'components/common'
import PropTypes from 'prop-types'
import PriceListButton from './PriceListButton'

function Header({ priceListUrl, title, loading }) {
  return (
    <DetailHeader title={title} loading={loading}>
      <PriceListButton
        url={priceListUrl}
      />
    </DetailHeader>
  )
}

Header.propTypes = {
  priceListUrl: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
}

Header.defaultProps = {
  priceListUrl: '',
  title: '',
  loading: '',
}

export default Header
