import React from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import {
  Flex, Input, AddressFields, Button, PurchaseOrderField,
} from 'components/common'
import { companies, orders } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { CompanySelect } from 'components/pages/companies'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'
import { DATE } from 'constants/formats'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { ORDER } from 'constants/resources'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import useMultipleContacts from 'components/hooks/useMultipleContacts'
import Status from './Status'


function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', ORDER, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, setValue, getValues,
  } = useForm({ mode: 'onBlur' })
  const {
    fields, onBlur, cleanContacts,
  } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues,
  })

  const onSubmit = (body) => {
    if (update) {
      dispatch(orders.update(cleanContacts(body)))
    } else {
      dispatch(orders.create(cleanContacts(body)))
    }
  }

  const handleCompanyChange = (value) => {
    const [company] = value
    if (!update && company) {
      dispatch(companies.get(company,
        (val) => {
          setValue('sales_rep', val.sales_rep?.id)
        }))
    }
    return company
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        {
          current.get('status') ? (
            <Status
              value={current.get('status')}
            />
          ) : null
      }
      </Flex>
      <Divider spacing={20} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={CompanySelect}
            name="company"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:company.required'),
              },
            }}
            error={errors.company?.message}
            id="new-order-company"
            onChange={handleCompanyChange}
            defaultValue={current.getIn(['company', 'id'])}
            disabled={loading || current.getIn(['company', 'id'])}
            helperText={current.getIn(['metadata', 'company'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-order-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="start_date"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:date.required'),
              },
            }}
            error={errors.start_date?.message}
            type="date"
            label={t('common:shared.startDate')}
            placeholder={moment().format(DATE)}
            id="new-order-start-date"
            defaultValue={current.get('start_date')}
            disabled={loading}
            helperText={current.getIn(['metadata', 'delivery_date'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="billing_date"
            control={control}
            error={errors.billing_date?.message}
            type="date"
            label={t('order:shared.billingDate')}
            placeholder={moment().add(9, 'd').format(DATE)}
            id="new-order-billing-date"
            defaultValue={current.get('billing_date')}
            disabled={loading}
          />
        </Grid>
        {
          fields.map(i => (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].name`}
                  control={control}
                  error={errors.metadata?.contacts[i]?.name?.message}
                  type="text"
                  label={t('order:shared.siteContact')}
                  placeholder={t('order:new.siteContact.placeholder')}
                  id="new-service-request-contact-name"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].number`}
                  control={control}
                  error={errors.metadata?.contacts[i]?.number?.message}
                  type="tel"
                  label={t('common:shared.contactNumber')}
                  id="new-service-request-contact-number"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
            </>
          ))
        }
        <Grid item xs={12} md={6}>
          <Controller
            as={PurchaseOrderField}
            name="external_id"
            control={control}
            error={errors.external_id?.message}
            label={t('order:shared.customerPO')}
            id="new-order-external-id"
            defaultValue={current.get('external_id') || current.getIn(['metadata', 'purchase_order'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PurchaseOrderField}
            name="metadata.rentall_external_id"
            control={control}
            error={errors.metadata?.rentall_external_id?.message}
            label={t('order:shared.rentallPO')}
            id="new-order-rentall-external-id"
            defaultValue={current.getIn(['metadata', 'rentall_external_id'])}
            disabled={loading}
          />
        </Grid>
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
        />
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="notes"
            control={control}
            error={errors.notes?.message}
            type="text"
            label={t('common:shared.notes')}
            placeholder={t('order:new.notes.placeholder')}
            multiline
            id="new-order-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('order:edit.submit') : t('order:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
