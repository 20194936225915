import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CircleButton, Hint, Sidebar } from 'components/common'
import { useQuickPreview, useResource } from 'components/hooks'
import { NEW, EDIT } from 'constants/crud'
import PropTypes from 'prop-types'
import { CANCELLED, RESOLVED, FINALIZED } from 'constants/status'
import { useDispatch } from 'react-redux'
import actionCreators from 'store/action-creators'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'

const Container = styled.div`
  display: flex;
  height: 100%;
  > div:first-child {
    flex-grow: 1;
    min-width: 0;
    flex-basis: 60vw;
  }
  .action-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
  }
`

function TableLayout({
  resource,
  table: Table,
  detail: Detail,
  disableActions,
  onEdit,
  onNew,
  disableNew,
  disableEdit,
  actions,
}) {
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()
  const [openActions, setOpenActions] = useState(false)
  const id = useResource(resource, 'current', 'id')
  const selected = useResource(resource, 'current')
  const canEdit = ![CANCELLED, RESOLVED, FINALIZED].includes(selected.get('status'))

  useEffect(() => {
    // Fetches the resource from the query string.
    const searchParams = new URLSearchParams(window.location.search)
    const queryId = searchParams.get('id')
    if (queryId && !id) {
      dispatch(actionCreators[resource].get(queryId))
    }
  }, [dispatch, id, resource])

  const handleNewClick = () => {
    if (onNew) onNew()
    openPreview(resource, NEW)
  }
  const handleEditClick = () => {
    if (onEdit) onEdit()
    openPreview(resource, EDIT)
  }

  const handleOpen = () => {
    setOpenActions(true)
  }

  const handleClose = () => {
    setOpenActions(false)
  }

  return (
    <Container>
      <Table />
      <Sidebar open={!!id}>
        {
          id ? <Detail /> : null
          }
      </Sidebar>
      {
        !disableActions && !disableNew && !id ? (
          <Hint hotKey="n" className="action-button">
            {
              actions.length ? (
                <SpeedDial
                  ariaLabel="Designation speed dial"
                  // hidden={hidden}
                  FabProps={{
                    color: 'secondary',
                    size: 'large',
                  }}
                  icon={(
                    <SpeedDialIcon
                      icon={<AddIcon color="primary" />}
                      openIcon={<ClearIcon color="primary" />}
                    />
                  )}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={openActions}
                >
                  {actions.map(action => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      FabProps={{
                        color: 'secondary',
                        size: 'large',

                      }}
                      tooltipOpen
                      onClick={() => {
                        if (action.onClick) action.onClick()
                        handleClose()
                        handleNewClick()
                      }}
                    />
                  ))}
                </SpeedDial>
              ) : (
                <CircleButton
                  data-test-id="new-record-button"
                  icon="add"
                  onClick={handleNewClick}
                />
              )
          }
          </Hint>
        ) : null
      }
      {
        !disableActions && !disableEdit && selected.size && canEdit ? (
          <Hint hotKey="n" className="action-button">
            <CircleButton
              data-test-id="edit-record-button"
              icon="edit"
              onClick={handleEditClick}
            />
          </Hint>
        ) : null
      }
    </Container>
  )
}

TableLayout.propTypes = {
  resource: PropTypes.string.isRequired,
  table: PropTypes.any.isRequired,
  detail: PropTypes.any.isRequired,
  disableActions: PropTypes.bool,
  disableNew: PropTypes.bool,
  disableEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onNew: PropTypes.func,
  actions: PropTypes.array,
}

TableLayout.defaultProps = {
  disableActions: false,
  disableNew: false,
  disableEdit: false,
  onEdit: null,
  onNew: null,
  actions: [],
}

export default TableLayout
