import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import { application } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import useNotification from './use-notification'

function useLanguage() {
  const dispatch = useDispatch()
  const notify = useNotification()
  const { t, i18n } = useTranslation('common')
  const language = useSelector(state => state.getIn(['application', 'language']))
  const cookies = new Cookies()

  const setLanguage = useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
      cookies.set('i18next', lang)
      dispatch(application.setLanguage(lang))
      notify({ text: t(`languages.change.${lang}`) })
    },
    [i18n, cookies, dispatch, notify, t],
  )

  return [language, setLanguage]
}

export default useLanguage
