import React from 'react'
import { UserTable, UserDetail } from 'components/pages/users'
import { TableLayout } from 'components/layouts'
import { USER } from 'constants/resources'

function Users() {
  return (
    <TableLayout
      resource={USER}
      table={UserTable}
      detail={UserDetail}
    />
  )
}

export default Users
