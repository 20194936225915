import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Sidebar = styled.div` 
  flex-basis: ${({ open }) => ((open) ? 40 : 0)}vw;
  max-width: 600px;
  margin: 0 0 0 ${({ open }) => open && 12}px;
  transition: flex-basis 100ms ease-out;
  overflow: hidden;
  box-sizing: border-box;
  > div {
    padding-bottom: 100px;
    height: 100%;
    overflow-y: scroll;
    margin: 0;
    &::-webkit-scrollbar {
      /* Width affects directly the Destroy fold */
      display: none;
      width: 4px;
      height: 40%;
      background: transparent;
    }
    &::-webkit-scrollbar-button {
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      /* background-color: blue; */
    }
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      color: teal;
      background-color: ${({ theme }) => theme.color.primary200};
      border-radius: 4px;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
  @media (min-width: 600px) {
  }
`

function SidebarCard({ children, open }) {
  return (
    <Sidebar open={open}>
      {children}
    </Sidebar>
  )
}

SidebarCard.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default SidebarCard
