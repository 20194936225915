import {
  put,
} from 'redux-saga/effects'
import actions, { application } from 'store/action-creators'
import { NEW, SHOW } from 'constants/crud'

export function* openPreview(resource, action) {
  yield put(application.setOverlayDisplay(true))
  yield put(application.setOverlayResource(resource))
  yield put(application.setOverlayAction(action))
}

export function* closePreview(resource, action) {
  if (resource) {
    switch (action) {
      case SHOW:
        yield put(actions[resource].setCurrentId(''))
        yield put(actions[resource].clearCurrentData())
        break
      case NEW:
        yield put(actions[resource].clearCreateData())
        break
      default:
        break
    }
  }
  yield put(application.setOverlayDisplay(false))
  yield put(application.setOverlayAction(''))
  yield put(application.setOverlayResource(''))
}
