import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Loading = styled.div`
  width: 100%;
  height: 200px;
`

function Image({ loading, alt, ...otherProps }) {
  return loading ? <Loading className="loading" /> : <img alt={alt} {...otherProps} />
}

Image.propTypes = {
  loading: PropTypes.bool,
  alt: PropTypes.string,
}

Image.defaultProps = {
  loading: false,
  alt: 'picture',
}

export default Image
