import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useQuickPreview } from 'components/hooks'
import { SERVICE_REQUEST } from 'constants/resources'
import { EDIT } from 'constants/crud'

function AssignButton({
  isOrphan,
}) {
  const { t } = useTranslation(SERVICE_REQUEST)
  const [openPreview] = useQuickPreview()

  const handleChipClick = () => {
    openPreview(SERVICE_REQUEST, EDIT)
  }

  return isOrphan ? (
    <Chip
      outline
      icon="add"
      onClick={handleChipClick}
      label={t('shared.assign')}
    />
  ) : null
}

AssignButton.propTypes = {
  isOrphan: PropTypes.bool,
}

AssignButton.defaultProps = {
  isOrphan: false,
}


export default AssignButton
