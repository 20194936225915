import { fork } from 'redux-saga/effects'
import NProgress from 'nprogress'

function* nprogress(type) {
  if (typeof document === 'undefined') return

  switch (type) {
    case true: yield fork(NProgress.start); break
    case false: yield fork(NProgress.done); break
    default: break
  }
}

export default nprogress
