import React from 'react'
import { TableControls, AlgoliaSearch, ActiveFilters } from 'components/common'
import { equipment } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { EQUIPMENT } from 'constants/resources'
import Hidden from '@material-ui/core/Hidden'

function EquipmentControls(props) {
  const dispatch = useDispatch()
  const handleSearchChange = (value) => {
    dispatch(equipment.setListSearchQuery(value))
  }
  return (
    <TableControls
      {...props}
    >
      <Hidden xsDown>
        <ActiveFilters resource={EQUIPMENT} />
        <AlgoliaSearch onChange={handleSearchChange} />
      </Hidden>
    </TableControls>
  )
}

export default EquipmentControls
