import React from 'react'
import { DateFilter } from 'components/common'
import { ORDER_ITEM } from 'constants/resources'

function Filters() {
  return (
    <>
      {/* <QuickFilters
      resource={ORDER_ITEM}
      options={[
        {
          value: [
            {
              attribute: 'status',
              value: ACCEPTED,
              label: t(`status.${ACCEPTED}`),
            },
            {
              attribute: 'status',
              value: CREATED,
              label: t(`status.${CREATED}`),
            },
            {
              attribute: 'status',
              value: SCHEDULED,
              label: t(`status.${SCHEDULED}`),
            },
            {
              attribute: 'status',
              value: ON_RENT,
              label: t(`status.${ON_RENT}`),
            },
          ],
          label: t('filters.current'),
        },
      ]}
    /> */}
      <div>
        <DateFilter
          resource={ORDER_ITEM}
          id="serialized_order.start_date_timestamp"
          attribute="serialized_order.start_date_timestamp"
          label="Start date"
          icon="today"
        />
      </div>
      <div>
        <DateFilter
          resource={ORDER_ITEM}
          id="serialized_order.billing_date_timestamp"
          attribute="serialized_order.billing_date_timestamp"
          label="Billing date"
          icon="event"
        />
      </div>
    </>
  )
}

export default Filters
