import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  SM, MD, LG, XL,
} from 'constants/breakpoints'

const Cell = styled.div`
  display: none;
  max-width: 100%;
  transition: background-color 0s;
  overflow: hidden;
  p, span, a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* width: 100%; */
    margin: 0;
  }

  * {
    transition: background-color 0ms;
  }

  @media (min-width: ${XL}px) {
    display: ${({
    sm, md, lg, xl,
  }) => (sm || md || lg || xl) && 'block'}; 
  }
  @media (min-width: ${LG}px) {
    display: ${({ sm, md, lg }) => (sm || md || lg) && 'block'}; 
  }
  @media (min-width: ${MD}px) {
    display: ${({ sm, md }) => (sm || md) && 'block'}; 
  }
  @media (max-width: ${SM}px) {
    display: ${({ sm }) => sm && 'block'}; 
  }
`

Cell.propTypes = {
  hide: PropTypes.bool,
}

Cell.defaultProps = {
  hide: false,
}

export default Cell
