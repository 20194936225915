
import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {
  Detail, VoiceNote, SectionHeader, PhoneNumber,
} from 'components/common'
import { useTranslation } from 'react-i18next'
import { SERVICE_REQUEST } from 'constants/resources'

const ClientInputSection = ({
  voiceNoteUrl,
  comments,
  voiceNoteExpiration,
  contactName,
  contactNumber,
  loading,
  action,
}) => {
  const { t } = useTranslation(['common', SERVICE_REQUEST])

  return (contactName || contactNumber || comments || voiceNoteUrl) ? (
    <Grid container spacing={2}>
      <SectionHeader title={t('serviceRequest:shared.clientInput')}>{action}</SectionHeader>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactName}
          loading={loading}
          label={t('common:shared.contactName')}
        >
          {contactName}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.contactNumber')}
        >
          <PhoneNumber number={contactNumber} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          loading={loading}
          label={t('common:shared.voiceNote')}
        >
          <VoiceNote
            src={voiceNoteUrl}
            expiration={voiceNoteExpiration}
          >
            {t('common:shared.voiceNote')}
          </VoiceNote>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={comments}
          loading={loading}
          label={t('serviceRequest:shared.clientNotes')}
        >
          {comments}
        </Detail>
      </Grid>
    </Grid>
  ) : null
}

ClientInputSection.propTypes = {
  action: PropTypes.node,
  comments: PropTypes.string,
  loading: PropTypes.bool,
  voiceNoteUrl: PropTypes.string,
  voiceNoteExpiration: PropTypes.string,
  contactName: PropTypes.string,
  contactNumber: PropTypes.string,
}

ClientInputSection.defaultProps = {
  action: null,
  comments: '',
  voiceNoteUrl: '',
  voiceNoteExpiration: '',
  contactName: '',
  contactNumber: '',
  loading: false,
}
export default ClientInputSection
