import React from 'react'
import styled from 'styled-components'
import { Loader } from 'components/ui'
import Overlay from 'components/common/Overlay'

const CenteredOverlay = styled(Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
`

function Loading() {
  return (
    <CenteredOverlay>
      <Loader />
    </CenteredOverlay>
  )
}

export default Loading
