import React from 'react'
import { DetailHeader } from 'components/common'
import PropTypes from 'prop-types'
import Status from 'components/pages/order-items/Status'
import PlaceService from 'components/pages/order-items/PlaceService'
import EditButton from 'components/pages/order-items/EditButton'
import { Map } from 'immutable'

function Header({
  isEdit,
  title,
  status,
  loading,
  orderItemId,
  orderItem,
  equipmentName,
  small,
}) {
  return (
    <DetailHeader title={equipmentName} loading={loading} variant={small && 'small'}>
      {
        isEdit ? (
          <EditButton orderItem={orderItem} />
        ) : null
      }
      <PlaceService
        designation="service-call"
        status={status}
        orderItemId={orderItemId}
        salesRepId={orderItem.getIn(['order', 'sales_rep', 'id'])}
        addressLine1={orderItem.getIn(['order', 'address_line1'])}
        addressLine2={orderItem.getIn(['order', 'address_line2'])}
        addressZip={orderItem.getIn(['order', 'address_zip'])}
        addressCity={orderItem.getIn(['order', 'address_city'])}
        addressState={orderItem.getIn(['order', 'address_state'])}
        addressCountryCode={orderItem.getIn(['order', 'address_country', 'code'])}
        contacts={orderItem.getIn(['order', 'metadata', 'contacts'])}
      />
      <PlaceService
        designation="off-rent"
        status={status}
        orderItemId={orderItemId}
        salesRepId={orderItem.getIn(['order', 'sales_rep', 'id'])}
        addressLine1={orderItem.getIn(['order', 'address_line1'])}
        addressLine2={orderItem.getIn(['order', 'address_line2'])}
        addressZip={orderItem.getIn(['order', 'address_zip'])}
        addressCity={orderItem.getIn(['order', 'address_city'])}
        addressState={orderItem.getIn(['order', 'address_state'])}
        addressCountryCode={orderItem.getIn(['order', 'address_country', 'code'])}
        contacts={orderItem.getIn(['order', 'metadata', 'contacts'])}
      />
      <Status defaultValue={status} id={orderItemId} />
    </DetailHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  equipmentName: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  small: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  orderItem: PropTypes.instanceOf(Map).isRequired,
  orderItemId: PropTypes.string,
}

Header.defaultProps = {
  title: '',
  equipmentName: '',
  status: '',
  orderItemId: '',
  loading: false,
  isEdit: false,
  small: false,
}


export default Header
