
import { formatCurrency } from 'utils'

function useCurrency(amount, digits = 0) {
  if (typeof amount !== 'number') return amount

  return formatCurrency(amount, digits)
}

export default useCurrency
