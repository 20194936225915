import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isInput } from 'utils'
import { useQuickPreview, useResource } from 'components/hooks'
import { NEW, EDIT } from 'constants/crud'
import {
  escape, n, upArrow, downArrow, tab, r,
} from 'constants/keycodes'
import actions from 'store/action-creators'

function useTableHotkeys(resource) {
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()
  const list = useResource(resource, 'list')
  const selected = useResource(resource, 'current', 'id')
  const isOverlay = useSelector(state => state.getIn(['application', 'overlay', 'active']))

  useEffect(() => {
    const selectPrevious = () => {
      if (selected && list.size) {
        const currentIndex = list.findIndex(comp => comp.get('id') === selected)
        if (currentIndex > 0) {
          dispatch(actions[resource].setCurrentId(list.get(currentIndex - 1).get('id')))
          dispatch(actions[resource].get(list.get(currentIndex - 1).get('id')))
        }
      }
    }

    const selectNext = () => {
      if (!selected && list.size) {
        dispatch(actions[resource].setCurrentId(list.first().get('id')))
        dispatch(actions[resource].get(list.first().get('id')))
      } else if (list.size) {
        const currentIndex = list.findIndex(comp => comp.get('id') === selected)
        if (list.has(currentIndex + 1)) {
          dispatch(actions[resource].setCurrentId(list.get(currentIndex + 1).get('id')))
          dispatch(actions[resource].get(list.get(currentIndex + 1).get('id')))
        }
      }
    }

    const exitDetail = () => {
      if (!isOverlay) {
        dispatch(actions.navigation.removeQuery('id'))
        dispatch(actions[resource].setCurrentId(''))
        dispatch(actions[resource].clearCurrentData())
      }
    }

    const setTableHotkeys = (event) => {
      const { keyCode, target } = event
      if (!isInput(target)) {
        switch (keyCode) {
          case n: openPreview(resource, NEW); break
          case r: if (selected) openPreview(resource, EDIT); break
          case upArrow: selectPrevious(); break
          case downArrow: selectNext(); break
          case tab: event.preventDefault(); selectNext(); break
          case escape: exitDetail(); break
          default: break
        }
      }
    }

    document.addEventListener('keydown', setTableHotkeys)
    return () => { document.removeEventListener('keydown', setTableHotkeys) }
  }, [list, selected, isOverlay, dispatch, resource, openPreview])
}

export default useTableHotkeys
