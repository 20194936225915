import { createGlobalStyle, keyframes } from 'styled-components'

const width = '325px'
const gutter = '20px'
const layout = `
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  transform: translateZ(0) scale(1.0, 1.0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90 %;
`
const notyInd = keyframes`
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`
const notyOut = keyframes`
  100% {
    transform: translate3d(50%, 0, 0);
    opacity: 0;
  }
`

export default createGlobalStyle`
.noty_layout_mixin {
  position: fixed;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.color.primary};
  z-index: 9999999; 
  transform: translateZ(0) scale(1.0, 1.0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%;
}
#noty_layout__top {
  ${layout}
  top: 0;
  left: 5%;
  width: 90%;
}
#noty_layout__topLeft {
  ${layout}
  top: ${gutter};
  left: ${gutter};
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
}
#noty_layout__topCenter {
  ${layout}
  top: 2%;
  left: 50%;
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
}
#noty_layout__topRight {
  ${layout}
  top: ${gutter};
  right: ${gutter};
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
}
#noty_layout__bottom {
  ${layout}
  bottom: 0;
  left: 5%;
  width: 90%;
}
#noty_layout__bottomLeft {
  ${layout}
  bottom: ${gutter};
  left: ${gutter};
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
}
#noty_layout__bottomCenter {
  ${layout}
  bottom: 5%;
  left: 50%;
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
}
#noty_layout__bottomRight {
  ${layout}
  bottom: ${gutter};
  right: ${gutter};
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
}
#noty_layout__center {
  ${layout}
  top: 50%;
  left: 50%;
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
  transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
}
#noty_layout__centerLeft {
  ${layout}
  top: 50%;
  left: ${gutter};
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
}
#noty_layout__centerRight {
  ${layout}
  top: 50%;
  right: ${gutter};
  width: ${width};
  max-width: calc(100% - ${gutter} - ${gutter});
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}
.noty_progressbar {
  display: none;
}
.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  opacity: 0.5;
  filter: alpha(opacity=10)
}
.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1.0, 1.0);
  transform: translate(0, 0) scale(1.0, 1.0);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
}
.noty_effects_open {
  opacity: 0;
  transform: translate3d(50%, 0, 0);
  animation: ${notyInd} 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.noty_effects_close {
  animation: ${notyOut} 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.noty_fix_effects_height {
  animation: noty_anim_height 75ms ease-out;
}
.noty_close_with_click {
  cursor: pointer;
}
.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, .05);
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease-out;
}
.noty_close_button:hover {
  background-color: rgba(0, 0, 0, .1);
}
.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: .3;
  left: 0;
  top: 0;
}
.noty_modal.noty_modal_open {
  opacity: 0;
  animation: noty_modal_in .3s ease-out;
}
.noty_modal.noty_modal_close {
  animation: noty_modal_out .3s ease-out;
  animation-fill-mode: forwards;
}
@keyframes noty_modal_in {
  100% {
    opacity: .3;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes notyInd {
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes noty_anim_out {
  100% {
    transform: translate(50%);
    opacity: 0;
  }
}
@keyframes noty_anim_height {
  100% {
    height: 0;
  }
}
/* Theme */
.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.radius}px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.1);
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .noty_body {
    padding: 10px;
    color: ${({ theme }) => theme.color.primary};
  }
  .noty_buttons {
  border-top: 1px solid #e7e7e7;
  padding: 5px 10px;
  }
}
.noty_type__alert,
.noty_type__notification {
  border-left: 2px solid var(--contrastPrimary);
}
.noty_type__warning {
  border-left: 2px solid var(--warning);
  .noty_buttons {
  	border-color: #dfaa30;
  }
}
.noty_type__error {
  background-color: ${({ theme }) => theme.color.red};
  border-left: 2px solid var(--danger);
  .noty_buttons {
	  border-color: darkred;
  }
}
.noty_type__info,
.noty_type__information {
  border-left: 2px solid var(--info);
  .noty_buttons {
	  border-color: #0B90C4;
  }
}
.noty_type__success {
  border-left: 2px solid var(--contrastPrimary);
  
  .noty_buttons {
	  border-color: #50C24E;
  }
}
`
