import React, { useEffect, useState } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { list as listCategories } from 'services/categories'
import PropTypes from 'prop-types'
import { CATEGORY } from 'constants/resources'

function SubcategorySelect({
  parentId, loading, disabled, ...otherProps
}) {
  const { t } = useTranslation(CATEGORY)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (parentId) {
      listCategories({
        limit: 100,
        parent: parentId,
      }).then((response) => {
        const opts = response.results.map(category => ({
          value: category.id,
          label: category.name,
        }))
        setOptions(opts)
      })
    }
  }, [parentId])

  return (
    <Input
      name="subcategory"
      label={t('selectSubcategory.label')}
      placeholder={t('selectSubcategory.placeholder')}
      type="select"
      disabled={disabled || !parentId}
      options={options}
      {...otherProps}
    />
  )
}

SubcategorySelect.propTypes = {
  parentId: PropTypes.string,
  disabled: PropTypes.bool,
}

SubcategorySelect.defaultProps = {
  disabled: false,
  parentId: '',
}

export default SubcategorySelect
