import React from 'react'
import PropTypes from 'prop-types'
import {
  Price, Detail, PreviewLink, SectionHeader,
} from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { SUPPLIER, ORDER_ITEM } from 'constants/resources'

function Supplier({
  supplierName,
  supplierId,
  purchaseOrder,
  dailyRate,
  weeklyRate,
  monthlyRate,
  contactName,
  contactNumber,
  loading,
}) {
  const { t } = useTranslation(['common', ORDER_ITEM])

  return (
    <Grid container spacing={2}>
      <SectionHeader title={t('common:resources.suppliers.singular')} />
      <Grid item xs={12} md={6}>
        <Detail
          text={supplierName}
          loading={loading}
          label={t('common:resources.suppliers.singular')}
        >
          <PreviewLink
            resource={SUPPLIER}
            id={supplierId}
          >
            <span>
              {supplierName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
      {/* FIXME: Delete */}
      {/* <Grid item xs={12} md={6}>
        <Detail
          text={contactName}
          loading={loading}
          label={t('common:shared.contactName')}
        >
          {contactName}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={contactNumber}
          loading={loading}
          label={t('common:shared.contactNumber')}
        >
          <PhoneNumber number={contactNumber} />
        </Detail>
      </Grid> */}
      <Grid item xs={12} md={6}>
        <Detail
          text={dailyRate}
          loading={loading}
          label={t('orderItem:shared.dailyRate')}
        >
          <Price amount={dailyRate} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={weeklyRate}
          loading={loading}
          label={t('orderItem:shared.weeklyRate')}
        >
          <Price amount={weeklyRate} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail
          text={monthlyRate}
          loading={loading}
          label={t('orderItem:shared.monthlyRate')}
        >
          <Price amount={monthlyRate} />
        </Detail>
      </Grid>
    </Grid>
  )
}

Supplier.propTypes = {
  supplierName: PropTypes.string,
  supplierId: PropTypes.string,
  contactName: PropTypes.string,
  contactNumber: PropTypes.string,
  purchaseOrder: PropTypes.string,
  dailyRate: PropTypes.number,
  weeklyRate: PropTypes.number,
  monthlyRate: PropTypes.number,
  loading: PropTypes.bool,
}

Supplier.defaultProps = {
  supplierName: '',
  supplierId: '',
  contactName: '',
  contactNumber: '',
  purchaseOrder: '',
  dailyRate: null,
  weeklyRate: null,
  monthlyRate: null,
  loading: false,
}

export default Supplier
