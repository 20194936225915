import { useMemo } from 'react'
import useBreakpoint from './use-breakpoint'

function useDeviceColumns(columns, compress = false) {
  const device = useBreakpoint()
  return useMemo(() => {
    if (compress && device !== 'sm') return columns.md

    return columns[device]
  }, [columns, compress, device])
}

export default useDeviceColumns
