import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components/ui'
import { useDispatch } from 'react-redux'
import actions from 'store/action-creators'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledDestroy = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  transition: 200ms;
  .fold {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    transition: border-radius 0s;
    margin: 0;
    }
  .fold:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
    width: 0;
    background-color: ${({ danger, theme }) => (danger ? theme.color.accent700 : theme.color.secondary)};
    border-width: 0px;
    border-color: ${({ theme }) => `transparent transparent ${theme.color.primary300} ${theme.color.primary300} `};
    border-radius: 0 6px 0 5px;
    transition: 200ms;
    z-index: 2;
  }
  &:hover {
    width: 70px;
    height: 70px;
    .fold {
      border-radius: 5px 0 5px 5px;
      &:before {
        border-width: 30px; 
      }
    }
    .destroy-icon {
      opacity: 1;
    }
  }
  .destroy-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .destroy-icon {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0;
    transition: 200ms;
  }
  > span {
    color: ${({ theme }) => theme.color.primary};
  }
`

function Destroy({ id, resource, show }) {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [verify, setVerify] = useState(false)
  if (!show) return null

  const handleClick = (event) => {
    event.stopPropagation()
    if (!verify) {
      setVerify(true)
    } else if (window && window.confirm(t('shared.deletionConfirm'))) {
      dispatch(actions[resource].delete(id))
    }
  }
  const handleMouseLeave = () => {
    setTimeout(() => {
      setVerify(false)
    }, 200)
  }
  if (!id) return null

  return (
    <StyledDestroy danger={verify} onMouseLeave={handleMouseLeave}>
      <div className="fold" />
      <div className="destroy-background" />
      <Icon
        className="destroy-icon"
        name={verify ? 'done_all' : 'delete'}
        size={20}
        onClick={handleClick}
      />
    </StyledDestroy>
  )
}

Destroy.propTypes = {
  id: PropTypes.string,
  resource: PropTypes.string.isRequired,
  show: PropTypes.bool,
}

Destroy.defaultProps = {
  id: '',
  show: true,
}


export default Destroy
