import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, Label, PreviewLink, Image, SectionHeader,
} from 'components/common'
import { CATEGORY } from 'constants/resources'

function Information({
  action,
  title,
  name,
  description,
  nameTranslation,
  descriptionTranslation,
  image,
  loading,
  categoryName,
  categoryId,
  subcategoryId,
  subcategoryName,
  loadingParent,
  searchTerms,
}) {
  const { t } = useTranslation('common')
  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={12}>
        <Detail
          text={name}
          loading={loading}
          label={`${t('common:shared.name')} (${t('common:languages.en')})`}
        >
          {name}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={description}
          loading={loading}
          label={`${t('common:shared.description')} (${t('common:languages.en')})`}
        >
          {description}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={nameTranslation}
          loading={loading}
          label={`${t('common:shared.name')} (${t('common:languages.fr')})`}
        >
          {nameTranslation}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={descriptionTranslation}
          loading={loading}
          label={`${t('common:shared.description')} (${t('common:languages.fr')})`}
        >
          {descriptionTranslation}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={categoryName}
          loading={loading || loadingParent}
          label={t('common:resources.categories.singular')}
        >
          <PreviewLink id={categoryId} resource={CATEGORY}>
            {categoryName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={subcategoryName}
          loading={loading}
          label={t('common:resources.subcategories.singular')}
        >
          <PreviewLink id={subcategoryId} resource={CATEGORY}>
            {subcategoryName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail
          text={searchTerms}
          loading={loading}
          label={t('equipment:shared.searchTerms')}
        >
          {searchTerms}
        </Detail>
      </Grid>
      {
        image ? (
          <Grid item xs={12} md={12}>
            <Label>{t('common:shared.picture')}</Label>
            <Image
              loading={loading}
              style={{ width: '100%', borderRadius: 3 }}
              src={image}
              alt={`${name} picture`}
            />
          </Grid>
        ) : null
      }
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  name: PropTypes.string,
  description: PropTypes.string,
  nameTranslation: PropTypes.string,
  descriptionTranslation: PropTypes.string,
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  subcategoryId: PropTypes.string,
  subcategoryName: PropTypes.string,
  image: PropTypes.string,
  searchTerms: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  loadingParent: PropTypes.bool,
}

Information.defaultProps = {
  action: null,
  name: '',
  description: '',
  nameTranslation: '',
  descriptionTranslation: '',
  image: '',
  searchTerms: '',
  title: '',
  categoryId: '',
  categoryName: '',
  subcategoryId: '',
  subcategoryName: '',
  loading: false,
  loadingParent: false,
}

export default Information
