const font = {
  title: {
    family: 'Inter',
    size: 28,
    weight: 500,
  },
  subtitle: {
    family: 'Inter',
    size: 24,
    weight: 500,
  },
  header: {
    family: 'Inter',
    size: 18,
    weight: 500,
  },
  body: {
    family: 'Inter',
    size: 14,
    weight: 400,
  },
  small: {
    family: 'Inter',
    size: 12,
    weight: 400,
  },
}

export default font
