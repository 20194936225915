import React from 'react'
import { useNotification, useTheme } from 'components/hooks'
import {
  Card, Link, Divider, Icon, Loader, Tooltip,
} from 'components/ui'
import { useDispatch } from 'react-redux'
import { application } from 'store/action-creators'
import {
  Chip, PreviewLink, Button, Input, CircleButton,
} from 'components/common'

function UI() {
  const dispatch = useDispatch()
  const notify = useNotification()
  const [, setTheme] = useTheme()
  return (
    <div style={{ padding: 50 }}>
      <h1>Button</h1>
      <Button onClick={() => notify({ text: 'Hello world' })}>Notify</Button>
      <Button onClick={() => dispatch(application.setOverlayDisplay(true))}>Set overlay</Button>
      <Button onClick={() => setTheme('light')}>Change theme</Button>
      <Divider />
      <h1>Card</h1>
      <Card width={200}>
        <h3>Hello</h3>
      </Card>
      <Divider />
      <h1>Chip</h1>
      <Chip label="Delivered" disabled onClick={() => { console.log('lcicked') }} />
      <Divider />
      <h1>CircleButton</h1>
      <CircleButton icon="add" />
      <Divider />
      <h1>DateField</h1>
      <Input
        label="Enter a name"
        placeholder="Daniel"
        type="date"
        value="2020-10-23"
      />
      <Input
        label="Equipment picture"
        placeholder="Daniel"
        type="file"
        value="2020-10-23"
      />
      <Divider />
      <h1>Icon</h1>
      <Icon name="map" color="gray" size={60} />
      <Divider />
      <h1>Link</h1>
      <Link>UI link</Link>
      <br />
      <PreviewLink to="company">NavLink</PreviewLink>
      <Divider />
      <h1>Loader</h1>
      <Loader size={20} />
      <Divider />
      <h1>Menu</h1>
      <Divider />
      <h1>SelectField</h1>
      <Input
        label="Enter a name"
        placeholder="Daniel"
        options={[{ label: 'Hello', value: 1 }, { label: 'Wordl', value: 2 }]}
        type="select"
      />
      <Divider />
      <h1>Skeleton</h1>
      <p className="loading" />
      <Divider />
      <h1>TextField</h1>
      <Input
        label="Enter a name"
        placeholder="Daniel"
        type="text"
      />
      <Divider />
      <h1>Tooltip</h1>
      <Tooltip title="World">Hover to display the tooltip</Tooltip>
      <Divider />


    </div>
  )
}

export default UI
