import React from 'react'
import PropTypes from 'prop-types'
import { Detail, SectionHeader } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { Link } from 'components/ui'
import { useParentCategory } from 'components/hooks'
import { useTranslation } from 'react-i18next'
import { CATEGORY } from 'constants/resources'

function ParentCategory({ categoryId, loading, onClick }) {
  const [parent, loadingParent] = useParentCategory(categoryId)
  const { t } = useTranslation(['common', CATEGORY])

  if (!parent.size) return null

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SectionHeader title={t('category:shared.parent')} fullSize />
          <Detail
            text={parent.get('name')}
            loading={loading}
            label={t('common:resources.categories.singular')}
          >
            <div>
              {
              (loadingParent || loading) ? (
                <p className="loading" />
              ) : (
                <Link
                  onClick={() => { if (onClick) onClick(parent.get('id')) }}
                >
                  {parent.get('name')}
                </Link>
              )
            }
            </div>
          </Detail>
        </Grid>
      </Grid>
    </>
  )
}


ParentCategory.propTypes = {
  categoryId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
}

ParentCategory.defaultProps = {
  loading: false,
  onClick: null,
}


export default ParentCategory
