import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import {
  Button, Input, PriceField, PercentageField, Flex,
} from 'components/common'
import { orderItems, orders } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EquipmentSelect } from 'components/pages/equipment'
import { SupplierSelect } from 'components/pages/suppliers'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map, fromJS } from 'immutable'
import { ORDER_ITEM } from 'constants/resources'
import { CANCELLED, FINALIZED } from 'constants/status'
import Status from './Status'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', ORDER_ITEM, 'error'])
  const order = useSelector(store => store.getIn(['order', 'current', 'data']))
  const isDisabled = [CANCELLED, FINALIZED].includes(current.get('status'))
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register, getValues, setValue,
  } = useForm({ mode: 'onBlur' })

  const handleUpdateSuccess = (response) => {
    if (order.size && order.has('orderItems')) {
      const itemIndex = order.get('orderItems').findIndex(item => item.get('id') === response.id)
      dispatch(orders.setCurrentData(order.setIn(['orderItems', itemIndex], fromJS(response))))
    }
  }

  const handleCreateSuccess = (response) => {
    if (order.size && order.has('orderItems')) {
      dispatch(orders.setCurrentData(order.set('orderItems', order.get('orderItems').push(fromJS(response)))))
    } else {
      dispatch(orders.setCurrentData(order.set('orderItems', fromJS([response]))))
    }
  }

  const onSubmit = (body) => {
    if (update) {
      dispatch(orderItems.update(
        body,
        handleUpdateSuccess,
      ))
    } else {
      dispatch(orderItems.create(
        body,
        handleCreateSuccess,
      ))
    }
  }

  const handleStatusChange = (val) => {
    if (!getValues('status')) register({ name: 'status' })
    setValue('status', val)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        <Status
          defaultValue={current.get('status')}
          onChange={handleStatusChange}
        />
      </Flex>
      <Divider spacing={20} />
      <input
        type="hidden"
        name="order"
        ref={register}
        defaultValue={current.getIn(['order', 'id'])}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={EquipmentSelect}
            name="equipment"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:equipment.required'),
              },
            }}
            error={errors.equipment?.message}
            id="new-order-item-equipment"
            defaultValue={current.getIn(['equipment', 'id'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="quantity"
            type="number"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:quantity.required'),
              },
            }}
            error={errors.quantity?.message}
            label={t('common:shared.quantity')}
            placeholder={t('orderItem:new.quantity.placeholder')}
            id="new-order-item-quantity"
            defaultValue={current.get('quantity')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.off_rented_at?.message}
            name="off_rented_at"
            type="datetime"
            label={t('orderItem:shared.offRented')}
            id="new-service-request-off-rent"
            defaultValue={current.get('off_rented_at')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.daily_rate"
            control={control}
            error={errors.metadata?.daily_rate?.message}
            label={t('orderItem:shared.dailyRate')}
            placeholder="69.00"
            id="new-order-item-daily-rate"
            defaultValue={current.getIn(['metadata', 'daily_rate'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.weekly_rate"
            control={control}
            error={errors.metadata?.weekly_rate?.message}
            label={t('orderItem:shared.weeklyRate')}
            placeholder="499.00"
            id="new-order-item-weekly-rate"
            defaultValue={current.getIn(['metadata', 'weekly_rate'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.monthly_rate"
            control={control}
            error={errors.metadata?.monthly_rate?.message}
            label={t('orderItem:shared.monthlyRate')}
            placeholder="3999.00"
            id="new-order-item-monthly-rate"
            defaultValue={current.getIn(['metadata', 'monthly_rate'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="metadata.rental_protection"
            control={control}
            error={errors.metadata?.rental_protection?.message}
            label={t('orderItem:shared.rentalProtection')}
            placeholder="2"
            id="new-order-item-rental-protection"
            defaultValue={current.getIn(['metadata', 'rental_protection'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="metadata.env_compensation"
            control={control}
            error={errors.metadata?.env_compensation?.message}
            label={t('orderItem:shared.envCompensation')}
            placeholder="5"
            id="new-order-item-env-compensation"
            defaultValue={current.getIn(['metadata', 'env_compensation'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.delivery_cost"
            control={control}
            error={errors.metadata?.delivery_cost?.message}
            label={t('orderItem:shared.deliveryCost')}
            placeholder="169.00"
            id="new-order-item-delivery-cost"
            defaultValue={current.getIn(['metadata', 'delivery_cost'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.pickup_cost"
            control={control}
            error={errors.metadata?.pickup_cost?.message}
            label={t('orderItem:shared.pickupCost')}
            placeholder="169.00"
            id="new-order-item-pickup-cost"
            defaultValue={current.getIn(['metadata', 'pickup_cost'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="number"
            name="invoices_sent"
            control={control}
            error={errors.invoices_sent?.message}
            label={t('orderItem:shared.invoicesSent')}
            placeholder="1"
            id="new-order-item-invoices-sent"
            defaultValue={current.get('invoices_sent') || current.getIn(['metadata', 'invoices_sent'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="number"
            name="invoices_received"
            control={control}
            error={errors.invoices_received?.message}
            label={t('orderItem:shared.invoicesReceived')}
            placeholder="1"
            id="new-order-item-invoices-received"
            defaultValue={current.get('invoices_received') || current.getIn(['metadata', 'invoices_received'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            type="text"
            name="notes"
            control={control}
            error={errors.notes?.message}
            label={t('common:shared.notes')}
            placeholder={t('orderItem:new.notes.placeholder')}
            multiline
            id="new-order-item-notes"
            defaultValue={current.get('notes')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Divider spacing={20} />
        <Grid item xs={12} md={12}>
          <h3>{t('common:resources.suppliers.singular')}</h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SupplierSelect}
            name="supplier"
            id="new-order-item-supplier"
            control={control}
            error={errors.supplier?.message}
            defaultValue={current.getIn(['supplier', 'id'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.supplier.daily_rate"
            control={control}
            error={errors.metadata?.supplier.daily_rate?.message}
            label={t('orderItem:shared.dailyRate')}
            placeholder="69.00"
            id="new-order-item-supplier-daily-rate"
            defaultValue={current.getIn(['metadata', 'supplier', 'daily_rate'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.supplier.weekly_rate"
            control={control}
            error={errors.metadata?.supplier.weekly_rate?.message}
            label={t('orderItem:shared.weeklyRate')}
            placeholder="499.00"
            id="new-order-item-supplier-weekly-rate"
            defaultValue={current.getIn(['metadata', 'supplier', 'weekly_rate'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.supplier.monthly_rate"
            control={control}
            error={errors.metadata?.supplier.monthly_rate?.message}
            label={t('orderItem:shared.monthlyRate')}
            placeholder="3999.00"
            id="new-order-item-supplier-monthly-rate"
            defaultValue={current.getIn(['metadata', 'supplier', 'monthly_rate'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Divider spacing={20} />
        <Grid item xs={12} md={12}>
          <h3>{t('orderItem:shared.transportSupplier')}</h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SupplierSelect}
            name="transport_supplier"
            id="new-order-item-transport-supplier"
            control={control}
            error={errors.transport_supplier?.message}
            defaultValue={current.getIn(['transport_supplier', 'id'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.transport_supplier.delivery_cost"
            control={control}
            error={errors.metadata?.transport_supplier.delivery_cost?.message}
            label={t('orderItem:shared.deliveryCost')}
            placeholder="169.00"
            id="new-order-item-transport-supplier-delivery-cost"
            defaultValue={current.getIn(['metadata', 'transport_supplier', 'delivery_cost'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.transport_supplier.pickup_cost"
            control={control}
            error={errors.metadata?.transport_supplier.pickup_cost?.message}
            label={t('orderItem:shared.pickupCost')}
            placeholder="169.00"
            id="new-order-item-transport-supplier-pickup-cost"
            defaultValue={current.getIn(['metadata', 'transport_supplier', 'pickup_cost'])}
            disabled={loading || isDisabled}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('orderItem:edit.submit') : t('orderItem:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
