export const orders = '/orders'
export const orderItems = '/orders/items'
export const users = '/users'
export const adminUsers = '/users/admin'
export const companies = '/companies'
export const equipment = '/equipment'
export const categories = '/equipment/categories'
export const suppliers = '/suppliers'
export const serviceCalls = '/service-calls'
export const requests = '/requests'
export const notifications = '/notifications'
export const resolvedNotifications = '/notifications/resolved'
export const login = '/login'
export const passwordReset = '/reset'
export const notFound = '/404'


export default {
  orders,
  orderItems,
  users,
  adminUsers,
  companies,
  equipment,
  categories,
  suppliers,
  serviceCalls,
  notifications,
  resolvedNotifications,
  login,
  passwordReset,
  notFound,
  requests,
}
