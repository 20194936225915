import React from 'react'
import MaterialTooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

function Tooltip({ title, children, ...otherProps }) {
  let content = children
  if (typeof children === 'string') content = <span>{children}</span>
  return (
    <MaterialTooltip arrow title={title} placement="top" {...otherProps}>
      {content}
    </MaterialTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
}

export default Tooltip
