import React, { useEffect, useState } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { COMPANY } from 'constants/resources'
import { COMPANY_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { Configure, connectHits } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

function CompanySelect({
  hits, value, onInputChange, ...otherProps
}) {
  const { t } = useTranslation(COMPANY)
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState([])
  const configure = value ? ({
    filters: `objectID:${value}`,
  }) : ({
    query,
  })
  useEffect(() => {
    const opts = hits.map(company => ({
      value: company.objectID,
      label: company.name,
    }))
    setOptions(opts)
  }, [hits, value])

  const handleInputChange = (event, data) => {
    setQuery(data)
    if (onInputChange) onInputChange(data)
    return data
  }

  return (
    <>
      <Configure
        {...configure}
        attributesToRetrieve={[
          'objectID',
          'name',
        ]}
      />

      <Input
        name="company"
        label={t('select.label')}
        placeholder={t('select.placeholder')}
        type="select"
        value={value}
        onInputChange={handleInputChange}
        options={options}
        {...otherProps}
      />
    </>
  )
}

CompanySelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
}

CompanySelect.defaultProps = {
  id: '',
}

export default withAlgolia(COMPANY_INDEX)(connectHits(CompanySelect))
