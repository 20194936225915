import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from 'store/reducers'
import rootSaga from 'sagas'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router/immutable'
import { Map } from 'immutable'

const initialState = new Map()

export const history = createBrowserHistory()

function initializeStore(preloadedState = initialState) {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware, routerMiddleware(history)]
  const enhancers = []
  const composedEnhancers = (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const enhancerComposed = composedEnhancers(applyMiddleware(...middleware), ...enhancers)
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    enhancerComposed,
  )

  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export default initializeStore
