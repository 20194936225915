import React from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Input, Button } from 'components/common'
import { companies, users } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { COMPANY, USER } from 'constants/resources'
import { useQuickPreview } from 'components/hooks'
import { NEW } from 'constants/crud'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', COMPANY, 'error'])
  const dispatch = useDispatch()
  const { handleSubmit, control, errors } = useForm({ mode: 'onBlur' })
  const [openPreview] = useQuickPreview()

  const handleCreateSuccess = (response) => {
    dispatch(users.setCreateData({
      company: { id: response.id },
    }))
    openPreview(USER, NEW)
  }

  const onSubmit = (body) => {
    if (update) {
      dispatch(companies.update(body))
    } else {
      dispatch(companies.create(
        body,
        handleCreateSuccess,
      ))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>{t('common:shared.information')}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:shared.required'),
              },
            }}
            error={errors.name?.message}
            type="text"
            name="name"
            label={t('common:shared.name')}
            placeholder={t('company:new.name.placeholder')}
            id="new-company-name"
            defaultValue={current.get('name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-company-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('company:edit.submit') : t('company:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
