import React, { useEffect, useState } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { list as listUsers } from 'services/users'
import { USER } from 'constants/resources'

function SelectAdmin(props) {
  const { t } = useTranslation(USER)
  const [options, setOptions] = useState([])

  useEffect(() => {
    listUsers({
      is_admin: true,
      limit: 100,
    }).then((response) => {
      const opts = response.results.map(user => ({
        value: user.id,
        label: user.name,
      }))
      setOptions(opts)
    })
  }, [])


  return (
    <Input
      label={t('selectAdmin.label')}
      placeholder={t('selectAdmin.placeholder')}
      type="select"
      options={options}
      {...props}
    />
  )
}

export default SelectAdmin
