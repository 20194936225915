import React from 'react'
import { Input } from 'components/common'
import InputAdornment from '@material-ui/core/InputAdornment'

function PercentageField(props) {
  return (
    <Input
      type="number"
      placeholder="5"
      InputProps={{
        endAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
      {...props}
    />
  )
}


export default PercentageField
