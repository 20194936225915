import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/suppliers/reviews/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/suppliers/reviews/${id}/`)
)

export const create = async ({
  supplier,
  notes,
  deliveries,
  service_calls,
  response_time,
  pricing,
}) => (
  api.post('/app/suppliers/reviews/', {
    supplier,
    notes,
    deliveries,
    service_calls,
    response_time,
    pricing,
  })
)

export const destroy = async id => (
  api.delete(`/app/suppliers/reviews/${id}/`)
)
