import React from 'react'
import { DetailHeader } from 'components/common'
import PropTypes from 'prop-types'
import Status from 'components/pages/service-requests/Status'
import { Map } from 'immutable'
import AssignButton from './AssignButton'
import RemindButton from './RemindButton'
import CreateOrderButton from './CreateOrderButton'

function Header({
  status,
  title,
  loading,
  isOrphan,
  id,
  designation,
  serviceRequest,
}) {
  return (
    <DetailHeader title={title} loading={loading}>
      <RemindButton id={id} status={status} />
      <CreateOrderButton
        id={id}
        status={status}
        designation={designation}
        serviceRequest={serviceRequest}
      />
      <AssignButton isOrphan={isOrphan} />
      <Status defaultValue={status} id={id} />
    </DetailHeader>
  )
}

Header.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  isOrphan: PropTypes.bool,
  designation: PropTypes.string,
  serviceRequest: PropTypes.instanceOf(Map),
}

Header.defaultProps = {
  status: '',
  id: '',
  title: '',
  loading: false,
  isOrphan: false,
  designation: '',
  serviceRequest: new Map(),
}


export default Header
