import React from 'react'
import { EquipmentContent, EquipmentHeader, EquipmentControls } from 'components/pages/equipment'
import { withAlgolia } from 'components/hocs'
import {
  EQUIPMENT_INDEX,
  EQUIPMENT_TOTALORDERS_ASC_INDEX,
  EQUIPMENT_TOTALORDERS_DESC_INDEX,
} from 'constants/algolia'
import { EQUIPMENT } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'

function EquipmentTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1fr',
    md: '1fr 3fr 2fr 2fr 1fr',
    lg: '1fr 2fr 3fr 2fr 2fr 2fr',
    xl: '1fr 2fr 3fr 2fr 2fr 2fr',
  }

  return (
    <Table
      title={t('resources.equipment.plural')}
      subtitle={(
        <Link
          data-test-id="route-categories-embed"
          to={routes.categories}
        >
          <h1>{t('resources.categories.plural')}</h1>
        </Link>
      )}
      controls={<EquipmentControls />}
      resource={EQUIPMENT}
      header={(
        <EquipmentHeader
          columns={columns}
          defaultRefinement={EQUIPMENT_INDEX}
          items={[
            EQUIPMENT_INDEX,
            EQUIPMENT_TOTALORDERS_ASC_INDEX,
            EQUIPMENT_TOTALORDERS_DESC_INDEX,
          ]}
        />
        )}
      content={(
        <EquipmentContent
          columns={columns}
        />
      )}
    />
  )
}

export default withAlgolia(EQUIPMENT_INDEX)(EquipmentTable)
