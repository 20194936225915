import React from 'react'
import { CategoryTable, CategoryDetail } from 'components/pages/categories'
import { TableLayout } from 'components/layouts'
import { CATEGORY } from 'constants/resources'

function Categories() {
  return (
    <TableLayout
      resource={CATEGORY}
      table={CategoryTable}
      detail={CategoryDetail}
    />
  )
}

export default Categories
