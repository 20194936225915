import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DATE, DATETIME } from 'constants/formats'

const moment = require('moment')

function Date({ format, variant, children }) {
  const { t } = useTranslation('common')
  const lang = useSelector(state => state.getIn(['config', 'lang']))
  if (!children) return null

  moment.locale('lang', {
    monthsShort: t('moment.monthsShort').split('_'),
  })
  moment.locale(lang)
  let momentFormat = format
  if (variant === 'datetime') momentFormat = DATETIME
  const date = moment(children).format(momentFormat)
  return (
    <span>{date}</span>
  )
}

Date.propTypes = {
  format: PropTypes.string,
  variant: PropTypes.string,
}

Date.defaultProps = {
  format: DATE,
  variant: '',
}

export default Date
