import React from 'react'
import {
  Input, AddressFields, Button, Flex, PriceField,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import { EquipmentSelect } from 'components/pages/equipment'
import { CompanySelect } from 'components/pages/companies'

function Form({ current, loading, update }) {
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      designation: 'purchase-price',
      metadata: {
        budget: current.getIn(['metadata', 'budget']),
        company: current.getIn(['metadata', 'company']),
        equipment: current.getIn(['metadata', 'equipment']),
        equipment_state: current.getIn(['metadata', 'equipment_state']),
        equipment_model: current.getIn(['metadata', 'equipment_model']),
        equipment_year: current.getIn(['metadata', 'equipment_year']),
      },
      assignee: current.getIn(['assignee', 'id']),
      notes: current.get('notes'),
      address_line1: current.get('address_line1'),
      address_line2: current.get('address_line2'),
      address_zip: current.get('address_zip'),
      address_city: current.get('address_city'),
      address_state: current.get('address_state'),
      address_country: current.getIn(['address_country', 'code']),
      state: current.get('state'),
    },
  })

  const onSubmit = (body) => {
    if (update) {
      dispatch(serviceRequests.update(body))
    } else {
      dispatch(serviceRequests.create(body))
    }
  }

  const handleChange = (results) => {
    const [value] = results
    return (value && value.label) || value
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="designation" value="purchase-price" ref={register} />
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <Controller
            as={CompanySelect}
            name="metadata.company"
            defaultValue={current.getIn(['metadata', 'company'])}
            error={errors?.metadata?.company?.message}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:company.required'),
              },
            }}
            required
            onChange={handleChange}
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={EquipmentSelect}
            control={control}
            name="metadata.equipment"
            error={errors?.metadata?.equipment?.message}
            defaultValue={current.getIn(['metadata', 'equipment'])}
            rules={{
              required: {
                value: true,
                message: t('error:equipment.required'),
              },
            }}
            required
            onChange={handleChange}
            freeSolo
            autoSelect={false}
            clearOnBlur={false}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            name="assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
            error={errors.assignee?.message}
            control={control}
            disabled={loading || !update}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-service-request-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            control={control}
            name="metadata.budget"
            defaultValue={current.getIn(['metadata', 'budget'])}
            error={errors?.metadata?.budget?.message}
            required
            rules={{
              required: {
                value: true,
                message: t('error:budget.required'),
              },
            }}
            label={t('serviceRequest:shared.budget')}
            placeholder={t('serviceRequest:new.budget.placeholder')}
            id="new-service-request-budget"
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="metadata.equipment_state"
            error={errors?.metadata?.equipment_state?.message}
            defaultValue={current.getIn(['metadata', 'equipment_state'])}
            control={control}
            type="select"
            options={[
              { value: 'new', label: t('serviceRequest:shared.new') },
              { value: 'used', label: t('serviceRequest:shared.used') },
            ]}
            label={t('serviceRequest:shared.equipmentState')}
            placeholder={t('serviceRequest:new.equipmentState.placeholder')}
            id="new-service-request-equipmentState"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.equipment_model"
            error={errors?.metadata?.equipment_model?.message}
            defaultValue={current.getIn(['metadata', 'equipment_model'])}
            type="text"
            label={t('serviceRequest:shared.model')}
            placeholder={t('serviceRequest:new.model.placeholder')}
            id="new-service-request-model"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.equipment_year"
            defaultValue={current.getIn(['metadata', 'equipment_year'])}
            error={errors?.metadata?.equipment_year?.message}
            type="text"
            label={t('serviceRequest:shared.year')}
            placeholder={t('serviceRequest:new.year.placeholder')}
            id="new-service-request-year"
            disabled={loading}
          />
        </Grid>
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
          cityRequired
          stateRequired
        />
        {
          update ? (
            <Grid item xs={12} md={12}>
              <Controller
                as={Input}
                control={control}
                error={errors.state?.message}
                name="state"
                type="text"
                multiline
                label={t('common:shared.status')}
                placeholder={t('serviceRequest:new.state.placeholder')}
                id="new-service-request-state"
                defaultValue={current.get('state')}
                disabled={loading}
              />
            </Grid>
          ) : null
        }
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            multiline
            label={t('common:shared.notes')}
            placeholder={t('serviceRequest:new.notes.placeholder')}
            id="new-service-request-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('serviceRequest:edit.submitRequest') : t('serviceRequest:new.submitRequest')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
