import React, { useState, useEffect } from 'react'
import { Icon, Tooltip } from 'components/ui'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { notifications } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { DATETIME } from 'constants/formats'

const blink = keyframes`
  0% { font-size: 16px; }
  20% { font-size: 22px; }
  100% { font-size: 16px; }
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.accent};
  &:active {
    transform: scale(0.85);
  }
  &.blink {
    animation-name: ${blink};
    animation-duration: 300ms;
  }
  &.clickable {
    cursor: pointer;
  }
`

function Acknowledgment({
  acknowledgedAt,
  acknowledgedBy,
  id,
}) {
  const [acknowledged, setAcknowledged] = useState(!!acknowledgedAt)
  const dispatch = useDispatch()
  useEffect(() => {
    setAcknowledged(!!acknowledgedAt)
  }, [acknowledgedAt])

  const handleClick = (event) => {
    event.stopPropagation()
    setAcknowledged(true)
    dispatch(notifications.acknowledge(id))
  }

  return acknowledged ? (
    <Tooltip title={(
      <span>
        {acknowledgedBy}
        <br />
        {acknowledgedAt && moment(acknowledgedAt).format(DATETIME)}
      </span>
      )}
    >
      <span>
        <StyledIcon
          name="done_all"
          className="blink"
          size={16}
        />

      </span>
    </Tooltip>
  ) : (
    <StyledIcon
      onClick={handleClick}
      className="clickable"
      name="radio_button_unchecked"
      size={16}
    />
  )
}

Acknowledgment.propTypes = {
  acknowledgedAt: PropTypes.string,
  acknowledgedBy: PropTypes.string,
  id: PropTypes.string,
}

Acknowledgment.defaultProps = {
  acknowledgedAt: '',
  acknowledgedBy: '',
  id: '',
}

export default Acknowledgment
