import {
  SET_LIST_DATA,
  PREPEND_LIST_ITEM,
  UPDATE_LIST_ITEM,
  REMOVE_LIST_ITEM,
  SET_LIST_SEARCH_INDEX,
  CLEAR_LIST_DATA,
  SET_LIST_SEARCH_QUERY,
  CLEAR_LIST_SEARCH_FACET_FILTERS,
  ADD_LIST_SEARCH_FACET_FILTERS,
  REMOVE_LIST_SEARCH_FACET_FILTERS,
  EMPTY_LIST_SEARCH_FACET_FILTERS,
  CLEAR_LIST_SEARCH_FILTERS,
  ADD_LIST_SEARCH_FILTERS,
  REMOVE_LIST_SEARCH_FILTERS,
  EMPTY_LIST_SEARCH_FILTERS,
  SET_LIST_METADATA,
  SET_LIST_LOADING,
  SET_CURRENT_DATA,
  CLEAR_CURRENT_DATA,
  SET_CURRENT_ID,
  SET_CURRENT_LOADING,
  SET_CREATE_DATA,
  CLEAR_CREATE_DATA,
  SET_CREATE_LOADING,
  SET_UPDATE_DATA,
  CLEAR_UPDATE_DATA,
  SET_UPDATE_LOADING,
  SET_DELETE_LOADING,
} from 'store/actions/factory'
import { normalize } from 'utils'
import { fromJS, Map, List } from 'immutable'


export const initialState = fromJS({
  list: {
    data: [],
    search: {
      query: '',
      index: '',
      facetFilters: {},
      filters: {},
    },
    metadata: {
      previous: null,
      next: null,
    },
    loading: true,
  },
  current: {
    id: '',
    data: {},
    loading: false,
  },
  create: {
    data: {},
    loading: false,
  },
  update: {
    data: {},
    loading: false,
  },
  delete: {
    loading: false,
  },
})

function createReducerWithResource(resource) {
  return (state = initialState, { type = '', payload = {} }) => {
    const { body } = payload
    switch (type) {
      case SET_LIST_DATA(resource):
        return state.setIn(['list', 'data'], fromJS(normalize(body)))
      case PREPEND_LIST_ITEM(resource):
        return state.setIn(['list', 'data'], state.getIn(['list', 'data']).unshift(fromJS(body)))
      case UPDATE_LIST_ITEM(resource):
        return state.mergeIn(
          ['list', 'data',
            state.getIn(['list', 'data']).findIndex(item => item.get('id') === body.id),
          ],
          fromJS(body),
        )
      case REMOVE_LIST_ITEM(resource):
        return state.deleteIn(
          ['list', 'data',
            state.getIn(['list', 'data']).findIndex(item => item.get('id') === body),
          ],
        )
      case CLEAR_LIST_DATA(resource):
        return state.setIn(['list', 'data'], new List())
      case SET_LIST_SEARCH_INDEX(resource):
        return state.setIn(['list', 'search', 'index'], body)
      case SET_LIST_SEARCH_QUERY(resource):
        return state.setIn(['list', 'search', 'query'], fromJS(body))
      case CLEAR_LIST_SEARCH_FACET_FILTERS(resource):
        return state.setIn(['list', 'search', 'facetFilters'], fromJS({}))
      case ADD_LIST_SEARCH_FACET_FILTERS(resource):
        // Payload interface { attribute, label?, value }
        if (!body.attribute) return state

        if (List.isList(state.getIn(['list', 'search', 'facetFilters', body.attribute]))
        && !state.getIn(['list', 'search', 'facetFilters', body.attribute]).find(filter => filter.get('value') === body.value)) {
          return state.setIn(
            ['list', 'search', 'facetFilters', body.attribute],
            state.getIn(['list', 'search', 'facetFilters', body.attribute]).push(fromJS(body)),
          )
        }
        // FIXME: Normalize to List only.
        if (state.getIn(['list', 'search', 'facetFilters']).has(body.attribute)
        && state.getIn(['list', 'search', 'facetFilters', body.attribute, 'value']) !== body.value) {
          return state.setIn(
            ['list', 'search', 'facetFilters', body.attribute],
            List([state.getIn(['list', 'search', 'facetFilters', body.attribute]), fromJS(body)]),
          )
        }
        return state.setIn(['list', 'search', 'facetFilters', body.attribute], fromJS(body))
      case REMOVE_LIST_SEARCH_FACET_FILTERS(resource):
        if (List.isList(state.getIn(['list', 'search', 'facetFilters', body.attribute]))
        && state.getIn(['list', 'search', 'facetFilters', body.attribute]).size > 1) {
          return state.deleteIn(['list', 'search', 'facetFilters', body.attribute,
            state.getIn(['list', 'search', 'facetFilters', body.attribute]).findIndex(
              value => value.get('value') === body.value,
            )])
        }
        return state.deleteIn(['list', 'search', 'facetFilters', body.attribute])
      case EMPTY_LIST_SEARCH_FACET_FILTERS(resource):
        return state.deleteIn(['list', 'search', 'facetFilters', body.attribute])
      case CLEAR_LIST_SEARCH_FILTERS(resource):
        return state.setIn(['list', 'search', 'filters'], fromJS({}))
      case ADD_LIST_SEARCH_FILTERS(resource):
        // Payload interface { attribute, label?, value }
        if (!body.attribute) return state

        if (List.isList(state.getIn(['list', 'search', 'filters', body.attribute]))
          && !state.getIn(['list', 'search', 'filters', body.attribute]).find(filter => filter.get('value') === body.value)) {
          return state.setIn(
            ['list', 'search', 'filters', body.attribute],
            state.getIn(['list', 'search', 'filters', body.attribute]).push(fromJS(body)),
          )
        }

        return state.setIn(['list', 'search', 'filters', body.attribute], List([fromJS(body)]))
      case REMOVE_LIST_SEARCH_FILTERS(resource):
        if (List.isList(state.getIn(['list', 'search', 'filters', body.attribute]))
          && state.getIn(['list', 'search', 'filters', body.attribute]).size > 1) {
          const index = state.getIn(['list', 'search', 'filters', body.attribute]).findIndex(
            value => value.get('value') === body.value,
          )
          return state.deleteIn(['list', 'search', 'filters', body.attribute, index])
        }
        return state.deleteIn(['list', 'search', 'filters', body.attribute])
      case EMPTY_LIST_SEARCH_FILTERS(resource):
        return state.deleteIn(['list', 'search', 'filters', body.attribute])
      case SET_LIST_METADATA(resource):
        return state.setIn(['list', 'metadata'], fromJS(body))
      case SET_LIST_LOADING(resource):
        return state.setIn(['list', 'loading'], body)
      case SET_CURRENT_ID(resource):
        return state.setIn(['current', 'id'], fromJS(body))
      case SET_CURRENT_DATA(resource):
        return state.setIn(['current', 'data'], fromJS(body))
      case CLEAR_CURRENT_DATA(resource):
        return state.setIn(['current', 'data'], new Map())
      case SET_CURRENT_LOADING(resource):
        return state.setIn(['current', 'loading'], body)
      case SET_CREATE_DATA(resource):
        return state.mergeIn(['create', 'data'], fromJS(body))
      case CLEAR_CREATE_DATA(resource):
        return state.setIn(['create', 'data'], new Map())
      case SET_CREATE_LOADING(resource):
        return state.setIn(['create', 'loading'], body)
      case SET_UPDATE_DATA(resource):
        return state.mergeIn(['update', 'data'], fromJS(body))
      case CLEAR_UPDATE_DATA(resource):
        return state.setIn(['update', 'data'], new Map())
      case SET_UPDATE_LOADING(resource):
        return state.setIn(['update', 'loading'], body)
      case SET_DELETE_LOADING(resource):
        return state.setIn(['delete', 'loading'], body)
      default: return state
    }
  }
}

export default createReducerWithResource
