import React from 'react'
import { TableHeader, TableCell } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { USER } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'

function AdminHeader({
  columns,
}) {
  const { t } = useTranslation('common')
  const selected = useResource(USER, 'current', 'id')
  const templateColumns = useDeviceColumns(columns, !!selected)

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell sm>
        <p>{t('shared.name')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('shared.email')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('shared.phone')}</p>
      </TableCell>
    </TableHeader>
  )
}

AdminHeader.propTypes = {
  columns: PropTypes.object.isRequired,
}

export default AdminHeader
