import {
  takeLatest,
} from 'redux-saga/effects'
import { RESERVATION_ITEM } from 'constants/resources'
import {
  DELETE,
} from 'store/actions/factory'
import { destroy } from './factory'

export default function* reservationSaga() {
  yield takeLatest(DELETE(RESERVATION_ITEM), destroy)
}
