import { createGlobalStyle } from 'styled-components'


export default createGlobalStyle`
  /* Tooltip */
  .MuiTooltip-tooltip {
    color: ${({ theme }) => theme.color.primary} !important;
    background-color: ${({ theme }) => theme.color.secondary} !important;
    text-align: center;
    *{
      color: ${({ theme }) => theme.color.primary} !important;
      font-size: 10px !important; 
    }
  }
  .MuiTooltip-arrow {
    color: ${({ theme }) => theme.color.secondary} !important;
  }
  
  /* Autocomplete */
  .MuiAutocomplete-popper {
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiAutocomplete-listbox {
      background-color: ${({ theme }) => theme.color.primary200};
      border: 2px solid ${({ theme }) => theme.color.primary300};
      li {
        color: ${({ theme }) => theme.color.secondary};
      }
      &::-webkit-scrollbar {
        width: 4px;
        height: 40%;
        background: transparent;
      }
      &::-webkit-scrollbar-button {
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        
      }
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb{
        color: teal;
        background-color: ${({ theme }) => theme.color.primary200};
        border-radius: 4px;
      }
    }
  }
  /* Datefield */
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.color.primary} !important;
  }
  .MuiPickersBasePicker-container {
    color: ${({ theme }) => theme.color.secondary} !important;
  }
  .MuiPickersCalendarHeader-daysHeader {
    span {
      color: ${({ theme }) => theme.color.secondary} !important; 
    }
  }
  .MuiPickersCalendarHeader-iconButton {
    background-color: ${({ theme }) => theme.color.primary} !important;
  }
  .MuiPickersCalendar-transitionContainer {

  }
  .MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.color.accent} !important;
  }
  .MuiPickersDay-current {
    color: ${({ theme }) => theme.color.accent} !important;
  }

  /* Timefield */
  .MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiPickersClockPointer-noPoint {
    background-color: ${({ theme }) => theme.color.accent} !important;
  }
  .MuiPickersClockPointer-thumb {
    border-color: ${({ theme }) => theme.color.accent} !important;
  }
  .MuiPickersClock-container {
    span {
      color: ${({ theme }) => theme.color.secondary} !important; 
    }
  }
`
