import React from 'react'
import AuthForm from 'components/pages/reset/AuthForm'
import { Overlay } from 'components/common'
import styled from 'styled-components'

const StyledOverlay = styled(Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

function PasswordReset() {
  return (
    <StyledOverlay opacity={1} show center>
      <AuthForm />
    </StyledOverlay>
  )
}

export default PasswordReset
