import { PROMOTE_EQUIPMENT } from 'store/actions/equipment'
import { EQUIPMENT } from 'constants/resources'
import createActionsWithResource, { createAction } from './factory'

const equipment = createActionsWithResource(EQUIPMENT)

export default {
  ...equipment,
  promote: createAction(PROMOTE_EQUIPMENT),
}
