import shared from './shared'
import common from './color/common'
import black from './color/black'
import white from './color/white'

const dark = {
  name: 'dark',
  color: {
    primary: black['200'],
    primary100: black['100'],
    primary200: black['200'],
    primary300: black['300'],
    primary400: black['400'],
    primary500: black['500'],
    secondary: white['200'],
    secondary100: white['100'],
    secondary200: white['200'],
    secondary300: white['300'],
    secondary400: white['400'],
    secondary500: white['500'],
    ...common,
  },
  ...shared,
}

export default dark
