import React from 'react'
import { Icon } from 'components/ui'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import actions from 'store/action-creators'

const hearBeat = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.5);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.5);
  }
  70% {
    transform: scale(1);
  }
`

const FacetStyled = styled.div`
  display: flex;
  align-items: center;
  .pin-icon {
    margin-left: .25em;
    cursor: pointer;
    opacity: 0;
    transition: 300ms ease;
    &:active {
      animation: ${hearBeat} 100ms linear;
    }
  }
  &:hover .pin-icon {
    opacity: 1;
  }
  > * {
    display: inline;
  }
`

function FacetFilter({
  filters,
  children,
  resource,
}) {
  const dispatch = useDispatch()
  if (!children || !filters.value) return null

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(actions[resource].addListSearchFacetFilters(filters))
  }
  return (
    <FacetStyled>
      {children}
      <Icon
        size={14}
        className="pin-icon"
        name="filter_alt"
        onClick={handleClick}
      />
    </FacetStyled>
  )
}

FacetFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
}

export default FacetFilter
