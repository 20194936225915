import { useMemo } from 'react'
import useDateFilters from './use-date-filters'

function useFilters(resource) {
  const dateFilters = useDateFilters(resource)

  return useMemo(() => {
    const filters = [
      ...dateFilters,
    ]
    return filters.join(' AND ')
  }, [dateFilters])
}

export default useFilters
