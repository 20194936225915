import React from 'react'
import { CategoryContent, CategoryHeader, CategoryControls } from 'components/pages/categories'
import { withAlgolia } from 'components/hocs'
import {
  CATEGORY_INDEX,
} from 'constants/algolia'
import { CATEGORY } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'

function CategoryTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1fr 1fr',
    md: '1fr 1fr',
    lg: '3fr 3fr 1fr',
    xl: '3fr 3fr 1fr',
  }

  return (
    <Table
      resource={CATEGORY}
      title={t('resources.categories.plural')}
      subtitle={<Link to={routes.equipment}><h1>{t('resources.equipment.plural')}</h1></Link>}
      controls={<CategoryControls />}
      header={(
        <CategoryHeader
          columns={columns}
        />
        )}
      content={(
        <CategoryContent
          columns={columns}
        />
      )}
    />
  )
}

export default withAlgolia(CATEGORY_INDEX)(CategoryTable)
