import React from 'react'
import { Icon } from 'components/ui'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledSort = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  > p {
    margin-right: 0.5em;
  }
  .rotate {
    transform: scaleY(-1);
  }
`

function Sort({
  onClick,
  ascendantIndex,
  descendantIndex,
  currentIndex,
  defaultIndex,
  children,
}) {
  let icon = <Icon name="more_vert" size={18} />
  if (currentIndex === ascendantIndex) icon = <Icon name="sort" size={18} className="rotate" />
  if (currentIndex === descendantIndex) icon = <Icon name="sort" size={18} />

  const handleClick = () => {
    if (currentIndex === ascendantIndex) {
      onClick(descendantIndex)
    } else if (currentIndex === descendantIndex) {
      onClick(defaultIndex)
    } else {
      onClick(ascendantIndex)
    }
  }

  return (
    <StyledSort onClick={handleClick}>
      <p>{children}</p>
      {icon}
    </StyledSort>
  )
}

Sort.propTypes = {
  onClick: PropTypes.func.isRequired,
  ascendantIndex: PropTypes.string.isRequired,
  descendantIndex: PropTypes.string.isRequired,
  currentIndex: PropTypes.string.isRequired,
  defaultIndex: PropTypes.string.isRequired,
}

export default Sort
