import { useSelector } from 'react-redux'

function useResource(...options) {
  const [resource, ...rest] = options
  const data = useSelector(state => state.get(resource))
  if (!rest) return data

  const [action, property, ...other] = rest
  if (other.length) return data.getIn([...rest])
  if (property) return data.getIn([action, property])
  if (action) return data.getIn([action, 'data'])
  return data
}

export default useResource
