import React from 'react'
import { TableHeader, TableCell, Sort } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SUPPLIER } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'
import {
  SUPPLIER_INDEX,
  SUPPLIER_STATE_ASC_INDEX,
  SUPPLIER_STATE_DESC_INDEX,
  SUPPLIER_CITY_ASC_INDEX,
  SUPPLIER_CITY_DESC_INDEX,
  SUPPLIER_TOTALORDERS_ASC_INDEX,
  SUPPLIER_TOTALORDERS_DESC_INDEX,
  SUPPLIER_RATING_ASC_INDEX,
  SUPPLIER_RATING_DESC_INDEX,
} from 'constants/algolia'
import { connectSortBy } from 'react-instantsearch-dom'
import { useDispatch } from 'react-redux'
import { suppliers } from 'store/action-creators'

function SupplierHeader({
  refine, columns,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', SUPPLIER])
  const selected = useResource(SUPPLIER, 'current', 'id')
  const currentIndex = useResource(SUPPLIER, 'list', 'search', 'index')
  const templateColumns = useDeviceColumns(columns, !!selected)

  const handleSortClick = (value) => {
    dispatch(suppliers.setListIndex(value))
    if (refine) refine(value)
  }

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell sm>
        {t('common:shared.name')}
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:resources.categories.plural')}</p>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={SUPPLIER_INDEX}
          ascendantIndex={SUPPLIER_STATE_ASC_INDEX}
          descendantIndex={SUPPLIER_STATE_DESC_INDEX}
        >
          {t('common:address.state.label')}
        </Sort>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={SUPPLIER_INDEX}
          ascendantIndex={SUPPLIER_CITY_ASC_INDEX}
          descendantIndex={SUPPLIER_CITY_DESC_INDEX}
        >
          {t('common:address.city.label')}
        </Sort>
      </TableCell>
      <TableCell sm>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={SUPPLIER_INDEX}
          ascendantIndex={SUPPLIER_TOTALORDERS_ASC_INDEX}
          descendantIndex={SUPPLIER_TOTALORDERS_DESC_INDEX}
        >
          {t('supplier:header.totalOrders')}
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={SUPPLIER_INDEX}
          ascendantIndex={SUPPLIER_RATING_ASC_INDEX}
          descendantIndex={SUPPLIER_RATING_DESC_INDEX}
        >
          {t('supplier:header.rating')}
        </Sort>
      </TableCell>
    </TableHeader>
  )
}

SupplierHeader.propTypes = {
  columns: PropTypes.object.isRequired,
  refine: PropTypes.func,
}

SupplierHeader.defaultProps = {
  refine: null,
}

export default connectSortBy(SupplierHeader)
