import { useState, useEffect } from 'react'
import { get as getCategory } from 'services/categories'
import { fromJS, Map } from 'immutable'

function ParentCategory(id) {
  const [fetching, setFetching] = useState(false)
  const [parent, setParent] = useState(new Map())
  useEffect(() => {
    if (id) {
      setFetching(true)
      getCategory(id)
        .then((response) => {
          setParent(fromJS(response))
          setFetching(false)
        })
    }
  }, [id])

  return [parent, fetching]
}

export default ParentCategory
